import React, { useState, useContext, useRef } from 'react';

import Divider from '../common/Divider';
import ToggleButtons from '../common/Buttons/ToggleButtons';
import VenueMap from '../page-venues/components/VenueMap';
import VenueListViewItem from '../page-venues/components/VenueListViewItem';
import VenueEmptyFilteredLocations from '../page-venues/components/VenueEmptyFilteredLocations';
import VenueEmptyMap from "../page-venues/components/VenueEmptyMap";

import { VenuesDataContext } from '../../context';
import Spinner from "../common/Spinner";

const DEFAULT_ACTIVE_MARKER_STATE = { hovered: null, expanded: null, scrollTo: false };

const VenueMobileLayout = ({ setCurrentFilterContainer }) => {
    const [activeMarker, setActiveMarker] = useState(DEFAULT_ACTIVE_MARKER_STATE);
    const [isMapView, setMapView] = useState(false);
    const filterContainer = useRef(null);
    const spinnerContainer = useRef(null);

    const { venues = [], filteredVenues = [], isLoading } = useContext(VenuesDataContext);

    if (isLoading) {
        return <div ref={spinnerContainer}>
            <Spinner targetRef={spinnerContainer} className='rounded-12' />
        </div>
    }

    if (!venues.length) {
         return <VenueEmptyMap />
    }

    return <>
        <ToggleButtons
            activeIndex={+isMapView}
            buttons={[
                { text: 'List View', onClick: () => {
                    setMapView(false);
                    setActiveMarker(DEFAULT_ACTIVE_MARKER_STATE);
                }, },
                { text: 'Map View', onClick: () => setMapView(true) },
            ]}
        />
        <div className="pb-24 mt-24" ref={ref => {
            if (!filterContainer.current) {
                setCurrentFilterContainer(ref);
                filterContainer.current = ref;
            }
        }}></div>
        <div className='flex flex-col lg:flex-row flex-wrap mt-24'>
            <div className={`basis-full lg:flex-1 min-h-[400px] -mx-16 flex ${isMapView ? 'visible' : 'hidden'}`}>
                <VenueMap
                    setActiveMarker={setActiveMarker}
                    activeMarker={activeMarker}
                    venues={venues}
                    filteredVenues={filteredVenues}
                />
            </div>
            <div className={`flex-1 ${isMapView ? 'hidden' : 'visible'}`}>
                {filteredVenues.length ?
                    filteredVenues.map((item, index) =>
                        <React.Fragment key={item.id}>
                            {index ? <Divider className="my-32" /> : null}
                            <VenueListViewItem
                                item={item}
                                showOnMapCallback={() => {
                                    setActiveMarker(prevState =>
                                        ({ ...prevState, scrollTo: false, expanded: item.id }));
                                    setMapView(true);
                                }}
                            />
                        </React.Fragment>)
                    : <VenueEmptyFilteredLocations />}
            </div>
        </div>
    </>
}

export default VenueMobileLayout;
