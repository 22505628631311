import { createContext } from 'react';

export const AuthContext = createContext(null);

export const SettingsContext = createContext(null);

export const TeamDataContext = createContext(null);

export const VenuesDataContext = createContext({});

export const ScheduleDataContext = createContext({});

export const FormDataContext = createContext({});

export const AppContext = createContext({});

export const ModalContext = createContext({});
