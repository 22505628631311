import { useState, useContext, useRef } from 'react';
import { useNode } from '@craftjs/core';

import ModalFooter from '../../common/Modal/ModalFooter';
import ModalContent from '../../common/Modal/ModalContent';
import ModalHeader from '../../common/Modal/ModalHeader';
import CustomInput from '../../common/Controls/CustomInput';
import CustomUploadImage from '../../common/Controls/CustomUploadImage';
import CustomQuillElement from '../../common/Controls/CustomQuillElement';
import FormImageUploadWrapper from '../../common/FormImageUploadWrapper';

import { dispatchChange, handleCloseModal, parseTemplateValues } from '../../../utils';
import { useOnChange } from '../../../hooks';
import { SettingsContext } from '../../../context';
import { DEFAULT_PREFIX_DIVIDER } from '../../../const';

const KEY_LOGO_PREFIX = 'hero_logo';
const KEY_BG_PREFIX = 'hero_bg';
const KEY_BG_MOBILE_PREFIX = 'hero_bg_mobile';

export const HeroModalSettings = ({ onSave }) => {
    const {
        props: { uuid, title, bg, mobileBg, logo, mainText },
        actions: { setProp, setCustom },
    } = useNode(node => ({ props: node.data.props }));
    const settings = useContext(SettingsContext);

    const [isLoading, setLoading] = useState(false);
    const [newTitle, setNewTitle] = useState(parseTemplateValues(title, settings));
    const [newBg, setNewBg] = useState(parseTemplateValues(bg, settings));
    const [newMobileBg, setNewMobileBg] = useState(parseTemplateValues(mobileBg, settings));
    const [newLogo, setNewLogo] = useState(parseTemplateValues(logo, settings));
    const [newMainText, setNewMainText] = useState(parseTemplateValues(mainText, settings));
    const ref = useRef(null);
    const isChanged = useOnChange(ref);

    const handleClose = () => handleCloseModal(setCustom);

    const handleSave = async newSrc => {
        handleClose();
        if (isChanged) {
            setLoading(true);
            setProp(props => {
                props.title = newTitle;
                props.mainText = newMainText;
                props.logo = newSrc[KEY_LOGO] || newLogo;
                props.bg = newSrc[KEY_BG] || newBg;
                props.mobileBg = newSrc[KEY_BG_MOBILE] || newMobileBg;
            });
            await onSave();
            setLoading(false);
        }
    }

    const KEY_LOGO = KEY_LOGO_PREFIX + DEFAULT_PREFIX_DIVIDER + uuid;
    const KEY_BG = KEY_BG_PREFIX + DEFAULT_PREFIX_DIVIDER + uuid;
    const KEY_BG_MOBILE = KEY_BG_MOBILE_PREFIX + DEFAULT_PREFIX_DIVIDER + uuid;

    return (
        <FormImageUploadWrapper
            parentIsLoading={isLoading}
            uploadCallback={handleSave}
            prevData={{ [KEY_LOGO]: logo, [KEY_BG]: bg, [KEY_BG_MOBILE]: mobileBg }}
            data={{ [KEY_LOGO]: newLogo, [KEY_BG]: newBg, [KEY_BG_MOBILE]: newMobileBg }}
        >
            <ModalHeader title='Edit Hero section' onClose={handleClose} />
            <ModalContent outerRef={ref}>
                <div className='flex'>
                    <div className='basis-1/2 border-r border-border py-20 px-24'>
                        <CustomUploadImage
                            mayBeDeleted
                            template={settings.template}
                            name={KEY_LOGO}
                            title='Logo'
                            description='The image will be used as the logo.'
                            setImg={setNewLogo}
                            imgSrc={newLogo}
                            imgType='logo'
                            className='pb-20 border-b border-border'
                            cropperProps={{
                                rounded: true,
                                aspectRatio: 1,
                                preferredSize: { width: 160, height: 160 }
                            }}
                        />
                        <CustomUploadImage
                            template={settings.template}
                            name={KEY_BG}
                            title='Desktop Cover Image'
                            description='Image will be used as the main cover.'
                            setImg={setNewBg}
                            imgSrc={newBg}
                            imgType="horizontal"
                            className='py-20 border-b border-color-border'
                            cropperProps={{
                                aspectRatio: 21 / 9,
                                preferredSize: { width: 1920, height: 820 }
                            }}
                        />
                        <CustomUploadImage
                            template={settings.template}
                            name={KEY_BG_MOBILE}
                            title='Mobile Cover Image'
                            description='The image will be used as the main cover graphic at Header section.'
                            setImg={setNewMobileBg}
                            imgSrc={newMobileBg}
                            imgType='vertical'
                            className='pt-20'
                            cropperProps={{
                                aspectRatio: 9 / 16,
                                preferredSize: { width: 430, height: 765 }
                            }}
                        />
                    </div>
                    <div className='basis-1/2 flex flex-col py-20 px-24'>
                        <CustomInput
                            label='Add a section title'
                            onChange={setNewTitle}
                            value={newTitle}
                            className='mb-20'
                        />
                        <CustomQuillElement
                            title='Add a subtitle'
                            value={newMainText}
                            onChange={value => {
                                dispatchChange(ref);
                                setNewMainText(value)
                            }}
                            className='max-h-hero_quill'
                        />
                    </div>
                </div>
            </ModalContent>
            <ModalFooter onClose={handleClose} />
        </FormImageUploadWrapper>
    );
};
