import React, { useId } from 'react';

const defaultElementClassName = "w-[36px] h-[12px] bg-edit-color-light rounded-[6px] peer-checked:after:translate-x-full"
const defaultElementAfterClassName = "after:h-[20px] after:top-[-5px] after:w-[20px] after:left-0"

export default function SwitchButton({
    label,
    checked,
    onChange,
    containerClass,
    labelClass,
    elementClass,
    roundClass,
    elementAfterClass,
    title,
}) {
    const id = useId();
    return (
        <label htmlFor={id} title={title} className={`relative inline-flex items-center cursor-pointer ${containerClass ?? ''}`}>
            <span className={`mr-12 text-14 ${labelClass ?? ''}`}>{label}</span>
            <input id={id} type="checkbox" checked={checked} onChange={onChange} className="sr-only peer" />
            <div className={`relative peer-focus:outline-none peer
                ${elementClass ?? defaultElementClassName}
                ${roundClass ? roundClass : 'after:bg-edit-color' }
                after:content-[''] after:absolute after:rounded-full after:transition-all
                ${elementAfterClass ?? defaultElementAfterClassName}
            `}>
            </div>
        </label>
    )
};
