import { useEffect, useState } from 'react';

import Modal from './index';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import Icon, { icons } from '../../../assets/images/icons';

const ICONS_ARRAY = 'TILES';
const POSSIBLE_ICONS = Object.keys(icons[ICONS_ARRAY]);

const IconsModal = ({ onClose, icon, onAccept }) => {
    const [currentIcon, setCurrentIcon] = useState(icon);

    useEffect(() => setCurrentIcon(icon), [icon]);

    const handleClose = () => {
        setCurrentIcon(icon);
        onClose();
    };

    const onChooseIcon = () => {
        onAccept(currentIcon);
        onClose();
    };

    return <>
        <ModalHeader
            title={<div className='flex items-center'>
                <button onClick={onClose} type='button'>
                    <Icon name='arrow_left'/>
                </button> Add Icon
                </div>}
            onClose={onClose}
        />
        <ModalContent>
            <div className='p-24 flex flex-wrap gap-16'>
                {POSSIBLE_ICONS.map(item => (
                    <div key={item}>
                        <label>
                            <input
                                onChange={() => setCurrentIcon(item)}
                                checked={item === currentIcon}
                                className='hidden peer'
                                type='radio'
                                name='icon' value={item}
                            />
                            <div className='w-[96px] h-[96px] border border-color-border peer-checked:border-[2px] peer-checked:border-edit-color rounded-[4px] p-10 fill-accent-color'>
                                <Icon arr={ICONS_ARRAY} name={item} />
                            </div>
                        </label>
                    </div>))
                }
            </div>
        </ModalContent>
        <ModalFooter
            onClose={handleClose}
            onSubmit={onChooseIcon}
            submitButtonType="button"
            submitButtonText="Choose icon"
        />
    </>
};

export default IconsModal;
