import React, { useContext, useEffect, useCallback } from 'react';
import { useEditor } from '@craftjs/core';

import { EditHeader } from '../EditHeader';
import ModalWrapper from "../../common/Modal/ModalWrapper";
import { AuthContext, SettingsContext } from "../../../context";
import { deserializeCraftContent } from "../../../utils";

export const Viewport = ({ children }) => {
    const { enabled, connectors, actions: { deserialize } } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    const { publishedContent, draftContent } = useContext(AuthContext);
    const { editor, auth, menu = [], permissions, links } = useContext(SettingsContext);

    useEffect(() => {
        deserializeCraftContent(publishedContent, deserialize);
    }, [publishedContent, deserialize]);

    const handleOnEdit = useCallback(() => {
        const content = enabled ? publishedContent : draftContent;
        deserializeCraftContent(content, deserialize);
    }, [draftContent, publishedContent, enabled])

    return (
        <div className="bg-page-bg flex flex-col relative min-h-screen">
            {auth && <EditHeader
                editor={editor}
                upgradeLink={links.activation}
                permissions={permissions}
                menu={menu}
                onEdit={handleOnEdit}
            />}
            <div className="flex flex-1" ref={(ref) => (connectors.select(connectors.hover(ref, null), null))}>
                {children}
            </div>
            <ModalWrapper />
        </div>
    );
};
