import { useState, useContext, useRef } from 'react';
import { useNode } from '@craftjs/core';

import ModalFooter from '../../common/Modal/ModalFooter';
import ModalHeader from '../../common/Modal/ModalHeader';
import ModalContent from '../../common/Modal/ModalContent';
import CustomInput from '../../common/Controls/CustomInput';
import CustomQuillElement from '../../common/Controls/CustomQuillElement';
import FormImageUploadWrapper from '../../common/FormImageUploadWrapper';
import { SettingsContext } from '../../../context';
import { dispatchChange, handleCloseModal, parseTemplateValues } from '../../../utils';
import { useOnChange } from '../../../hooks';

const FormModalSettings = ({ onSave }) => {
    const {
        props: { title, description },
        actions: { setProp, setCustom },
    } = useNode(node => ({ props: node.data.props }));

    const settings = useContext(SettingsContext);
    const [isLoading, setLoading] = useState(false);
    const [newDescription, setNewDescription] = useState(parseTemplateValues(description, settings));
    const [newTitle, setNewTitle] = useState(parseTemplateValues(title, settings));

    const changeRef = useRef(null);
    const isChanged = useOnChange(changeRef);

    const handleClose = () => handleCloseModal(setCustom);

    const handleSave = async (e) => {
        e.preventDefault();
        handleClose();
        if (isChanged) {
            setLoading(true);
            setProp(props => {
                props.description = newDescription;
                props.title = newTitle;
            });
            await onSave();
            setLoading(false);
        }
    }

    return (
        <FormImageUploadWrapper parentIsLoading={isLoading} onSubmit={handleSave}>
            <ModalHeader title='Edit Join Form section' onClose={handleClose} />
            <ModalContent outerRef={changeRef}>
                <div className='py-20 px-24'>
                    <CustomInput
                        label='Add a section title'
                        onChange={setNewTitle}
                        value={newTitle}
                        className='mb-20'
                    />
                    <CustomQuillElement
                        title='Add a text'
                        value={newDescription}
                        className='quill-text_block'
                        onChange={value => {
                            dispatchChange(changeRef);
                            setNewDescription(value)
                        }}
                    />
                </div>
            </ModalContent>
            <ModalFooter onClose={handleClose} />
        </FormImageUploadWrapper>
    )
};

export default FormModalSettings;
