import React, { useContext, useEffect } from "react";
import { useEditor, useNode } from '@craftjs/core';
import { Route, Routes, Outlet, useLocation } from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';

import AddSectionToolbox from './AddSectionToolbox';
import { generateRoute } from "../../utils";
import { PATH_ROOT, routes, titles } from "../../config";
import Header from "../common/Header";
import Footer from "../common/Footer";
import InfoBanner from "../common/InfoBanner";
import CommonHeading from "../common/CommonHeading";
import { AppContext, SettingsContext } from "../../context";
import Icon from "../../assets/images/icons";
import ScrollToTop from "../common/ScrollToTop";

const AppWrapper = () => {
    const { connectors: { connect } } = useNode();
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
    const mainWrapperRef = React.useRef(null);
    const { pathname } = useLocation();
    const { appState, setAppState } = useContext(AppContext);
    const { showBanners, hiddenPages } = useContext(SettingsContext);
    const splitPathName = pathname.split('/');
    const isNotTeamPath = splitPathName.length === 2;
    const pageTitle = titles[splitPathName.at(1)];

    useEffect(() => {
        if (appState.toast.type && !appState.init) {
            toast[appState.toast.type](appState.toast.message, appState.toast.options);
            setAppState(prevState => ({
                ...prevState,
                toast: {},
            }))
        }
    }, [appState.init, appState.toast]);

    return (
        <>
            <Header />
            {isNotTeamPath && (pageTitle || showBanners) && <div className="w-full wrapper pt-48">
                {pageTitle && <CommonHeading mainHeader className='capitalize flex-1'>{pageTitle}</CommonHeading>}
                {showBanners ? <InfoBanner /> : null}
            </div>}
            <main
                id="main-wrapper"
                ref={ref => {
                    connect.current = ref;
                    mainWrapperRef.current = ref;
                    return ref;
                }}
                className='text-content font-main-font relative flex-1'>
                <ScrollToTop />
                <Routes>
                    <Route path={PATH_ROOT} element={<Outlet />}>
                        {generateRoute(routes, hiddenPages)}
                    </Route>
                </Routes>
            </main>
            {enabled && <AddSectionToolbox mainWrapperRef={mainWrapperRef} />}
            <Footer />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                toastClassName="rounded-[6px] items-center shadow-[0_4px_12px_0px_rgba(0,0,0,0.15)]"
                closeButton={({ closeToast }) => <Icon
                    name="close"
                    className="min-w-[24px] w-24 fill-white"
                    onClick={closeToast}
                />}
            />
        </>
    )
}

AppWrapper.craft = {
    displayName: 'AppWrapper',
    custom: {
        isHideTopbar: true,
    }
};

export default AppWrapper;
