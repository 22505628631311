import React, { useState, useEffect, useRef } from 'react';

import CustomInput from '../../../common/Controls/CustomInput';
import CustomSelect from '../../../common/Controls/CustomSelect';
import {
    filterStatesByCountry,
    dispatchChange,
    defer,
    loadGoogleMapsLibrary,
    loadGoogleMarkersLibrary
} from '../../../../utils';

const MAP_ID =  'SETTINGS_MAP_ID';

const DEFAULT_EMPTY_DATA = {
    name: '',
    description: '',
    type: '',
    logoPath: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    latitude: '',
    longitude: '',
};

const Map = ({countries, states, data}) => {
    const [place, setPlace] = useState(data.streetAddress);
    const [state, setState] = useState(data || DEFAULT_EMPTY_DATA);
    const [filteredStates, setFilteredStates] = useState(filterStatesByCountry({
        states,
        countries,
        countryId: data.country
    }));
    const [map, setMap] = useState(null);
    const [librariesLoaded, setLibrariesLoaded] = useState(false);

    const countrySelectRef = useRef(null);
    const stateSelectRef = useRef(null);
    const containerRef = useRef(null);
    const mapRef = useRef(null);
    const infoWindowRef = useRef(null);
    const cardRef = useRef(null);
    const inputRef = useRef(null);

    const handleChangeValue = key => {
        return value => setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    }

    const GoogleMaps = window.google.maps || {};

    useEffect(() => {
        async function initializeMap() {
            const MapEl = await loadGoogleMapsLibrary();
            const MarkerEl = await loadGoogleMarkersLibrary();

            const initializedMap = new MapEl(
                mapRef.current,
                {
                    center: {lat: 40.749933, lng: -73.98633},
                    zoom: 13,
                    mapTypeControl: false,
                    streetViewControl: false,
                    disableDefaultUI: true,
                    mapId: MAP_ID,
                }
            );

            setMap(initializedMap);
            setLibrariesLoaded(true);
        }

        if (!map) {
            initializeMap();
        }
    }, [map]);

    useEffect(() => {
        if (map && librariesLoaded) {
            loadGoogleMarkersLibrary().then(MarkerEl => {
                const options = {
                    fields: ['address_components', 'geometry', 'name', 'website'],
                    componentRestrictions: {country: ['us', 'ca']},
                    strictBounds: false,
                    language: 'en',
                };
                map.controls[GoogleMaps.ControlPosition.TOP_LEFT].push(cardRef.current);
                const autocomplete = new GoogleMaps.places.Autocomplete(inputRef.current, options);
                autocomplete.bindTo('bounds', map);

                const infoWindow = new GoogleMaps.InfoWindow();
                const infoWindowContent = infoWindowRef.current;

                infoWindow.setContent(infoWindowContent);

                const marker = new MarkerEl({map});
                //Old value: anchorPoint: new GoogleMaps.Point(0, -29)

                // Here is no event to load autocomplete input. That's why focusing input field in 500ms after google map is full loaded.
                // GoogleMaps.event.addListener(initializedMap, 'tilesloaded', function () {
                //     setTimeout(() => {
                //         inputRef.current?.focus();
                //     }, 500);
                //     GoogleMaps.event.clearListeners(initializedMap, 'tilesloaded');
                // });

                autocomplete.addListener('place_changed', () => {
                    infoWindow.close();
                    marker.map = null;

                    const place = autocomplete.getPlace();

                    if (!place.geometry || !place.geometry.location) {
                        // User entered the name of a Place that was not suggested and
                        // pressed the Enter key, or the Place Details request failed.
                        return;
                    }

                    // If the place has a geometry, then present it on a map.
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(17);
                    }

                    marker.position = place.geometry.location;
                    marker.map = map;
                    infoWindowContent.children['place-name'].textContent = place.name;
                    infoWindowContent.children['place-address'].textContent = place.formatted_address;
                    infoWindow.open(map, marker);
                    setPlace(place);

                    setTimeout(() => {
                        const newState = {};
                        let newFilteredStates;
                        if (place.name) {
                            newState.name = place.name;
                        }
                        if (place.address_components && Array.isArray(place.address_components)) {
                            const addresses = place.address_components;
                            const getDataByType = (data, type) => data.find(item => item.types.includes(type));
                            const [country, streetNumber, streetName, city, state, postalCode] = [
                                getDataByType(addresses, 'country'),
                                getDataByType(addresses, 'street_number'),
                                getDataByType(addresses, 'route'),
                                getDataByType(addresses, 'locality'),
                                getDataByType(addresses, 'administrative_area_level_1'),
                                getDataByType(addresses, 'postal_code'),
                            ];
                            newState.streetAddress = `${streetNumber?.long_name || ''} ${streetName?.long_name || ''}`
                            newState.city = city?.long_name || '';
                            newState.postalCode = postalCode?.long_name || '';
                            const countryName = country?.long_name;
                            const countryId = countries[countryName];
                            newFilteredStates = countrySelectRef.current.value === countryId
                                ? filteredStates
                                : filterStatesByCountry({states, countries, countryName});

                            defer(() => {
                                countrySelectRef.current.value = countryId || '';
                                stateSelectRef.current.value = newFilteredStates[state?.long_name] || '';
                            }, 10);
                        }
                        if (place.geometry.location) {
                            newState.latitude = place.geometry.location.lat() || '';
                            newState.longitude = place.geometry.location.lng() || '';
                        }
                        dispatchChange(containerRef);
                        setState(prevState => ({ ...prevState, ...newState }));
                        setFilteredStates(newFilteredStates);
                    }, 50);
                });
            })
        }

        return () => {
            const pacElements = document.querySelectorAll('.pac-container.pac-logo');
            pacElements.forEach(element => element.remove());
        }
    }, [librariesLoaded]);

    useEffect(() => {
        const handleFullScreen = event => {
            let target = event.target;
            let pacContainerElements = document.querySelectorAll('.pac-container');
            if (pacContainerElements.length > 0) {
                let pacContainer = document.getElementsByClassName('pac-container')[0];
                if (pacContainer.parentElement === target) {
                    document.getElementsByTagName('body')[0].appendChild(pacContainer);
                } else {
                    target.appendChild(pacContainer);
                }
            }
        }
        document.addEventListener('fullscreenchange', handleFullScreen);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreen);
        }
    }, []);

    return <>
        <div className='hidden'>
            <div
                className='light bg-white text-content border-0 rounded-6 m-10 p-0 w-[calc(100%-20px)] overflow-hidden shadow-[0_1px_4px_-1px_rgba(0,0,0,0.3)]'
                ref={cardRef}
            >
                <input
                    ref={inputRef}
                    type='text'
                    data-skip-on-change={true}
                    placeholder='Search location'
                    className='px-[11px] rounded-6 h-40 text-[15px] w-full overflow-ellipsis border border-border'
                />
            </div>
            <div className="light text-content" ref={infoWindowRef}>
                <span id='place-name' className='title'></span><br/>
                <span id='place-address'></span>
            </div>
        </div>
        <div ref={containerRef} className='w-full flex flex-col gap-8'>
            <span className="text-content text-label font-semibold">Club address (or primary practice venue)</span>
            <div className="flex flex-col gap-24 min-h-[calc(300px+24px+24px)]">
                <div ref={mapRef} className={`h-[345px] rounded-6 w-full max-h-full flex`}/>
                <div className={`h-fit transition-all ease-out duration-[250ms] :w-full flex flex-col gap-16
                   ${place ? 'opacity-1 max-h-[1000px]' : '[&_*]:invisible opacity-0 max-h-0'}
                `}>
                    <CustomInput
                        label='Street address'
                        placeholder="Type here"
                        name='streetAddress'
                        value={state.streetAddress}
                        onChange={handleChangeValue('streetAddress')}
                    />
                    <div className='flex gap-16'>
                        <CustomSelect
                            outerRef={countrySelectRef}
                            label='Country'
                            name='country'
                            options={countries}
                            value={state.country}
                            onChange={countryId => {
                                handleChangeValue('country')(countryId);
                                setFilteredStates(filterStatesByCountry({ states, countries, countryId }));
                            }}
                            className='flex-1'
                        />
                        {filteredStates && <CustomSelect
                            outerRef={stateSelectRef}
                            label='State / Province'
                            name='state'
                            options={filteredStates}
                            value={state.state}
                            onChange={handleChangeValue('state')}
                            className='flex-1'
                        />}
                    </div>
                    <div className='flex gap-16'>
                        <CustomInput
                            label='City'
                            name='city'
                            value={state.city}
                            onChange={handleChangeValue('city')}
                            className='flex-1'
                        />
                        <CustomInput
                            label='Postal code'
                            name='postalCode'
                            value={state.postalCode}
                            onChange={handleChangeValue('postalCode')}
                            className='flex-1'
                        />
                    </div>
                    <div className='flex gap-16'>
                        <CustomInput
                            label='Latitude'
                            name='latitude'
                            value={state.latitude}
                            onChange={handleChangeValue('latitude')}
                            className='flex-1'
                        />
                        <CustomInput
                            label='Longitude'
                            name='longitude'
                            value={state.longitude}
                            onChange={handleChangeValue('longitude')}
                            className='flex-1'
                        />
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default Map;
