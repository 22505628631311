import { useEffect, useRef } from 'react';
import FocusTrap from "focus-trap-react";

import { stopScrolling } from "../../../utils";

const focusTrapOptions = trapFallbackRef => ({
    checkCanFocusTrap: (triggerButtons) => {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (triggerButtons.every(triggerButton => getComputedStyle(triggerButton).visibility !== 'hidden')) {
                    resolve();
                    clearInterval(interval);
                }
            }, 5);
        });
    },
    fallbackFocus: trapFallbackRef.current,
    allowOutsideClick: true,
});

export default function Modal({
    onClick,
    children,
    wrapperClassName,
    containerClassName,
    move,
    animated = true,
    setLightTheme = true,
    open = true,
}) {
    const trapFallbackRef = useRef(null);

    useEffect(() => {
        const checkIsSomeModalOpen = () => document.querySelectorAll('section[role="dialog"].show').length > 0;

        stopScrolling(checkIsSomeModalOpen());
        return () => {
            stopScrolling(checkIsSomeModalOpen());
        }
    }, [open]);

    return <section
        role="dialog"
        aria-modal="true"
        onClick={onClick}
        className={`${open && !move ? 'show' : 'invisible'}${animated ? ' animated-modal' : ''}${move ? ' move' : ''} fixed bg-black bg-opacity-30 z-[1100] top-0 left-0 right-0 flex items-center h-full w-full ${wrapperClassName ?? ''}`}
    >
        <div
            ref={trapFallbackRef}
            className={`${setLightTheme ? 'light bg-white' : 'dark bg-page-bg text-title'} flex flex-1 flex-col relative lg:max-w-[65vw] max-h-[100vh] lg:max-h-[calc(100vh-110px)] mx-auto rounded-4 overflow-hidden font-main-font ${containerClassName ?? ''}`}>
            {children
                ? <FocusTrap active={open} focusTrapOptions={focusTrapOptions(trapFallbackRef)}>
                    <div className="flex flex-col max-h-[inherit] overflow-hidden">
                        {children}
                    </div>
                </FocusTrap>
                : null}
        </div>
    </section>;
}
