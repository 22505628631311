import Icon from '../../../assets/images/icons';

export default function SocialLink({ type, color, bg, link, classname }) {
    return (
        <span className={`w-44 h-44 relative rounded-[70px] cursor-pointer ${bg ? bg : 'bg-footer-icon-bg'} ${classname ?? ''}`}>
            <a
                className='absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4'
                href={link}
                rel='noreferrer'
                target='_blank'
            >
                <Icon arr='SOCIALS' name={type} className={color ? color : 'fill-header-footer-text'} />
            </a>
        </span>
    )
}
