import { useContext } from "react";
import { Outlet } from 'react-router-dom';

import TeamMobileHeader from '../page-team/components/TeamMobileHeader';
import InfoBanner from "../common/InfoBanner";
import { SettingsContext } from "../../context";

const TeamMobileLayout = () => {
    const { showBanners } = useContext(SettingsContext);
    return (
        <section className='bg-page-bg text-content'>
            <TeamMobileHeader />
            <main className='px-default-teams-px py-default_section_py max-w-[100vw]'>
                {showBanners ? <InfoBanner /> : null}
                <Outlet />
            </main>
        </section>
    )
}

export default TeamMobileLayout;
