import { useContext } from 'react';
import { useNode } from '@craftjs/core';

import SocialLink from '../../common/Buttons/SocialLinks';
import CommonHeading from '../../common/CommonHeading';

import SocialLinksModalSettings from './SocialLinksModalSettings';
import { SettingsContext } from '../../../context';
import { parseTemplateValues } from '../../../utils';
import { DEFAULT_SOCIALS } from '../../../config';

const SocialLinks = ({ title, description }) => {
    const { connectors: { connect } } = useNode();
    const settings = useContext(SettingsContext);
    const config = settings.configuration || {};

    return (
        <div ref={connect}
             className='wrapper text-center py-default_section_py lg:py-default_section_md_py bg-page-bg'>
            {title && <CommonHeading className='break-words-custom' center craftComponent textSection>
                {parseTemplateValues(title, settings)}
            </CommonHeading>}
            {description && <div className='quill-content break-words-custom w-full lg:w-2/3 mx-auto mb-24 lg:mb-32 text-content text-m leading-standard'>
                <div dangerouslySetInnerHTML={{ __html: parseTemplateValues(description, settings)}} />
            </div>}
            {title && !description && <div className='h-[8px]'></div>}
            {DEFAULT_SOCIALS.some(key => config[key + 'Visible'])
                ? <p className='flex justify-center'>
                    {DEFAULT_SOCIALS.map(key =>
                        config[key + 'Visible'] && config[key]
                            ? <SocialLink
                                key={key}
                                type={key}
                                bg='bg-title'
                                color='fill-page-bg'
                                classname='mr-16 md:mr-24 lg:mr-32 last:!mr-0'
                                link={config[key]}
                            />
                            : null)}
                </p>
                : null}
        </div>
    );
};

SocialLinks.craft = {
    displayName: 'SocialLinks',
    props: {
        title: 'Idea for section name — Stay Connected',
        description: 'Idea for section text: Follow us on our social media platforms for the latest updates, events, and opportunities!',
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: SocialLinksModalSettings,
    },
};

export default SocialLinks;
