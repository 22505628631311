import { useContext } from 'react';

import { TeamDataContext } from '../../../context';
import { EmptyContent } from '../../common/EmptyContent';
import TeamShortStatistics from '../components/TeamShortStatistics';

export default function TeamStatistics() {
    const data = useContext(TeamDataContext);
    const statistics = data.statistics || {};
    const isEmpty = !statistics?.l10?.win && !statistics?.l10?.lose;
    const max = Math.max(
        statistics.ppg_number,
        statistics.others_ppg_number,
        statistics.opp_ppg_number,
        statistics.others_opp_ppg_number,
    ) / 90;

    const setLinearWidthInPercentage = score => (score || 0) / max + '%';

    return (
        isEmpty
        ? <EmptyContent icon='statistic' text="The season's statistics will be visible once your initial tournament games are played."/>
        : <div className='lg:max-w-[750px]'>
                <TeamShortStatistics data={statistics} />
                {/* TODO: optimize code below with replacing it to map function */}
                <div className='py-default_section_py border-t border-border w-full'>
                    <h2 className='font-accent-font font-semibold text-h5 leading-h5 text-title'>Points-Per-Games (PPG)</h2>
                    <div className='mt-24'>
                        <p className='text-content text-xs leading-xs mb-12'>
                            {`All ${data.grade_level_name} Grade Teams:`}
                        </p>
                        <div className='flex w-full h-24 rounded-16 bg-statistic-linear-bg'>
                            <div style={{width: setLinearWidthInPercentage(statistics.others_ppg_number)}}
                                 className='bg-content h-full rounded-l-16'></div>
                            <span
                                className='bg-page-bg border border-content rounded-4 -my-4 py-[3px] px-[5px] text-center text-s leading-standard font-semibold'>
                        {statistics.others_ppg}
                    </span>
                        </div>
                    </div>
                    <div className='mt-24'>
                        <p className='text-content text-xs leading-xs mb-12'>
                            {`${data.name || 'This team'}:`}
                        </p>
                        <div className='flex w-full h-24 rounded-16 bg-statistic-linear-bg'>
                            <div style={{width: setLinearWidthInPercentage(statistics.ppg_number)}}
                                 className='bg-accent-color h-full rounded-l-16'></div>
                            <span
                                className='bg-page-bg border border-accent-color rounded-4 -my-4 py-[3px] px-[5px] text-center text-s leading-standard font-semibold'>
                        {statistics.ppg}
                    </span>
                        </div>
                    </div>
                </div>
                <div className='pt-default_section_py border-t border-border w-full'>
                    <h2 className='font-accent-font font-semibold text-h5 leading-h5'>Opponents PPG (OPP PPG)</h2>
                    <div className='mt-24'>
                        <p className='text-content text-xs leading-xs mb-12'>
                            {`All ${data.grade_level_name} Grade Teams:`}
                        </p>
                        <div className='flex w-full h-24 rounded-16 bg-statistic-linear-bg'>
                            <div style={{width: setLinearWidthInPercentage(statistics.others_opp_ppg_number)}}
                                 className='bg-content h-full rounded-l-16'></div>
                            <span
                                className='bg-page-bg border  border-content rounded-4 -my-4 py-[3px] px-[5px] text-center text-s leading-standard font-semibold'>
                        {statistics.others_opp_ppg}
                    </span>
                        </div>
                    </div>
                    <div className='mt-24'>
                        <p className='text-content text-xs leading-xs mb-12'>
                            {`${data.name || 'This team'}:`}
                        </p>
                        <div className='flex w-full h-24 rounded-16 bg-statistic-linear-bg'>
                            <div style={{width: setLinearWidthInPercentage(statistics.opp_ppg_number)}}
                                 className='bg-accent-color h-full rounded-l-16'></div>
                            <span
                                className='bg-page-bg border border-accent-color rounded-4 -my-4 py-[3px] px-[5px] text-center text-s leading-standard font-semibold'>
                        {statistics.opp_ppg}
                    </span>
                        </div>
                    </div>
                </div>
            </div>
    )
}
