import { useContext } from 'react';

import { TeamDataContext } from '../../../context';
import { copyUrl, shareData } from '../../../utils';
import Icon from '../../../assets/images/icons';
import LinkWithQuery from '../../common/LinkWithQuery';

const TEAM_MENU_LIST = [
    {text: 'Schedule', href: 'schedule'},
    {text: 'Details', href: 'details'},
    {text: 'Statistics', href: 'statistics'},
    {text: 'History', href: 'history'},
    {text: 'Roster', href: 'roster'},
];

export default function TeamMobileHeader() {
    const data = useContext(TeamDataContext);
    return (
        <header>
            <div className='bg-gradient-sub-header text-header_main py-16'>
                <div className='wrapper flex items-center'>
                    {data.logo && <div className='w-40 h-40 mr-12'>
                        <img src={data.logo} className='w-full h-full object-cover rounded-full' alt='Team small logo' />
                    </div>}
                    <p className='mr-12 flex flex-col'>
                        <span className='text-label leading-label text-header_main'>{data.organization_name}</span>
                        <span className='text-s leading-standard font-semibold text-header_main'>{data.schedule_name}</span>
                    </p>
                    <button
                        type='button'
                        className='ml-auto cursor-pointer'
                        onClick={() => navigator.share ? shareData() : copyUrl()}
                    >
                        <Icon
                            name={navigator.share ? 'share_mobile' : 'share_desktop'}
                            className='fill-header_main'
                        />
                    </button>
                </div>
            </div>
            <div className='relative pt-18 flex items-center border-b border-b-border max-w-[100vw] overflow-hidden teams_mobile_menu'>
                <nav className='w-full flex justify-between uppercase font-bold text-button leading-button no-scrollbar overflow-x-scroll'>
                    {TEAM_MENU_LIST.map((menuItem) => (
                        <LinkWithQuery
                            isNav
                            key={menuItem.text}
                            to={menuItem.href}
                            className={({isActive}) => {
                                return `${isActive ? 'border-b-accent-color' : 'border-b-transparent text-border'} pb-16 border-b-team_menu last:pr-18 mr-18 last:mr-0 first:ml-18 inline-block`
                            }}
                        >
                            {menuItem.text}
                        </LinkWithQuery>
                    ))}
                </nav>
            </div>
        </header>
    )
}
