export default class InfoWindow extends window.google.maps.OverlayView {
    position;
    containerDiv;
    constructor(map) {
        super();
        this.setMap(map);
        this.containerDiv = document.createElement("div");
        this.containerDiv.style.position = 'relative';
        this.containerDiv.classList.add("popup-container");
        // Optionally stop clicks, etc., from bubbling up to the map.
        InfoWindow.preventMapHitsAndGesturesFrom(this.containerDiv);
    }
    /** Called when the popup is added to the map. */
    onAdd() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
    }
    /** Called when the popup is removed from the map. */
    onRemove() {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    }
    hide() {
        this.containerDiv.style.display = 'none';
    }
    show() {
        this.containerDiv.style.display = 'block';
    }
    setPosition(position) {
        this.position = position;
        this.show();
    }
    setContent(newContent) {
        this.containerDiv.innerHTML = newContent;
    }
    /** Called each frame when the popup needs to draw itself. */
    draw() {
        if (this.position) {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            this.containerDiv.style.left = divPosition.x + "px";
            this.containerDiv.style.top = divPosition.y + "px";
        }
    }
}
