import React from "react";
import { Element } from "@craftjs/core";

import Empty from "../components/craftJS/empty";
import Venues from "../components/page-venues";

const VenuesContainer = () => {
    return <Element id="venues-canvas" canvas is={Empty}>
        <Venues />
    </Element>;
};

export default VenuesContainer;
