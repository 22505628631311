import React from "react";
import { navigateByCoords } from "../../../utils";
import Button from "./Button";

const DEFAULT_NAVIGATE_TITLE = 'Maps will open showing the direction to the location';
const DEFAULT_NAVIGATE_TEXT = 'Navigate';

const NavigateButton = ({ latitude, longitude, title, text, onClick, className, size= 'small' }) => {
    const handleClick = e => {
        if (onClick) {
            onClick(e);
        }
        navigateByCoords({ latitude, longitude });
    }
    return <Button
        className={className}
        size={size}
        title={title || DEFAULT_NAVIGATE_TITLE}
        onClick={handleClick}
    >
        {text || DEFAULT_NAVIGATE_TEXT}
    </Button>
};

export default NavigateButton;
