import moment from 'moment';

import Icon from '../../../assets/images/icons';
import NavigateButton from '../../common/Buttons/NavigateButton';
import GradientLogo from "../../common/GradientLogo";
import { generateTitle } from "../../../utils";

const LETTERS = ['A', 'B'];

export default function TeamScheduleItem({
    isHistory,
    final,
    formattedTime,
    location_name,
    division_name,
    gameName,
    date,
    teams,
    calendarEvent,
    court_name,
    ...rest
}) {
    return (
        <div className='text-xs leading-xs'>
            <div className='border border-border rounded-8 overflow-hidden'>
                <div className='gap-[6px] grid grid-cols-[max-content_fit-content(80%)_fit-content(80%)] p-12 bg-card-default border-b border-b-border font-semibold'>
                    <p>{formattedTime + ','}</p>
                    <p className="truncate">{location_name}</p>
                    <p className="truncate">({court_name})</p>
                </div>
                <div className='flex flex-nowrap overflow-hidden'>
                    <div className='bg-card-team-schedule flex-1'>
                        {(teams || []).map((team, index) => {
                            const isWinner = rest[`team${LETTERS[index]}Score`] > rest[`team${LETTERS[+!index]}Score`];
                            return <div
                                key={team.name + team.id}
                                className={`relative items-center flex text-accent-color pl-12 min-h-[48px] first:border-b first:border-b-border ${isWinner ? 'schedule_winner' : ''}`}
                            >
                                <div className='shrink-0 w-24 h-24 mr-8 rounded-14 overflow-hidden'>
                                    {team.logo
                                        ? <img title={generateTitle(team.grade, team.gender_name)} alt='Team logo' className='h-full object-cover' src={team.logo} />
                                        : <GradientLogo title={generateTitle(team.grade, team.gender_name)} text={team.grade} gender={team.gender} />
                                    }
                                    </div>
                                <a target="_blank" href={isHistory ?`${team.link}/history`:team.link} className='overflow-ellipsis overflow-hidden max-w-[70%]'>
                                    {team.full_name}
                                </a>
                                <span
                                    className='pl-8 pr-14 ml-auto inline-block text-title text-s font-semibold leading-standard whitespace-nowrap'>
                                {final ? `${index ? rest.teamBScore : rest.teamAScore}` : `${team.season_win || 0} - ${team.season_lose || 0}`}
                            </span>
                            </div>
                        })}
                    </div>
                    <div className='w-[96px] overflow-hidden bg-card-team-schedule flex items-center text-center border-l border-l-border p-8 font-semibold text-title'>
                        <span className='text-center overflow-hidden overflow-ellipsis flex-1'>
                            {final
                                ? <>
                                    <span>FINAL</span>
                                    <span className="block">{moment.utc(date).format('ddd M/D')}</span>
                                </>
                                : moment.utc(date).format('dddd MMM D')}
                        </span>
                    </div>
                </div>
                <div className='font-semibold p-12 min-h-[49px] bg-card-default border-t border-t-border flex justify-between basis-full'>
                    <span className='break-all'>{division_name} / {gameName}</span>
                    <NavigateButton
                        className='min-w-[25px] !h-24 ml-auto bg-transparent border-none !p-0'
                        latitude={calendarEvent?.latitude || 0}
                        longitude={calendarEvent?.longitude || 0}
                        text={<Icon name='geolocation' className='fill-accent-color'/>}
                    />
                </div>
            </div>
        </div>
    )
}
