import { useRef, useState, useContext } from 'react';
import { useNode } from '@craftjs/core';

import ModalHeader from '../../common/Modal/ModalHeader';
import ModalContent from '../../common/Modal/ModalContent';
import CustomInput from '../../common/Controls/CustomInput';
import ModalFooter from '../../common/Modal/ModalFooter';
import Tile from './Tile';
import { SettingsContext } from '../../../context';

import {
    dispatchChange,
    handleAddOneMoreItem,
    handleChangeItemsOrder,
    handleCloseModal,
    handleDeleteItem, handleModalOpen,
    handleSetItemValue,
    parseTemplateValues
} from '../../../utils';
import FormImageUploadWrapper from "../../common/FormImageUploadWrapper";
import { useOnChange } from "../../../hooks";
import { modalTypes, prefixByName } from "../../../config";
import { DEFAULT_PREFIX_DIVIDER } from "../../../const";

const DEFAULT_ITEM = { icon: '', title: '', description: '' };

const TilesModalSettings = ({ onSave }) => {
    const {
        props: { uuid, items, title },
        actions: { setProp, setCustom },
    } = useNode(node => ({ props: node.data.props }));

    const settings = useContext(SettingsContext);
    const [isLoading, setLoading] = useState(false);
    const [newTitle, setNewTitle] = useState(parseTemplateValues(title, settings));
    const [newItems, setNewItems] = useState(items.map(item => {
        const newItem = { ...item };
        newItem.title = parseTemplateValues(newItem.title, settings);
        newItem.description = parseTemplateValues(newItem.description, settings);
        return newItem;
    }));

    const ref = useRef(null);
    const changeRef = useRef(null);
    const isChanged = useOnChange(changeRef);

    const handleClose = () => handleCloseModal(setCustom);

    const handleAddOneMore = () => {
        dispatchChange(changeRef);
        handleAddOneMoreItem(
            setNewItems,
            DEFAULT_ITEM,
            `${prefixByName.Tiles}${DEFAULT_PREFIX_DIVIDER}${uuid}`,
            { element: ref.current },
        );
    };

    const handleChangeOrder = (index, newIndex) => {
        dispatchChange(changeRef);
        handleChangeItemsOrder(setNewItems, index, newIndex);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        handleClose();
        if (isChanged) {
            setLoading(true);
            setProp(props => {
                props.items = newItems;
                props.title = newTitle;
            });
            await onSave();
            setLoading(false);
        }
    };

    const handleSetIconsModal = (id, icon) => {
        handleModalOpen({
            modalType: modalTypes.icons,
            onAccept: item => {
                handleSetChosenIcon(item, id)
            },
            data: { icon },
        });
    };

    const handleSetChosenIcon = (value, id) => {
        dispatchChange(changeRef);
        handleSetItemValue(setNewItems)(value, id, 'icon');
    };

    return (
        <FormImageUploadWrapper parentIsLoading={isLoading} onSubmit={handleSave}>
            <ModalHeader title='Edit Tiles section' onClose={handleClose} />
            <ModalContent outerRef={changeRef}>
                <div className='w-full'>
                    <CustomInput
                        label='Add a section title'
                        onChange={setNewTitle}
                        value={newTitle}
                        className='mb-20 mt-24 mx-24'
                    />
                    <div className='flex justify-between border-t border-color-border overflow-hidden'>
                        <div className='w-full flex flex-wrap'>
                            {newItems?.map((item, index) =>
                                <Tile
                                    key={item.id}
                                    handleSetValue={(value, id, type) => {
                                        dispatchChange(changeRef);
                                        handleSetItemValue(setNewItems)(value, id, type)
                                    }}
                                    handleChooseIcon={handleSetIconsModal}
                                    handleDelete={id => {
                                        dispatchChange(changeRef);
                                        handleDeleteItem(setNewItems)(id);
                                    }}
                                    handleChangeOrder={handleChangeOrder}
                                    index={index}
                                    isLast={index === newItems.length - 1}
                                    {...item}
                                />,
                            )}
                            <div ref={ref} className='invisible'></div>
                        </div>
                    </div>
                </div>
            </ModalContent>
            <ModalFooter
                onClose={handleClose}
                additionalButton={{ text: 'Add Tile', onClick: handleAddOneMore }}
                elementsCount={`${newItems.length} tile${newItems.length > 1 ? 's': ''} added`}
            />
        </FormImageUploadWrapper>
    );
};

export default TilesModalSettings;
