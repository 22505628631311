import { useRef } from 'react';

import { useFetch } from '../../hooks';

import Accordion from '../common/Accordion';
import Spinner from '../common/Spinner';
import LinkWithQuery from '../common/LinkWithQuery';
import { EmptyContent } from '../common/EmptyContent';

export default function Teams() {
    const [data, isLoading] = useFetch('teamsData');
    const containerRef = useRef(null);
    return (
        <div className='mt-[8px] bg-page-bg text-title'>
            <div className='wrapper relative pb-default_section_py px-24 lg:px-72 lg:pb-default_section_md_py'>
                <div className='max-w-[100vw]' ref={containerRef}>
                    {isLoading
                        ? <Spinner targetRef={containerRef}/>
                        : data.some(group => group?.teams.length)
                            ? data.map(group => (
                                group.teams.length
                                    ? <Accordion
                                        isActive
                                        title={group.label}
                                        headerStyle='py-24 px-16 text-h5 lg:text-h4 leading-standard font-bold font-accent-font'
                                        key={group.label}>
                                        <div className='flex flex-col lg:flex-row text-accent-color flex-wrap px-16'>
                                            {group.teams.map((team) => (
                                                <LinkWithQuery
                                                    to={`${team.id}`}
                                                    key={team.id}
                                                    className='py-10 basis-full lg:basis-2/6'
                                                >
                                                    {team.name}
                                                </LinkWithQuery>)
                                            )}
                                        </div>
                                    </Accordion>
                                    : null))
                            : <EmptyContent icon='teams' text='No teams have been added. '/>
                    }
                </div>
            </div>
        </div>
    )
}
