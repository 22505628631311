import { useState, useId } from 'react';

const RadioInput = ({ onChange, label, ...other }) => {
    const id = useId();
    return <div className="flex flex-1 items-center gap-8">
        <input
            id={id}
            type="radio"
            onChange={event => onChange ? onChange(event.target.value) : null}
            {...other}
        />
        <label className="opacity-70" htmlFor={id}>{label}</label>
    </div>
}

const CustomRadioGroup = ({ label, className, options, name, value }) => {
    const [checkedValue, setCheckedValue] = useState(value || options.at(0).value);
    return (
        <div className={`flex flex-col ${className}`}>
            {label && <p className="text-black leading-130 font-semibold text-12 mb-[8px]">{label}</p>}
            <div className="flex">
                {options.map((item, index) => {
                    return <RadioInput
                        key={index}
                        checked={item.value === checkedValue}
                        onChange={setCheckedValue}
                        name={name}
                        {...item}
                    />
                })}
            </div>
        </div>
    )
}

export default CustomRadioGroup;
