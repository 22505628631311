import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import TeamHeader from '../page-team/components/TeamHeader';
import TeamDetails from '../page-team/tabs/Details';
import CommonHeading from "../common/CommonHeading";
import { titles } from "../../config";
import InfoBanner from "../common/InfoBanner";
import { SettingsContext } from "../../context";

const TeamDesktopLayout = () => {
    const { pathname } = useLocation();
    const splitPathName = pathname.split('/');
    const pageTitle = titles[splitPathName.at(3)];
    const { showBanners } = useContext(SettingsContext);

    return (
        <section className='bg-page-bg text-content'>
            <header className='bg-gradient-sub-header'>
                <TeamHeader />
            </header>
            <div className='wrapper flex'>
                <aside className='basis-[23%] py-32 border-r border-border'>
                    <TeamDetails />
                </aside>
                <main className='basis-[77%] max-w-[77%] py-default_section_md_py px-default-teams-md-px lg:pr-0'>
                    {pageTitle && <CommonHeading notShownOnMobiles>{pageTitle}</CommonHeading>}
                    {showBanners ? <InfoBanner /> : null}
                    <Outlet />
                </main>
            </div>
        </section>
    )
}

export default TeamDesktopLayout;
