var _ = require('lodash');
module.exports = Function(_.keys(_.templateSettings.imports), 'return ' + function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table id="bracket" class="bracket 6t3g" border="0" cellspacing="0" cellpadding="0">\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(1, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(4, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(1)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(1)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('1', "<!--start-->W1<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(4)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(4)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(7, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(1, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('1', "", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(4, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-4 '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(1, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-3 '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(4, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right round-4 '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(7)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(7)))==null?'':__t)+
'</td>            \n            <td class="game-result '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('7', "<!--start-->W7<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(2, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(5, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'"></td>            \n            <td class="game-summary '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('7', "Champion", false)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(2)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(2)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('2', "<!--start-->W2<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(5)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(5)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(7, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(2, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('2', "", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(5, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game teamB left-side round-4 '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(7, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(2, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-3 '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(5, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(3, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(6, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(3)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(3)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('3', "<!--start-->W3<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(6)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(6)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('6', "<!--start-->W6<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(3, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('3', "", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(6, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('6', "", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(3, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-3 '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(6, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(8, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td >\n                  <!--BracketPreview--><div style="position: absolute;font-size: 14px;"><div class="uniq-game-indexes" style="text-align: left; font-weight: bold">Game &num;'+
((__t=(getUniqIndex('5')))==null?'':__t)+
' Seating</div><div style="text-align: left;padding-left: 20px;margin-bottom: 15px;"><p class="uniq-game-indexes" style="margin: 0">If Wildcard Game &num;'+
((__t=(getUniqIndex('1')))==null?'':__t)+
', plays Winner Game &num;'+
((__t=(getUniqIndex('2')))==null?'':__t)+
'</p><p class="uniq-game-indexes" style="margin: 0">If Wildcard Game &num;'+
((__t=(getUniqIndex('2')))==null?'':__t)+
', plays Winner Game &num;'+
((__t=(getUniqIndex('3')))==null?'':__t)+
'</p><p class="uniq-game-indexes" style="margin: 0">If Wildcard Game &num;'+
((__t=(getUniqIndex('3')))==null?'':__t)+
', plays Winner Game &num;'+
((__t=(getUniqIndex('1')))==null?'':__t)+
'</p><p style="margin: 0">Make sure opponents didn\'t already play each other</p></div><div class="uniq-game-indexes" style="text-align: left; font-weight: bold">Game &num;'+
((__t=(getUniqIndex('8')))==null?'':__t)+
' and &num;'+
((__t=(getUniqIndex('9')))==null?'':__t)+
'</div><div style="text-align: left;padding-left: 20px;margin-bottom: 15px;"><p style="margin: 0">Make sure opponents didn\'t already play each other</p></div></div><!--BracketPreviewEnd-->                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-4 '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(8)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(8)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('8', "<!--start-->W8<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td >\n                                    \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(8, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('8', "3,4 || 5,6 places", false)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td >\n                                    \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-4 '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(8, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(9, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-4 '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(9)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(9)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('9', "<!--start-->W9<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(9, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('9', "3,4 || 5,6 places", false)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-4 '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(9, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n</table>';
}
return __p;
}.toString()).apply(undefined, _.values(_.templateSettings.imports));
