import React from "react";
import { Element } from "@craftjs/core";

import Empty from "../components/craftJS/empty";
import Schedule from "../components/page-schedule";

const ScheduleContainer = () => {
    return <Element id="schedule-canvas" canvas is={Empty}>
        <Schedule />
    </Element>;
};

export default ScheduleContainer;
