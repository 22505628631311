export const areNumbersEqual = (number1, number2) => Number(number1) === Number(number2);

const findById = (list, id) => {
    const intId = parseInt(id, 10);
    return list.find(item => item.id === (isNaN(intId) ? id : intId) ? item : false);
};

export const getTemplateNumberFromName = template => isNaN(template[1]) ? template[0] : template[0] + template[1];

export const getNumberSuffixFromNumber = number => {
    if (![11, 12, 13].includes((number % 100))) {
        switch (number % 10) {
            // Handle 1st, 2nd, 3rd
            case 1: return number + 'st';
            case 2: return number + 'nd';
            case 3: return number + 'rd';
        }
    }

    return number + 'th';
};

export const getCompiledBracket = ({
    bracketData,
    listTeams,
    games,
    bracketTemplate,
    divisionBracketsGamesIndexesStarts,
    getGameView,
    gamesStatus,
}) => {
    try {
        const { id: bracketId, data } = bracketData;
        const options = {
            getGameView: (gameId, competitorIndex) => {
                if (Number(competitorIndex) === 1) {
                    return '';
                }
                return `<div class="not-parsed-container">${getGameView(gameId, competitorIndex)}</div>`;
            },
            getGameLabel: (gameId, competitorIndex) => {
                return `<div class="not-parsed-container">${getGameView(gameId, competitorIndex)}</div>`;
            },
            getCompetitorTypeSelector: (gameId, competitorIndex) => {},
            getGameIdHtml: bracketGameName => {
                const gameIndex = divisionBracketsGamesIndexesStarts[bracketId] + bracketGameName;
                const game = games.filter(game => areNumbersEqual(game.bracketGameName, bracketGameName) &&
                    areNumbersEqual(game.bracket, bracketId))[0];
                let scheduleHtml = '';
                if (game && game.scheduleTime && game.scheduleCourt) {
                    scheduleHtml = `<div class="schedule"><div>${game.scheduleTime}</div><div class="court">${game.scheduleCourt}</div></div>`;
                }
                return `<div class="game-container"><div class="game-id">${gameIndex}</div>${scheduleHtml}</div>`;
            },
            getGameScheduleHtml: gameId => {}, // TODO: remove it
            getUniqIndex: bracketGameName => {
                return divisionBracketsGamesIndexesStarts[bracketId] + Number(bracketGameName);
            },
            setGameVisibility: bracketGameId => {
                return !gamesStatus[bracketId][bracketGameId] ? 'hidden' : '';
            },
            getGameSummary: (bracketGameName, summary, isResult) => {
                const gameSummary = data[bracketGameName]?.summary || {};
                let updatedSummary = summary;
                const match = summary.match(/<!--start-->[W|L]\d{0,3}<!--end-->/g);
                if (match?.length > 0) {
                    match.forEach(match => {
                        if (match?.length > 22) {
                            const value = match.slice(12, -10);
                            updatedSummary = summary.replace(
                                `<!--start-->${value}<!--end-->`,
                                value[0] + (divisionBracketsGamesIndexesStarts[bracketId] + Number(value.substring(1))),
                            );
                        }
                    });
                }
                let summaryString;

                if (isResult) {
                    if (gameSummary.teamId) {
                        summaryString = findById(listTeams, gameSummary.teamId)?.name;
                    }
                } else {
                    if (gameSummary.hasOwnProperty('changed')) {
                        summaryString = gameSummary.changed;
                    } else {
                        summaryString = gameSummary.final_label || '';
                    }
                }

                return summaryString || updatedSummary;
            },
        };
        return bracketTemplate(options);
    } catch (error) {
        return {
            error,
        };
    }
};

export const generateBracketData = ({ competitorSource, bracket }) => {
    const data = {};

    bracket.data.forEach((games, index) => {
        games.forEach(game => {
            // Fill bracketGames only in case when bracket is new to division
            // or in case 'Restore Bracket Template Defaults'
            if (!data[game.id]) {
                // TODO: here we can fill bracketGames with real teams while new results become available
                data[game.id] = {
                    round: index + 1,
                    competitors: [],
                };
                if (game.summary) {
                    data[game.id].summary = game.summary;
                }
                game.competitors.forEach((competitor, competitorIndex) => {
                    const competitors = data[game.id].competitors;

                    switch (competitor.type) {
                        case competitorSource.fromList:
                            competitors[competitorIndex] = {
                                index: competitor.teamIndex,
                            };
                            break;
                        case competitorSource.customGameResult:
                        case competitorSource.gameResult:
                            competitors[competitorIndex] = {
                                id: competitor.parent,
                                result: competitor.result,
                            };
                            break;
                        case competitorSource.teamId:
                            competitors[competitorIndex] = {
                                id: competitor.teamId,
                                result: competitor.result,
                            };
                            break;
                        case competitorSource.gameResultVariant:
                            competitors[competitorIndex] = competitor;
                            break;
                        case competitorSource.custom:
                            competitors[competitorIndex] = {
                                text: competitor.text,
                            };
                            break;
                        case competitorSource.fromPool:
                            competitors[competitorIndex] = {
                                id: competitor.poolId,
                                result: competitor.teamIndex,
                            };
                            break;
                        case competitorSource.xthLowestPtDiff:
                            competitors[competitorIndex] = {
                                round: competitor.round,
                                teamIndex: competitor.teamIndex,
                            };
                            break;
                        case competitorSource.multiWinnerLoser:
                            competitors[competitorIndex] = {
                                variants: competitor.variants,
                                rules: competitor.rules,
                            };
                            break;
                        case competitorSource.poolPlace:
                            competitors[competitorIndex] = {
                                poolId: competitor.poolId,
                                teamIndex: competitor.teamIndex,
                            };
                            break;
                        case competitorSource.divisionPlace:
                            competitors[competitorIndex] = {
                                teamIndex: competitor.teamIndex,
                            };
                            break;
                        case competitorSource.winnerFromBracketGame:
                        case competitorSource.loserFromBracketGame:
                            competitors[competitorIndex] = {
                                bracketId: competitor.bracketId ?? bracket.id,
                                id: competitor.parent,
                            };
                            break;
                        default:
                            competitors[competitorIndex] = {
                                error: `Unknown Competitor Source Type: ${competitor.type}`,
                            };
                            break;
                    }
                    competitors[competitorIndex].type = competitor.type;
                });
            }
        });
    });

    return { id: bracket.id, template: bracket.template, data };
};
