export default function CommonHeading({
    children,
    notShownOnMobiles,
    className,
    mainHeader,
    center,
    craftComponent,
    textSection}) {
    const styles = `font-accent-font font-bold  text-title text-h3 leading-h3 lg:text-h2 lg:leading-h2 ${notShownOnMobiles ? ' hidden lg:inline-block lg:mb-[8px] ' : ''} ${className || ''}`;
    return <div className={`flex items-center gap-24 ${center? ' justify-center ' : ' justify-between '} ${craftComponent ? textSection ? ' mb-16 lg:mb-24 ' : ' mb-24 lg:mb-32 ' : ' mb-24 '}`}>
        {mainHeader
            ? <h1 id='page-title' className={styles}>{children}</h1>
            : <h2 className={styles}>{children}</h2>
        }
    </div>
}
