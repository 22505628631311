import { useState, useContext } from 'react';
import { useNode } from '@craftjs/core';

import TilesModalSettings from './TilesModalSettings';
import Icon from '../../../assets/images/icons';
import Button from '../../common/Buttons/Button';

import { parseTemplateValues } from '../../../utils';
import CommonHeading from '../../common/CommonHeading';
import { SettingsContext } from '../../../context';

const ICONS_ARRAY = 'TILES';

const Tiles = ({ items, title }) => {
    const { connectors: { connect } } = useNode(node => ({ props: node.data.props }));
    const [viewAll, setViewAll] = useState(false);
    const settings = useContext(SettingsContext);

    return (
        <div className='wrapper py-default_section_py lg:py-default_section_md_py w-full' ref={connect}>
            {title && <CommonHeading className='break-words-custom' craftComponent>{parseTemplateValues(title, settings)}</CommonHeading>}
            <div className='flex flex-wrap gap-24'>
                {items.length
                    ? items.map((item, index) =>
                        <div
                            key={item.id}
                            className={`${index > 2 && !viewAll ? 'hidden lg:block ' : ''}grow basis-full md:basis-[calc(25%_-_18px)]`}
                        >
                            <div
                                className='h-full text-center border border-border rounded-8 p-24 bg-card-default'>
                                {item.icon &&
                                    <div className='max-w-[184px] h-[184px] mx-auto mb-20'>
                                        <Icon arr={ICONS_ARRAY} name={item.icon} className='fill-title'/>
                                    </div>
                                }
                                {item.title &&
                                    <h3 className='break-words-custom text-title text-h4 leading-standard font-accent-font font-bold mb-12'>
                                        {parseTemplateValues(item.title, settings)}
                                    </h3>
                                }
                                {item.description &&
                                    <div className='quill-content break-words-custom text-content text-s text-standard'>
                                        <div dangerouslySetInnerHTML={{ __html: parseTemplateValues(item.description, settings)}} />
                                    </div>
                                }
                            </div>
                        </div>
                    )
                    : <p>Please add some tiles to section</p>
                }
                {items.length > 3 &&
                    <Button
                        onClick={() => setViewAll(prevValue => !prevValue)}
                        buttonStyle='transparent'
                        size='expanded'
                        className='mt-40 lg:hidden w-full'>
                        {viewAll ? 'View less' : 'View more'}
                        <Icon className='ml-4 fill-title inline-block' name={viewAll ? 'arrow_up' : 'arrow_down'} />
                    </Button>}
            </div>
        </div>
    );
};

Tiles.craft = {
    displayName: 'Tiles',
    props: {
        title: 'Idea for section name — Advantages',
        items: [
            {
                id: 1,
                icon: 'ball',
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear',
            },
            {
                id: 2,
                icon: 'ball_in_fire',
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear',
            },
            {
                id: 3,
                icon: 'net',
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear',
            },
            {
                id: 4,
                icon: 'prize',
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear',
            },
        ],

    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true,
    },
    related: {
        modal: TilesModalSettings,
    },
};

export default Tiles;
