import React, { useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Spinner from '../common/Spinner';
import TeamDesktopLayout from '../layouts/TeamDesktopLayout';
import TeamMobileLayout from '../layouts/TeamMobileLayout';
import { useFetch, useMobile } from "../../hooks";
import { TeamDataContext } from "../../context";
import { PATH_404 } from "../../config";
import { defer } from "../../utils";

export default function Team() {
    const isMobile = useMobile();
    const { team } = useParams();
    const { search } = useLocation();
    const [data, isLoading] = useFetch('teamData', { team });
    const spinnerContainer = useRef(null);
    const navigate = useNavigate();

    if (isLoading) {
        return <div className="wrapper py-16">
            <div ref={spinnerContainer}>
                <Spinner targetRef={spinnerContainer} className='rounded-12' />
            </div>
        </div>
    }

    if (data.hasOwnProperty('success') && !data.success) {
        defer(() => navigate(PATH_404 + search, { replace: true }));
        return null;
    }

    return <TeamDataContext.Provider value={data}>
        {isMobile ? <TeamMobileLayout /> :  <TeamDesktopLayout />}
    </TeamDataContext.Provider>;
}
