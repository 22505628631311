import React from "react";
import Icon from "../../../assets/images/icons";

const VenueEmptyFilteredLocations = () =>
    <div className='flex flex-col items-center justify-center h-full rounded-[16px] px-24 py-48 bg-card-default'>
        <Icon className='stroke-title' name='empty_filter_list' />
        <p className='text-h5 lg:text-h4 font-accent-font font-bold leading-h5 mt-24 mb-16'>No matches found</p>
        <p className='text-xs lg:text-m text-content leading-standard text-center max-w-[430px]'>
            Try to change the filter keyword
        </p>
    </div>

export default VenueEmptyFilteredLocations;
