import React from "react";

const borderBySide = {
    0: { borderSide: 'r', borderRadiusSide: 'l' },
    1: { borderSide: 'l', borderRadiusSide: 'r' },
}

const ToggleButtons = ({ activeIndex, buttons }) => {
    // the duplicate of rounded-l-[6px] rounded-r-[6px] needed because sometimes tailwind not creating this classnames
    // and buttons shows without border-radius
    return <div className="w-full flex rounded-l-[6px] rounded-r-[6px] !rounded-r-[8px] !rounded-l-[8px] border border-toggle-inactive-text">
        {buttons.map(({ text, onClick }, index) =>
            <div
                key={text}
                onClick={() => onClick instanceof Function ? onClick() : {} }
                className={`w-1/2 py-[8px] text-xs uppercase text-center font-bold border-${borderBySide[index].borderSide}
             border-toggle-inactive-text rounded-${borderBySide[index].borderRadiusSide}-[6px] ${
                    activeIndex === index ? 'bg-toggle-active-bg text-toggle-active-text' : 'bg-transparent text-toggle-inactive-text'
                }`}
                title={`Toggle${text ? ' to ' + text.toLowerCase() : ''} view`}
            >
                {text}
            </div>
        )}
    </div>
};

export default ToggleButtons;
