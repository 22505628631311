import { useRef } from 'react';

import { useFetch, useScrollTo } from '../hooks';

import Spinner from '../components/common/Spinner';

const StaticContent = ({ type }) => {
    const [data, isLoading] = useFetch(type);
    const containerRef = useRef(null);
    useScrollTo({
        targetRef: containerRef,
        isScrollTo: (!!data.content && !isLoading),
    });

    return (
        <div className='bg-page-bg text-title'>
            <div className='wrapper relative pb-default_section_py px-24 py-40 lg:py-48 lg:px-72 lg:pb-default_section_md_py'>
                <div className="max-w-[100vw]" ref={containerRef}>
                    {isLoading
                        ? <Spinner targetRef={containerRef} />
                        : <div className="
                            [&>ul>li]:list-disc [&>ul>li]:list-inside
                            [&_.ql-indent-1]:indent-24 [&_.ql-align-justify]:text-justify [&_.ql-align-center]:text-center
                            [&>h1]:text-h3 [&>h2]:text-h4 [&>h3]:text-h5 [&>h4]:text-h5 [&>h5]:text-h5 [&>h6]:text-h6
                            [&_a]:break-words [&_a]:!text-accent-color
                            [&_img]:w-full
                        "
                            dangerouslySetInnerHTML={{ __html: data.content || ''}}
                        ></div>
                    }
                </div>
            </div>
        </div>
    )
};

export default StaticContent;
