import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useEditor } from "@craftjs/core";

import Modal from "./index";
import SettingsModal from "./Settings";
import Confirm from "./Confirm";
import StaffMessage from "./StaffMessage";
import IconsModal from "./IconsModal";
import ManageVenue from "./ManageVenue";
import DeleteVenue from "./DeleteVenue";
import VenueMobileBackdrop from "./VenueMobileBackdrop";
import CraftModalContainer from "./CraftModalContainer";
import CropperModal from "./Cropper";
import { AppContext, SettingsContext, ModalContext } from "../../../context";
import { modalTypes, DEFAULT_MODAL_PROPS_BY_TYPE, modalSizes } from "../../../config";
import { defer, handleModalClose } from "../../../utils";

const sizes = {
    [modalSizes.sm]: 'lg:!max-w-[550px]',
    [modalSizes.lg]: '!max-w-[90vw]',
}

const ModalWrapper = () => {
    const { appState: { modal } } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [Component, setComponent] = useState(null);
    const [defaultProps, setDefaultProps] = useState({});
    const [cropperOpen, setCropperOpen] = useState(false);
    const { actions, query } = useEditor();
    const { configuration } = useContext(SettingsContext);
    const { pathname } = useLocation();
    const [cropperData, setCropperData] = useState(null);
    const currentCraftNodeIdRef = useRef(null);

    useEffect(() => {
        const type = modal?.modalType;
        if (type) {
            setOpen(true);
            setDefaultProps({
                ...(DEFAULT_MODAL_PROPS_BY_TYPE[modal?.modalType] || {}),
                modalData: modal,
            });
            setComponent(() => {
                switch (type) {
                    case modalTypes.settings: return SettingsModal;
                    case modalTypes.staffMessage: return StaffMessage;
                    case modalTypes.icons: return IconsModal;
                    case modalTypes.manageVenue: return ManageVenue;
                    case modalTypes.deleteVenue: return DeleteVenue;
                    case modalTypes.confirm: return Confirm;
                    case modalTypes.venueBackdrop: return VenueMobileBackdrop;

                    default: return null;
                }
            });
        } else {
            defer(() => {
                setOpen(false);
                setComponent(null);
                setDefaultProps({});
            }, 350);
        }
        setOpen(!!type);
    }, [modal]);

    useEffect(() => {
        if (modal) {
            handleModalClose();
        }
        if (currentCraftNodeIdRef.current && !!query.node(currentCraftNodeIdRef.current).get()) {
            // imitation closing of opened craft modal and select ROOT
            actions.setCustom(currentCraftNodeIdRef.current, custom => (custom.isSettingShow = false));
            currentCraftNodeIdRef.current = 'ROOT';
        }
        setCropperData(null);
    }, [pathname]);

    return <ModalContext.Provider value={{ setCropperData, setCropperOpen }}>
        <CraftModalContainer currentCraftNodeIdRef={currentCraftNodeIdRef} move={open || cropperOpen} />
        <Modal
            open={open}
            move={cropperOpen}
            wrapperClassName={defaultProps.alignBottom ? '!items-end' : ''}
            containerClassName={`${sizes[defaultProps.size] ?? ''} ${defaultProps.alignBottom ? 'rounded-t-[24px] rounded-b-none' : ''}`}
            setLightTheme={defaultProps.isThemed ? !configuration.darkTheme : true}
            onClick={defaultProps.closeClickOutside ? defaultProps.modalData.onClose : null}
        >
            {Component
                ? <Component {...defaultProps.modalData} />
                : null}
        </Modal>
        <Modal open={cropperOpen}>
            {cropperData
                ? <CropperModal {...cropperData} />
                : null}
        </Modal>
    </ModalContext.Provider>
};

export default ModalWrapper;
