
const getGradient = gender => {
    switch (gender) {
        case 3:
        case '3':
            return 'from-[#eb309f] to-[#3475EB]';
        case 2:
        case '2':
            return 'from-[#f857b8] to-[#eb309f]';
        default:
            return 'from-[#388DF8] to-[#3475EB]';
    }
}

const GradientLogo = ({ text, gender, className, title }) => {
    return <div title={title || ''} className={`w-full h-full flex items-center justify-center text-white text-xs leading-[130%] font-medium
     bg-gradient-to-br from-[50%] to-[50%] ${getGradient(gender)} ${className || ''}`}>
        {text ?? ''}
    </div>
}

export default GradientLogo;
