import { useState, useContext } from 'react';
import { useNode } from '@craftjs/core';
import { toast } from 'react-toastify';

import ModalFooter from '../../common/Modal/ModalFooter';
import ModalHeader from '../../common/Modal/ModalHeader';
import ModalContent from '../../common/Modal/ModalContent';
import CustomInput from '../../common/Controls/CustomInput';
import CustomQuillElement from '../../common/Controls/CustomQuillElement';

import { handleCloseModal, handleModalOpen, parseTemplateValues } from '../../../utils';
import { SettingsContext } from '../../../context';
import { ERR_DEFAULT } from '../../../const';
import { modalTypes } from '../../../config';

/* set socialLinks from general settings => additionalButton */
const SocialLinksModalSettings = ({ onSave }) => {
    const {
        props: { title, description },
        actions: { setProp, setCustom },
    } = useNode(node => ({ props: node.data.props }));
    const settings = useContext(SettingsContext);
    const [newDescription, setNewDescription] = useState(parseTemplateValues(description, settings));
    const [newTitle, setNewTitle] = useState(parseTemplateValues(title, settings));

    const handleClose = () => handleCloseModal(setCustom);

    const handleSave = () => {
        setProp(props => {
            props.description = newDescription;
            props.title = newTitle;
        });
        handleClose();
        onSave();
    };

    return (
        <>
            <ModalHeader title='Edit Socials section' onClose={handleClose} />
            <ModalContent>
                <div className='py-20 px-24'>
                    <CustomInput
                        label='Add a section title'
                        onChange={setNewTitle}
                        value={newTitle}
                        className='mb-20'
                    />
                    <CustomQuillElement
                        title='Add a text'
                        value={newDescription}
                        onChange={setNewDescription}
                        className='quill-text_block'
                    />
                </div>
            </ModalContent>
            <ModalFooter
                onClose={handleClose}
                onSubmit={handleSave}
                additionalButton={{
                    text: 'Edit Socials links',
                    onClick: () => {
                        const editSettings = settings.permissions?.editSettings || {};
                        if (editSettings.allow) {
                            handleModalOpen({ modalType: modalTypes.settings, data: { scrollToSocials: true } });
                        } else {
                            toast.error(editSettings.message || ERR_DEFAULT)
                        }
                    }
                }}
            />
        </>
    )
};

export default SocialLinksModalSettings;
