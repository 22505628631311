import React, { useContext } from 'react';

import Icon from '../../../assets/images/icons';
import LinkWithQuery from '../../common/LinkWithQuery';
import Button from '../../common/Buttons/Button';
import { VenuesDataContext } from '../../../context';
import { handleModalOpen } from "../../../utils";
import { modalTypes } from "../../../config";

const VenueEmptyMap = () => {
    const { enabled, setVenues } = useContext(VenuesDataContext);

    return <div className={`flex flex-wrap w-full h-full py-72 px-24
        ${enabled ? 'bg-block-bg' : 'bg-card-active'}
        border border-border rounded-16 lg:rounded-6 justify-center content-center`}
    >
        {enabled
            ? <div className='flex flex-col items-center justify-center h-full w-full'>
                <Icon className='stroke-title' name='empty_folder' />
                <p className='text-h5 lg:text-h4 font-accent-font font-bold leading-h4 mt-24 mb-16'>No venues yet</p>
                <Button
                    onClick={() => handleModalOpen({ modalType: modalTypes.manageVenue, data: { setVenues } })}
                    buttonStyle='transparent'
                    size='small'
                    className='mr-12'
                >
                    add venue
                </Button>
            </div>
            : <div className='flex flex-col items-center'>
                <Icon className='stroke-title' name='empty_folder' />
                <p className='text-h5 lg:text-h4 font-accent-font font-bold leading-h4 mt-24 mb-16'>No venues yet</p>
                <p className='text-xs lg:text-m text-content leading-standard text-center max-w-[430px]'>
                    No venues have been added. Please
                    <LinkWithQuery className='text-accent-color' to='/contact-us'> contact us </LinkWithQuery>
                    if you have any questions.
                </p>
            </div>
        }
    </div>
}

export default VenueEmptyMap;
