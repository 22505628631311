import React, { useState, useEffect, useRef } from 'react';

import CustomInput from '../../common/Controls/CustomInput';
import CustomSelect from '../../common/Controls/CustomSelect';
import CustomTextarea from '../../common/Controls/CustomTextarea';
import CustomUploadImage from '../../common/Controls/CustomUploadImage';
import CustomRadioGroup from '../../common/Controls/CustomRadioGroup';
import Divider from '../../common/Divider';
import Spinner from '../../common/Spinner';
import {
    defer,
    filterStatesByCountry,
    loadGoogleMapsLibrary,
    loadGoogleMarkersLibrary
} from '../../../utils';
import ModalHeader from '../../common/Modal/ModalHeader';
import ModalContent from '../../common/Modal/ModalContent';
import ModalFooter from '../../common/Modal/ModalFooter';

const KEY_LOGO = 'logo';
const MAP_ID = 'VENUE_MODAL_MAP_ID';
const RADIO_BUTTONS_OPTIONS = [
    {value: 'practice', label: 'Practice'},
    {value: 'game', label: 'Game'},
    {value: 'game/practice', label: 'Game/Practice'},
];

const DEFAULT_EMPTY_DATA = {
    name: '',
    description: '',
    link: '',
    type: '',
    logoPath: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    latitude: '',
    longitude: '',
};

const VenueModalContent = ({countries, states, isLoading, data, title, onClose}) => {
    const [place, setPlace] = useState(data.id);
    const [state, setState] = useState(data || DEFAULT_EMPTY_DATA);
    const [filteredStates, setFilteredStates] = useState(filterStatesByCountry({
        states,
        countries,
        countryId: data.country
    }));
    const [map, setMap] = useState(null);
    const [librariesLoaded, setLibrariesLoaded] = useState(false);
    const countrySelectRef = useRef(null);
    const stateSelectRef = useRef(null);
    const containerRef = useRef(null);
    const mapRef = useRef(null);
    const infoWindowRef = useRef(null);
    const cardRef = useRef(null);
    const inputRef = useRef(null);

    const handleChangeValue = key => {
        return value => setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const GoogleMaps = window.google.maps || {};
    useEffect(() => {
        async function initializeMap() {
            const MapEl = await loadGoogleMapsLibrary();
            const MarkerEl = await loadGoogleMarkersLibrary();

            const initializedMap = new MapEl(
                mapRef.current,
                {
                    center: {lat: 40.749933, lng: -73.98633},
                    zoom: 13,
                    mapTypeControl: false,
                    streetViewControl: false,
                    disableDefaultUI: true,
                    mapId: MAP_ID,
                }
            );

            setMap(initializedMap);
            setLibrariesLoaded(true);
        }

        if (!map) {
            initializeMap();
        }
    }, [map]);

    useEffect(() => {
        if (map && librariesLoaded) {
            loadGoogleMarkersLibrary().then(MarkerEl => {
                const options = {
                    fields: ['address_components', 'geometry', 'name', 'website'],
                    componentRestrictions: {country: ['us', 'ca']},
                    strictBounds: false,
                    language: 'en',
                    types: ['establishment'],
                };
                map.controls[GoogleMaps.ControlPosition.TOP_LEFT].push(cardRef.current);
                const autocomplete = new GoogleMaps.places.Autocomplete(inputRef.current, options);
                autocomplete.bindTo('bounds', map);

                const infoWindow = new GoogleMaps.InfoWindow();
                const infoWindowContent = infoWindowRef.current;

                infoWindow.setContent(infoWindowContent);

                const marker = new MarkerEl({ map });
                //Old value - anchorPoint: new GoogleMaps.Point(0, -29)

                // Here is no event to load autocomplete input. That's why focusing input field in 500ms after google map is full loaded.
                GoogleMaps.event.addListener(map, 'tilesloaded', function () {
                    setTimeout(() => {
                        place || inputRef.current?.focus();
                    }, 500);
                    GoogleMaps.event.clearListeners(map, 'tilesloaded');
                });

                autocomplete.addListener('place_changed', () => {
                    infoWindow.close();
                    marker.map = null;

                    const place = autocomplete.getPlace();

                    if (!place.geometry || !place.geometry.location) {
                        // User entered the name of a Place that was not suggested and
                        // pressed the Enter key, or the Place Details request failed.
                        return;
                    }

                    // If the place has a geometry, then present it on a map.
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(17);
                    }

                    marker.position = place.geometry.location;
                    marker.map = map;
                    infoWindowContent.children['place-name'].textContent = place.name;
                    infoWindowContent.children['place-address'].textContent = place.formatted_address;
                    infoWindow.open(map, marker);
                    setPlace(place);

                    setTimeout(() => {
                        const newState = {};
                        let newFilteredStates;
                        if (place.name) {
                            newState.name = place.name;
                        }
                        if (place.website) {
                            newState.link = place.website;
                        }
                        if (place.address_components && Array.isArray(place.address_components)) {
                            const addresses = place.address_components;
                            const getDataByType = (data, type) => data.find(item => item.types.includes(type));
                            const [country, streetNumber, streetName, city, state, postalCode] = [
                                getDataByType(addresses, 'country'),
                                getDataByType(addresses, 'street_number'),
                                getDataByType(addresses, 'route'),
                                getDataByType(addresses, 'locality'),
                                getDataByType(addresses, 'administrative_area_level_1'),
                                getDataByType(addresses, 'postal_code'),
                            ];
                            newState.streetAddress = `${streetNumber?.long_name || ''} ${streetName?.long_name || ''}`
                            newState.city = city?.long_name || '';
                            newState.postalCode = postalCode?.long_name || '';
                            const countryName = country?.long_name;
                            const countryId = countries[countryName];
                            newFilteredStates = countrySelectRef.current.value === countryId
                                ? filteredStates
                                : filterStatesByCountry({states, countries, countryName});

                            defer(() => {
                                countrySelectRef.current.value = countryId || '';
                                stateSelectRef.current.value = newFilteredStates[state?.long_name] || '';
                            }, 10);
                        }
                        if (place.geometry.location) {
                            newState.latitude = place.geometry.location.lat() || '';
                            newState.longitude = place.geometry.location.lng() || '';
                        }
                        setState(prevState => ({...prevState, ...newState}));
                        setFilteredStates(newFilteredStates);
                    }, 50);
                });
            })
        }
        return () => {
            const pacElements = document.querySelectorAll('.pac-container.pac-logo');
            pacElements.forEach(element => {
                element.remove();
            });
        }
    }, [map, librariesLoaded]);

    return <>
        <ModalHeader title={title || 'Add venue'} onClose={onClose} />
        <ModalContent>
            {isLoading ? <Spinner /> : null}
            <div className='hidden'>
                <div
                    className='bg-white border-0 rounded-2 m-10 p-0 w-[calc(100%-20px)] overflow-hidden shadow-[0_1px_4px_-1px_rgba(0,0,0,0.3)]'
                    ref={cardRef}
                >
                    <input
                        ref={inputRef}
                        type='text'
                        placeholder='Start by entering a venue name or address'
                        className='px-[11px] h-40 text-[15px] w-full overflow-ellipsis focus:border-border'
                    />
                </div>
                <div ref={infoWindowRef}>
                    <span id='place-name' className='title'></span><br/>
                    <span id='place-address'></span>
                </div>
            </div>
            <div ref={containerRef} className='text-black w-full min-h-[calc(300px+24px+24px)] h-[calc(100vh-255px)] px-24 py-20 flex flex-row gap-24'>
                <div
                    ref={mapRef}
                    className={`transition-all ease-out duration-[400ms] rounded-6 w-full min-h-[inherit]
                    h-[inherit] max-h-full flex ${place ? 'basis-1/2' : 'basis-full'}`}
                />
                {place
                    ? <div className='py-20 h-fit transition-all ease-out duration-[400ms] w-full basis-[50%] flex flex-col'>
                        <CustomInput
                            label='Venue name *'
                            name='name'
                            value={state.name}
                            onChange={handleChangeValue('name')}
                            required
                            maxLength={120}
                            className='mb-16'
                        />
                        <CustomTextarea
                            title='Description'
                            name='description'
                            value={state.description}
                            onChange={handleChangeValue('description')}
                            maxLength={320}
                            className='mb-16'
                        />
                        {/*<CustomInput*/}
                        {/*    name={`link${KEY_LOGO}`}*/}
                        {/*    value={state.logoPath}*/}
                        {/*    className='invisible h-[1px] w[1px] absolute mb-16'*/}
                        {/*/>*/}
                        <CustomUploadImage
                            name={KEY_LOGO}
                            title='Image'
                            description='The image will be used as additional visual information about this venue'
                            titleClassName='font-semibold'
                            imgSrc={state.logoPath}
                            setImg={value => {
                                handleChangeValue(KEY_LOGO)(value);
                                data.logoPath = value;
                            }}
                            className='mb-16'
                            cropperProps={{
                                aspectRatio: 19 / 9,
                                preferredSize: { width: 586, height: 330 }
                            }}
                        />
                        <CustomInput
                            label='Link'
                            name='link'
                            value={state.link}
                            onChange={handleChangeValue('link')}
                            className='mb-16'
                        />
                        <Divider className='my-16' />
                        <CustomRadioGroup
                            label='Location Type'
                            options={RADIO_BUTTONS_OPTIONS}
                            value={state.type}
                            className='mb-16'
                            name='type'
                        />
                        <CustomInput
                            label='Street address *'
                            name='streetAddress'
                            value={state.streetAddress}
                            onChange={handleChangeValue('streetAddress')}
                            required
                            className='mb-16'
                        />
                        <div className='flex gap-16 mb-16'>
                            <CustomSelect
                                label='Country *'
                                outerRef={countrySelectRef}
                                name='country'
                                options={countries}
                                value={state.country}
                                onChange={countryId => {
                                    handleChangeValue('country')(countryId);
                                    setFilteredStates(filterStatesByCountry({ states, countries, countryId }));
                                }}
                                required
                                className='flex-1'
                            />
                            <CustomSelect
                                label='State / Province *'
                                outerRef={stateSelectRef}
                                name='state'
                                options={filteredStates}
                                value={state.state}
                                onChange={handleChangeValue('state')}
                                required
                                className='flex-1'
                            />
                        </div>
                        <div className='flex gap-16 mb-16'>
                            <CustomInput
                                label='City *'
                                name='city'
                                value={state.city}
                                onChange={handleChangeValue('city')}
                                required
                                className='flex-1'
                            />
                            <CustomInput
                                label='Postal code *'
                                name='postalCode'
                                value={state.postalCode}
                                onChange={handleChangeValue('postalCode')}
                                required
                                className='flex-1'
                            />
                        </div>
                        <div className='flex gap-16'>
                            <CustomInput
                                label='Latitude *'
                                name='latitude'
                                value={state.latitude}
                                onChange={handleChangeValue('latitude')}
                                required
                                className='flex-1'
                            />
                            <CustomInput
                                label='Longitude *'
                                name='longitude'
                                value={state.longitude}
                                onChange={handleChangeValue('longitude')}
                                required
                                className='flex-1'
                            />
                        </div>
                    </div>
                    : null}
            </div>
        </ModalContent>
        <ModalFooter disableSaveButton={!place} onClose={onClose} />
    </>;
}

export default VenueModalContent;
