import { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Element, useEditor } from '@craftjs/core';
import { useLocation } from 'react-router-dom';

import Icon from '../../assets/images/icons';
import { SIDEBAR_SECTIONS, CRAFTJS_PAGES } from '../../config';
import { handleSaveDraft } from "../../utils";
import { AuthContext } from "../../context";

const ICONS_ARR = 'SECTIONS';

/* TODO: add form for new sections (d-none now) */
const AddSectionToolbox = () => {
    const { query, enabled, connectors: { create } } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    const { setAuthState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const [expanded, setExpanded] = useState(false);

    const AddSectionButton = ({ name, icon, element }) => {
        return (
            <div className='cursor-move flex gap-4 items-center py-[6px] border-b border-aside-border'
                 title={`Add ${name.toLowerCase()} section`}
                 ref={(ref) => {
                     create(ref, <Element is={element} />, {
                         onCreate: () =>
                             handleSaveDraft(query, content => setAuthState(prevState => ({
                                 ...prevState,
                                 draftContent: content,
                             })))
                     });
                 }}
            >
                <Icon className="shrink-0 w-24 h-24" arr={ICONS_ARR} name={icon} />
                {expanded && <>
                    <span className="flex-1">{name}</span>
                    <Icon name='drag' className='shrink-0 w-24 h-24 fill-white' />
                </>}
            </div>
        )
    };

    const availableSections = SIDEBAR_SECTIONS.filter(section => {
        return section.pages.some(key => CRAFTJS_PAGES[key].pathname === pathname)
    })

    const mainWrapper = document.getElementById('main-wrapper');

    return availableSections.length ?
        ReactDOM.createPortal(
            <div className={`fixed bg-header-bg text-aside-text font-main-font text-12 max-w-fit top-[0px] h-full pt-[120px] z-50 ${enabled ? 'block' : 'hidden'}`}>
                <div className={`sticky px-[6px] overflow-y-auto max-h-[calc(297px+145px)] h-[calc(100vh-120px)] flex flex-col justify-between transition-all duration-200 ${expanded ? 'w-[156px] !max-w-[156px]' : 'w-[38px] max-w-[38px]'}`}>
                    <div className='flex flex-col justify-end whitespace-nowrap'>
                        <button
                            type='button'
                            onClick={() => setExpanded(prev => !prev)}
                            className='bg-aside-header border border-aside-border -mx-[6px] pl-[8px] pr-[4px] py-[6px] overflow-hidden'
                        >
                            {expanded ?
                                <span className='flex items-center justify-between text-14'>
                                    Sections pane
                                    <Icon name='arrow_left' className='ml-[4px] fill-white'/>
                                </span>
                                :
                                <Icon name='arrow_right' className='fill-white'/>}
                        </button>
                        {availableSections.map(section => <AddSectionButton key={section.name} {...section} />)}
                    </div>
                    {expanded && <>
                        <div className='w-[132px] max-w-[132px] pb-12'>
                            <p className='py-[12px] text-label border-b border-aside-border text-white'>
                                Choose the needed section, drag and drop it into the body of your site.
                            </p>
                            <p className='py-[12px] hidden'>
                                Need a section, but it is not in the set?
                                <a href='/#' target='_blank' rel='noreferrer' className='text-edit-color-light font-medium'>
                                    Fill out this form
                                </a>
                                so that we’ll try to make the template more suitable for you.
                            </p>
                        </div>
                    </>}
                </div>
            </div>,
            mainWrapper,
        )
        : null
};

export default AddSectionToolbox;
