const STATISTICS_DATA = [
    {name: 'Record', value: statistics => `${statistics.win || 0} - ${statistics.lose || 0}`},
    {name: 'Streak', value: 'streak'},
    {name: 'Last 10', value: statistics => `${statistics.l10?.win || 0} - ${statistics.l10?.lose || 0}`},
    {name: 'PPG', value: 'ppg'},
    {name: 'OPP PPG', value: 'opp_ppg'},
    {name: 'DIFF', value: 'diff'},
];
export default function TeamShortStatistics({ data }) {
    return <div className='grid grid-rows-[1fr_1fr] lg:grid-rows-[auto] grid-cols-[1fr_1fr_1fr] lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] font-main-font mb-40 gap-16'>
        {STATISTICS_DATA.map(({name, value}) =>
            <div key={name}>
                <div
                    className='h-full py-16 px-14 rounded-16 border border-border bg-statistic-item-bg text-center'>
                    <p className='mb-4 text-label leading-label text-content'>{name}</p>
                    <p className='text-m leading-standard text-title font-semibold'>
                        {value instanceof Function ? value(data) : data[value]}
                    </p>
                </div>
            </div>)}
    </div>
};
