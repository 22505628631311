import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useEditor } from "@craftjs/core";

import { useFetch, useMobile } from "../../hooks";
import { ScheduleDataContext } from "../../context";
import Datepicker from "../common/Datepicker";
import ScheduleMobileLayout from "../layouts/ScheduleMobileLayout";
import ScheduleDesktopLayout from "../layouts/ScheduleDesktopLayout";

export default function Schedule() {
    const isMobile = useMobile();
    const [data, isLoading] = useFetch('scheduleData');
    const [additionalLoading, setAdditionalLoading] = useState(false);
    const [filteredData, setFilteredData] = useState({});
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
    const [currentFilterContainer, setCurrentFilterContainer] = useState(null);

    return <ScheduleDataContext.Provider value={{
        data,
        filteredData,
        isLoading: additionalLoading || isLoading,
        enabled,
    }}>
        <div className='bg-page-bg pb-default_section_py lg:pb-default_section_md_py text-content'>
            {currentFilterContainer && ReactDOM.createPortal(
                <Datepicker
                    minDate={data.minDate}
                    enabled={enabled}
                    setFilteredData={setFilteredData}
                    filters={data.filters}
                    setLoading={setAdditionalLoading}
                />,
                currentFilterContainer,
            )}
            <div className='wrapper relative'>
                {isMobile
                    ? <ScheduleMobileLayout setCurrentFilterContainer={setCurrentFilterContainer} />
                    : <ScheduleDesktopLayout setCurrentFilterContainer={setCurrentFilterContainer} />}
            </div>
        </div>
    </ScheduleDataContext.Provider>
};
