import React from "react";
import { CustomPicker } from "react-color";
import { Hue, Saturation } from "react-color/lib/components/common";
import ChromeFields from "react-color/lib/components/chrome/ChromeFields";

const CustomColorPicker = props => {
    return <div className="flex flex-col">
        <div className="flex relative w-full h-[200px]">
            <Saturation {...props}
                // pointer={() => <div
                //     draggable={false}
                //     className="-translate-y-1/2 -translate-x-1/2 w-[8px] h-[8px] border border-title pointer rounded-[15px]"
                // />}
            />
        </div>
        <div className="flex gap-16 items-center">
            <div className="basis-[24px] shrink-0 h-24 rounded-full" style={{ backgroundColor: props.hex }}></div>
            <div className="flex relative w-full h-[8px] my-24">
                <Hue {...props}  />
            </div>
        </div>
        <div className="flex relative w-full [&>div]:w-full [&_svg]:!fill-content [&_svg:hover]:!bg-[inherit]">
            <ChromeFields {...props} disableAlpha />
        </div>
    </div>
};

export default CustomPicker(CustomColorPicker);
