import { Element } from '@craftjs/core';

import Form from '../page-home/Form';
import Text from '../page-home/Text';
import Contacts from './Contacts';
import SocialLinks from './SocialLinks';
import Empty from "../craftJS/empty";

const DEMONSTRATION = {
    GET_IN_TOUCH: {
        title: 'Get in Touch with Us!',
        description: 'Reach out to us and become part of our energetic community! If you\'re interested in joining our sports school or if you have any questions, we\'re here to help. Don\'t hesitate to connect with us.',
    },
    CONTACTS: {
        title: 'Phone Number',
        description: 'Feel free to call us during our operating hours (9 AM to 5 PM CST, Monday to Friday):',
        items: [
            {
                id: 1,
                name: 'For general questions:',
                main_phone: '+1-123-789-4560',
            },
            {
                id: 2,
                name: 'For financial questions:',
                main_phone: '+1-123-789-4560',

            },
            {
                id: 3,
                name: 'Boys team questions:',
                main_phone: '+1-123-789-4560',

            },
            {
                id: 4,
                name: 'Girls team questions:',
                main_phone: '+1-123-789-4560',

            },
        ],

    },

    FORM: {
        title: 'Send Us a Message',
        description: 'Can\'t reach us by phone? Fill out the form below with your contact information and your question or comment. We\'ll get back to you as soon as we can!',
    }
};

const ContactUs = () => {
    return <Element id="contact-us-canvas" canvas is={Empty}>
        <Element id="contact-us-text" is={Text} {...DEMONSTRATION.GET_IN_TOUCH} />
        <Element id="contacts" is={Contacts} {...DEMONSTRATION.CONTACTS} />
        <Element id="form" is={Form} {...DEMONSTRATION.FORM} />
        <Element id="social-links" is={SocialLinks} />
    </Element>;
}

export default ContactUs;
