import React, { useState } from "react";

import Spinner from "../Spinner";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import Icon from "../../../assets/images/icons";
import { iconColorType } from "../../../config";

const ConfirmModal = ({
    onClose,
    title,
    contentText,
    submitButtonText,
    onConfirm,
    titleIconType = iconColorType.warning,
}) => {
    const [isModalLoading, setModalLoading] = useState(false);

    const handleSubmit = async () => {
        setModalLoading(true);
        onConfirm();
    };

    // this need because tailwind can't create styles from parsed variables such as 'fill-' + titleIconType
    let fillColor;
    switch (titleIconType) {
        case "warning" : fillColor = 'fill-warning'; break;
        case "info" : fillColor = 'fill-info'; break;
        case "success" : fillColor = 'fill-success'; break;
        case "error" : fillColor = 'fill-error'; break;
        default: fillColor = 'fill-warning'; break;
    }

    return <>
        <ModalHeader
            title={<div className='flex items-center gap-8'>
                <Icon name={titleIconType} className={fillColor} />
                {title}
            </div>}
            onClose={onClose}
        />
        <ModalContent>
            {isModalLoading ? <Spinner /> : null}
            <div className="px-24 pt-16 pb-32 flex flex-col text-title">
                <p className="mb-16">{contentText}</p>
            </div>
        </ModalContent>
        <ModalFooter submitButtonText={submitButtonText || 'confirm'} onClose={onClose} onSubmit={handleSubmit} />
    </>
};

export default ConfirmModal;
