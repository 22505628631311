import Icon from '../../assets/images/icons';

const ICONS_DATA = {
    array: 'EMPTY',
}
export const EmptyContent = ({ icon, text, inner }) => {
    return (
        <div className={`${inner ? '' : 'p-16 border border-border rounded-[16px] bg-card-default'} w-full flex items-center text-h5 leading-h5 font-semibold`}>
            <div className='min-w-40 w-40 h-40 rounded-40 p-8 mr-16 bg-card-hover'>
                <Icon name={icon} arr={ICONS_DATA.array} className='fill-title'/>
            </div>
            {text}
        </div>
    );
}
