import CustomInput from '../../common/Controls/CustomInput';
import Icon from '../../../assets/images/icons';
import { getPhoneInputProps } from '../../../utils';

const Contact = ({
    id,
    name,
    main_phone,
    email,
    handleSetValue,
    handleDelete,
    template,
    handleChangeOrder,
    isLast,
    index,
}) => {
    const additionalProps = getPhoneInputProps(template);
    return (
        <div className='flex w-full py-20 px-24 border-b last:border-none border-color-border'>
            <div className='flex flex-wrap w-full basis-[95%] pr-24'>
                <CustomInput
                    label='Contact name'
                    className='basis-full mb-24'
                    value={name}
                    onChange={value => handleSetValue(value, id, 'name')}
                />
                <CustomInput
                    className='basis-1/2 pr-8'
                    label='Phone number'
                    value={main_phone}
                    onChange={value => handleSetValue(value, id, 'main_phone')}
                    {...additionalProps}
                />
                <CustomInput
                    className='basis-1/2 pl-8'
                    label='Email'
                    type={template ? null : 'email'}
                    value={email}
                    onChange={value => handleSetValue(value, id, 'email')}
                />
            </div>
            <div className='border-l border-border pl-16 flex flex-col justify-between items-center'>
                <button
                    title="Remove contact"
                    type='button'
                    onClick={() => handleDelete(id)}
                >
                    <Icon name='close' />
                </button>
                <button
                    title="Move this contact up"
                    onClick={() => handleChangeOrder(index, index-1)}
                    className='mt-auto mb-12'
                    disabled={!index}
                    type='button'
                >
                    <Icon className={!index && 'fill-icon-disabled'} name='arrow_up' />
                </button>
                <button
                    title="Move this contact down"
                    onClick={() => handleChangeOrder(index, index+1)}
                    disabled={isLast}
                    type='button'
                >
                    <Icon className={isLast && 'fill-icon-disabled'}  name='arrow_down' />
                </button>
            </div>
        </div>)
};

export default Contact;
