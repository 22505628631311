import { useEffect, useRef } from 'react';

const textStyles = 'text-s leading-standard text-title placeholder-input-placeholder font-normal font-main-font';
const borderStyles = 'border border-input-default-border rounded-input focus:border-title hover:border-title focus-visible:border-title focus-visible:outline-0';
const bgStyles = 'bg-input-default-bg focus:bg-input-active-bg disabled:bg-input-disabled-bg';

const CustomSelect = ({ outerRef, label, onChange, className, type, options, value, showEmptyValue, ...other }) => {
    const selectRef = useRef(null);
    const currentRef = outerRef || selectRef;

    useEffect(() => {
        const textColor = getComputedStyle(currentRef.current).getPropertyValue('--text-content');
        const svg = `<svg fill="${textColor}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z" fill="inherit" /><path d="M0 0h24v24H0z" fill="none"/></svg>`;

        currentRef.current.style.backgroundImage = `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`;
    });

    const sortedOptions = Object.entries(options).sort((a, b) => a.at(1) - b.at(1));

    return (
        <label className={`flex flex-col ${className ?? ''}`}>
            {label && <p className='font-semibold text-label leading-label mb-8'>{label}</p>}
            <select
                ref={currentRef}
                defaultValue={value}
                onChange={event => onChange ? onChange(event.target.value) : null}
                className={`${textStyles} ${borderStyles} ${bgStyles} px-input-x-p py-input-y-p`}
                {...other}
            >
                {sortedOptions.map(([name, id]) =>
                    <option key={+id} value={+id}>{name}</option>
                )}
            </select>
        </label>
    )
}

export default CustomSelect;
