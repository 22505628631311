import React from "react";

import Empty from "../components/craftJS/empty";
import Staff from "../components/page-staff";
import { Element } from "@craftjs/core";

const StaffContainer = () => {
    return <Element id="staff-canvas" canvas is={Empty}>
        <Staff />
    </Element>
};

export default StaffContainer;
