import React, { useContext } from "react";

import Accordion from "../../common/Accordion";
import Button from "../../common/Buttons/Button";
import NavigateButton from "../../common/Buttons/NavigateButton";
import VenueEmptyFilteredLocations from "./VenueEmptyFilteredLocations";
import Icon from "../../../assets/images/icons";

import { VenuesDataContext } from "../../../context";
import { areNumbersEqual, capitalizeString, handleModalOpen } from "../../../utils";
import { modalTypes } from "../../../config";

const EMPTY_EDIT_LOCATION = {};

const VenueLocations = ({ setActiveMarker, expandedId, isScrollTo, locationsScrollRef }) => {
    const { venues = [], filteredVenues = [], enabled, setVenues } = useContext(VenuesDataContext);
    const handleActiveMarker = (value, isHover) =>
        setActiveMarker(prevState => ({ ...prevState, scrollTo: false, [isHover ? 'hovered' : 'expanded']: value }));

    const handleModalData = (modalType, data) => {
        handleModalOpen({ modalType, data: { ...data, setVenues } });
    };

    if (venues.length && !filteredVenues.length) {
        return <VenueEmptyFilteredLocations />
    }

    return [enabled ? EMPTY_EDIT_LOCATION : null, ...filteredVenues].map(item => {
        if (!item) {
            return null;
        }
        if (!item.id) {
            return <Button
                key="addvenuebutton"
                onClick={() => handleModalData(modalTypes.manageVenue)}
                buttonStyle="transparent"
                className="flex w-full justify-center !h-[36px] gap-8 py-[6px] px-16 mb-16 items-center leading-4"
            >
                <Icon name='plus' className='fill-title' />
                Add venue
            </Button>
        }
        const isActive = areNumbersEqual(expandedId, item.id);
        return <Accordion
            key={item.id}
            title={item.name}
            isScrollTo={isActive && isScrollTo}
            scrollRef={locationsScrollRef}
            isActive={isActive}
            subtitleClass="!text-label !leading-label mt-4"
            sectionStyle={expanded =>
                `border rounded-16 mb-16 last:mb-0 hover:cursor-pointer hover:bg-card-hover ${
                    expanded ? '!bg-card-active' : 'bg-card-default'
                }`
            }
            titleClassName="font-accent-font text-h5 font-semibold leading-standard"
            subtitle={<span>
                <span className="font-semibold">
                    {`Type: ${capitalizeString(item.type)}`}
                </span>
                <p className='mt-4'>{item.fullAddress}</p>
            </span>}
            additionalControl={<NavigateButton
                latitude={item.latitude}
                longitude={item.longitude}
                onClick={e => e.stopPropagation()}
            />}
            onMouseEnter={() => handleActiveMarker(item.id, true)}
            onMouseLeave={() => handleActiveMarker(null, true)}
            // if we close expanded accordion it means we still hovering it. So we should to highlight the marker on map
            onExpand={isExpanded => isExpanded
                ? handleActiveMarker(item.id, false)
                : setActiveMarker({ expanded: null, hovered: item.id, scrollTo: false })
            }
            sideContent={enabled
                ? <>
                    <button
                        disabled={!item.created}
                        onClick={() => handleModalData(modalTypes.manageVenue, { ...item, title: 'Edit venue' })}>
                        <Icon
                            className={`my-[5.5px] ${item.created ? 'fill-title' : 'fill-icon-disabled'}`}
                            name="edit"
                        />
                    </button>
                    <button
                        disabled={!item.created}
                        onClick={() => handleModalData(
                            modalTypes.deleteVenue,
                            { id: item.id, name: item.name, address: item.fullAddress },
                        )}
                    >
                        <Icon
                            className={`my-[5.5px] ${item.created ? 'fill-title' : 'fill-icon-disabled'}`}
                            name="delete"
                        />
                    </button>
                </>
                : null}
        >
            {item.logoPath || item.description
                ? <div className="flex flex-col lg:flex-row flex-wrap px-24 gap-24">
                    {item.logoPath
                        ? <div className="flex flex-1">
                            <div className='rounded-16 overflow-hidden w-full'>
                                <img src={item.logoPath}
                                     alt={item.image_alt || 'Location Preview'}
                                     className='w-full object-cover aspect-16/9'
                                />
                            </div>
                        </div>
                        : null}
                    {item.description
                        ? <div className="flex-1 flex">
                            <p className="text-s leading-standard text-content">{item.description}</p>
                        </div>
                        : null}
                </div>
                : null}
        </Accordion>
    });
}

export default VenueLocations;
