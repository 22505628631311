import React, { useState, useRef, useEffect } from 'react';

import Icon from "../../../assets/images/icons";
import CustomInput from "../Controls/CustomInput";

const Filter = ({ data, enabled, setFilteredValue, filterParams = [], ...other }) => {
    const [isActive, setActive] = useState(false);
    const [filterString, setFilterString] = useState('');
    const filteredCount = useRef(-1);
    const inputRef = useRef(null);

    const handleFilter = filterString => {
        const filteredData = filterString
            ? data.filter(item => {
                let addToData = false;
                for (let i = 0; i < filterParams.length; i++) {
                    const value = item[filterParams[i]];
                    if (value && value.toLowerCase().includes(filterString.toLowerCase())) {
                        addToData = true;
                        break;
                    }
                }
                return addToData;
            })
            : data;
        filteredCount.current = filterString.length ? filteredData.length : -1;
        setFilteredValue(filteredData);
    }

    const handleChangeValue = value => {
        setFilterString(value);
        handleFilter(value);
    }

    useEffect(() => {
        handleFilter(filterString)
    }, [data]);

    if (!data || !data.length) return;

    if (enabled) {
        return <div className="bg-card-default rounded-8 w-[300px] h-[40px]"></div>
    }

    return <div className="flex flex-col flex-1 h-[40px]">
        <div className={`ml-auto w-full flex-1 transition-all !duration-200 lg:max-w-[300px]${isActive ? ' !max-w-full' : ''}`}>
            <CustomInput
                autoComplete="custom-autocomplete"
                outerRef={inputRef}
                value={filterString}
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                className="flex-1 !flex-row justify-end"
                inputClassName="!p-8 !pl-[38px] h-[40px] leading-[22px] flex-1 outline-0 lg:max-w-[300px] relative focus:max-w-full transition-all !duration-200 filter"
                onChange={handleChangeValue}
                withClearIcon
                {...other}
            />
            <div className={`text-content flex flex-1 mt-[5px] ${isActive ? 'justify-end' : 'justify-start'}`}>
                <span className={`h-[24px] flex flex-1 lg:h-min max-w-[150px]${isActive ? ' !max-w-full' : ''}`}>
                    {filteredCount.current > -1
                        ? `${filteredCount.current} match${filteredCount.current > 1 ? 'es' : ''} found`
                        : null}
                </span>
            </div>
        </div>
    </div>;
}

export default Filter;
