import React, { useState, useRef } from "react";
import Cropper from "react-cropper";

import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import Button from "../Buttons/Button";
import SwitchButton from "../Buttons/SwitchButton";
import { DEFAULT_MAX_FILE_SIZE } from '../../../config';

import "cropperjs/dist/cropper.css";
import {toast} from "react-toastify";

const onGetBlob = canvas => {
    return new Promise(function(resolve) {
        if (!canvas) {
            throw new Error('Crop canvas does not exist');
        }

        canvas.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob');
            }
            const newSrc = URL.createObjectURL(blob);
            resolve({ blob, newSrc });
        });
    });
}

export const CropperModal = ({
    onClose,
    setImageSrc,
    name,
    title,
    fileData,
    preferredSize = { width: 0, height: 0 },
    rounded = false,
    aspectRatio = 1,
    setCroppedImages,
}) => {
    const [enableColor, setEnableColor] = useState(false);
    const [color, setColor] = useState('#000000');
    const cropperRef = useRef();

    const setCropperBGColor = color => {
        const element = document.querySelector('.cropper-bg');
        if (color) {
            element.style.background = color;
        } else {
            element.style.removeProperty('background');
        }
    }

    return <div className="max-h-[inherit] flex flex-col overflow-hidden">
        <ModalHeader title={title} onClose={onClose} />
        <ModalContent>
            {fileData?.src
                ? <div className="flex">
                    <div className="flex flex-col p-16 flex-1 gap-16">
                        <div className="flex items-center justify-between gap-8">
                            <p className="text-xs">{fileData.name}</p>
                            <p className="text-label shrink-0  text-content">
                                {`Image size: ${fileData.width} x ${fileData.height}`}
                            </p>
                        </div>
                        <div className="bg-black w-full max-h-[350px] flex justify-center">
                            <Cropper
                                ref={cropperRef}
                                className={`max-h-[340px] w-full ${rounded ? '[&_.cropper-view-box]:rounded-full [&_.cropper-face]:rounded-full' : ''}`}
                                zoomTo={0}
                                aspectRatio={aspectRatio}
                                src={fileData.src}
                                viewMode={0}
                                dragMode="move"
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={true}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                guides={true}
                                toggleDragModeOnDblclick={false}
                            />
                        </div>
                    </div>
                    <div className="bg-[#E5E5E5] p-16 flex flex-col gap-16 flex-1 max-w-[300px]">
                        {preferredSize.width && preferredSize.width
                            ? <p>{`Preferred size: ${preferredSize.width} x ${preferredSize.height}`}</p>
                            : null}
                        <div className="flex-1">
                            <div className="flex flex-col gap-10">
                                <SwitchButton
                                    containerClass='!flex justify-between flex-1 basis-[36px]'
                                    elementClass="w-[36px] h-[12px] rounded-[6px] bg-[#C4C4C4] peer-checked:bg-edit-color-light"
                                    roundClass='after:left-0 peer-checked:after:left-[16px] after:bg-white peer-checked:after:bg-white'
                                    label="Enable color BG:"
                                    checked={enableColor}
                                    onChange={() => setEnableColor(prevState => {
                                        setCropperBGColor(prevState ? null : color);
                                        return !prevState;
                                    })}
                                />
                                {enableColor
                                    ? <div className="flex flex-1 justify-between gap-12">
                                        <label htmlFor="cropper-color-picker">Background color:</label>
                                        <input
                                            id="cropper-color-picker"
                                            type="color"
                                            value={color}
                                            onChange={e => {
                                                const color = e.target.value;
                                                setColor(color);
                                                setCropperBGColor(color);
                                            }}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className="text-label">
                            <p>• Click on the picture and drag to move the picture</p>
                            <p>• Scroll to zoom in and zoom out</p>
                            <p>• Drag the selection corner to resize the crop area</p>
                        </div>
                        {fileData.width < preferredSize.width || fileData.height < preferredSize.height
                            ? <p className="text-warning">
                                The image you uploaded is smaller than the recommended size.
                                This will negatively affect the quality of the saved image.
                            </p>
                            : null}
                    </div>
                </div>
                : null}
        </ModalContent>
        <ModalFooter
            submitButtonType="button"
            onClose={onClose}
            additionalButton={<div className="flex gap-16">
                <Button
                    size="small"
                    onClick={() => cropperRef.current?.cropper.zoom(0.1)}
                    buttonStyle='transparent-black'
                    tabIndex={1}
                >
                    Zoom in
                </Button>
                <Button
                    size="small"
                    onClick={() => cropperRef.current?.cropper.zoom(-0.1)}
                    buttonStyle='transparent-black'
                    tabIndex={1}
                >
                    Zoom out
                </Button>
            </div>}
            onSubmit={() => {
                const cropData = cropperRef.current?.cropper.getCroppedCanvas({
                    fillColor: enableColor ? color : undefined,
                    imageSmoothingQuality: 'high',
                    imageSmoothingEnabled: true,
                    width: preferredSize.width || 960,
                    height: preferredSize.height || 960,
                });
                onGetBlob(cropData).then(({ blob, newSrc }) => {
                    if (blob.size > DEFAULT_MAX_FILE_SIZE) {
                        toast.warning(`The size of the cropped image exceeds ${DEFAULT_MAX_FILE_SIZE/1024/1024}MB. Please reduce the cropping area or choose a different file.`);
                    }  else {
                        setImageSrc(newSrc);
                        setCroppedImages(prevState => ({
                            ...prevState,
                            [name]: blob,
                        }))
                        onClose();
                    }
                })
            }}
        />
    </div>;
};

export default CropperModal;
