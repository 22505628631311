/* Tiles Icons*/
import {ReactComponent as AidKit} from './tiles_icons/aid_kit.svg';
import {ReactComponent as Ball} from './tiles_icons/ball.svg';
import {ReactComponent as BallInFire} from './tiles_icons/ball_in_fire.svg';
import {ReactComponent as BallTurned} from './tiles_icons/ball_turned.svg';
import {ReactComponent as Boot} from './tiles_icons/boot.svg';
import {ReactComponent as Boy} from './tiles_icons/boy.svg';
import {ReactComponent as Brackets} from './tiles_icons/brackets.svg';
import {ReactComponent as Certificate} from './tiles_icons/certificate.svg';
import {ReactComponent as Crown} from './tiles_icons/crown.svg';
import {ReactComponent as Document} from './tiles_icons/document.svg';
import {ReactComponent as Flag} from './tiles_icons/flag.svg';
import {ReactComponent as Girl} from './tiles_icons/girl.svg';
import {ReactComponent as Group} from './tiles_icons/group.svg';
import {ReactComponent as Hand} from './tiles_icons/hand.svg';
import {ReactComponent as Medal} from './tiles_icons/medal.svg';
import {ReactComponent as Net} from './tiles_icons/net.svg';
import {ReactComponent as NetFull} from './tiles_icons/net_full.svg';
import {ReactComponent as Pedestal} from './tiles_icons/pedestal.svg';
import {ReactComponent as Player} from './tiles_icons/player.svg';
import {ReactComponent as Playground} from './tiles_icons/playground.svg';
import {ReactComponent as Portfolio} from './tiles_icons/portfolio.svg';
import {ReactComponent as Prize} from './tiles_icons/prize.svg';
import {ReactComponent as Referee} from './tiles_icons/referee.svg';
import {ReactComponent as Score} from './tiles_icons/score.svg';
import {ReactComponent as Shield} from './tiles_icons/shield.svg';
import {ReactComponent as Tactic} from './tiles_icons/tactic.svg';
import {ReactComponent as Tickets} from './tiles_icons/tickets.svg';
import {ReactComponent as Timer} from './tiles_icons/timer.svg';
import {ReactComponent as Uniform} from './tiles_icons/uniform.svg';
import {ReactComponent as Whistle} from './tiles_icons/whistle.svg';
/* Social Icons */
import {ReactComponent as Twitter} from './social_icons/twitter.svg';
import {ReactComponent as Facebook} from './social_icons/facebook.svg';
import {ReactComponent as Instagram} from './social_icons/instagram.svg';
import {ReactComponent as Youtube} from './social_icons/youtube.svg';
import {ReactComponent as Tiktok} from './social_icons/tiktok.svg';

/* Craft Sections Icons*/
import {ReactComponent as Columns} from './craft_sections_icons/columns.svg';
import {ReactComponent as Form} from './craft_sections_icons/form.svg';
import {ReactComponent as Gallery} from './craft_sections_icons/gallery.svg';
import {ReactComponent as Hero} from './craft_sections_icons/hero.svg';
import {ReactComponent as Partners} from './craft_sections_icons/partners.svg';
import {ReactComponent as Text} from './craft_sections_icons/text.svg';
import {ReactComponent as Tiles} from './craft_sections_icons/tiles.svg';
import {ReactComponent as Contacts} from './craft_sections_icons/contacts.svg';
import {ReactComponent as Socials} from './craft_sections_icons/socials.svg';
/* Team Page Details Icons */
import {ReactComponent as Head} from './teamPage_details_icons/details_person.svg';
import {ReactComponent as Assistant} from './teamPage_details_icons/details_group.svg';
import {ReactComponent as Grade} from './teamPage_details_icons/details_grade.svg';
import {ReactComponent as Gender} from './teamPage_details_icons/details_gender.svg';
import {ReactComponent as Organization} from './teamPage_details_icons/details_buildings.svg';
import {ReactComponent as Abbreviation} from './teamPage_details_icons/details_abbr.svg';
import {ReactComponent as TeamType} from './teamPage_details_icons/details_type.svg';
/* Empty Content Icons */
import {ReactComponent as EmptyEvent} from './empty-content_icons/empty_event.svg';
import {ReactComponent as EmptyHistory} from './empty-content_icons/empty_history.svg';
import {ReactComponent as EmptyRoster} from './empty-content_icons/empty_roster.svg';
import {ReactComponent as EmptyStatistic} from './empty-content_icons/empty_statistic.svg';
import {ReactComponent as EmptyTeams} from './empty-content_icons/empty_teams.svg';
import {ReactComponent as EmptyStaff} from './empty-content_icons/empty_staff.svg';
import {ReactComponent as EmptySchedule} from './empty-content_icons/empty_schedule.svg';

import {ReactComponent as ImageAdd} from './image_add.svg';
import {ReactComponent as ArrowBack} from './arrow_back.svg';
import {ReactComponent as ArrowLeft} from './arrow_left.svg';
import {ReactComponent as ArrowRight} from './arrow_right.svg';
import {ReactComponent as ArrowUp} from './arrow_up.svg';
import {ReactComponent as ArrowDown} from './arrow_down.svg';
import {ReactComponent as Close} from './close.svg';
import {ReactComponent as Delete} from './delete.svg';
import {ReactComponent as Edit} from './edit.svg';
import {ReactComponent as Geolocation} from './geolocation.svg';
import {ReactComponent as Phone} from './phone.svg';
import {ReactComponent as Plus} from './plus.svg';
import {ReactComponent as ShareMobile} from './share_mobile.svg';
import {ReactComponent as ShareDesktop} from './share_desktop.svg';
import {ReactComponent as EmptyFolder} from './empty_folder.svg';
import {ReactComponent as EmptyFilterList} from './empty_filter_list.svg';
import {ReactComponent as Burger} from './burger.svg';
import {ReactComponent as Filter} from './filter.svg';
import {ReactComponent as Image404} from './404.svg';
import {ReactComponent as Popout} from './popout.svg';
import {ReactComponent as Construction} from './construction.svg';
import {ReactComponent as Home} from './home.svg';
import {ReactComponent as Schedule} from './schedule.svg';
import {ReactComponent as Copy} from './copy.svg';
import {ReactComponent as Warning} from './warning.svg';
import {ReactComponent as Drag} from './drag_indicator.svg';
import {ReactComponent as Error} from './error.svg';

export const icons = {
    TILES: {
        aid_kit: AidKit,
        ball: Ball,
        ball_in_fire: BallInFire,
        ball_turned: BallTurned,
        boot: Boot,
        boy: Boy,
        brackets: Brackets,
        certificate: Certificate,
        crown: Crown,
        document: Document,
        flag: Flag,
        girl: Girl,
        group: Group,
        hand: Hand,
        medal: Medal,
        net: Net,
        net_full: NetFull,
        pedestal: Pedestal,
        player: Player,
        playground: Playground,
        portfolio: Portfolio,
        prize: Prize,
        referee: Referee,
        score: Score,
        shield: Shield,
        tactic: Tactic,
        tickets: Tickets,
        timer: Timer,
        uniform: Uniform,
        whistle: Whistle,
    },
    SOCIALS: {
        twitter: Twitter,
        instagram: Instagram,
        facebook: Facebook,
        tiktok: Tiktok,
        youtube: Youtube,
    },
    SECTIONS: {
        tiles: Tiles,
        text: Text,
        hero: Hero,
        gallery: Gallery,
        form: Form,
        columns: Columns,
        partners: Partners,
        contacts: Contacts,
        socials: Socials,
    },
    DETAILS: {
        abbreviation: Abbreviation,
        assistant: Assistant,
        head: Head,
        gender: Gender,
        grade: Grade,
        gallery: Gallery,
        organization: Organization,
        team_type: TeamType,
    },
    EMPTY: {
        event: EmptyEvent,
        history: EmptyHistory,
        roster: EmptyRoster,
        statistic: EmptyStatistic,
        teams: EmptyTeams,
        staff: EmptyStaff,
        schedule: EmptySchedule,
    },
    arrow_back: ArrowBack,
    arrow_down: ArrowDown,
    arrow_left: ArrowLeft,
    arrow_right: ArrowRight,
    arrow_up: ArrowUp,
    close: Close,
    delete: Delete,
    edit: Edit,
    geolocation: Geolocation,
    image_add: ImageAdd,
    phone: Phone,
    plus: Plus,
    share_mobile: ShareMobile,
    share_desktop: ShareDesktop,
    empty_folder: EmptyFolder,
    empty_filter_list: EmptyFilterList,
    burger: Burger,
    filter: Filter,
    image404: Image404,
    popout: Popout,
    construction: Construction,
    home: Home,
    schedule: Schedule,
    copy: Copy,
    warning: Warning,
    drag: Drag,
    error: Error,
};

export default function Icon({ name, arr, className, outerRef, ...rest }) {
    let result;
    if (name) {
        const Component = arr ? icons[arr][name] : icons[name];
        result = Component ? <Component ref={outerRef} className={className || 'fill-black'} {...rest} /> : '?';
    } else {
        result = '?';
    }
    return result
};
