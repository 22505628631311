import { useContext, useState } from 'react';

import VenueModalContent from "../../page-venues/components/VenueModalContent";
import { SettingsContext, FormDataContext } from "../../../context";
import { areNumbersEqual } from "../../../utils";
import ApiClient from "../../../api";
import { useMobile } from "../../../hooks";

const ManageVenue = ({ onClose, title, setVenues, ...rest }) => {
    const [isModalLoading, setModalLoading] = useState(false);
    const [croppedImages, setCroppedImages] = useState(false);
    const isMobile = useMobile();

    const { states, countries } = useContext(SettingsContext);

    // TODO: implement the better way to remove empty options
    const countriesWithoutEmptyValue = { ...countries };
    const statesWithoutEmptyValue = { ...states };
    delete countriesWithoutEmptyValue['Select Country'];
    delete statesWithoutEmptyValue['Select State/Province'];

    const handleSave = async event => {
        event.preventDefault();
        const formData = new window.FormData(event.target);

        const venue = Object.fromEntries(formData.entries());
        setModalLoading(true);
        const thenCallback = venue => {
            const updateVenues = prevState => {
                const editedVenueIndex = prevState.findIndex(item => areNumbersEqual(item.id, venue.id));
                if (editedVenueIndex > -1) {
                    prevState[editedVenueIndex] = venue;
                    return [...prevState];
                }
                return [venue, ...prevState];
            }
            setVenues(updateVenues);
            onClose();
        };
        if (rest.id) {
            venue.id = rest.id;
        }
        venue.logo = croppedImages.logo || (venue.logo.size ? venue.logo : (rest.logoPath || ''));
        ApiClient.onPostRequest({
            action: 'onManageVenue',
            content: { venue },
            thenCallback,
        });
    }

    return <form onSubmit={handleSave}>
        <FormDataContext.Provider value={{ setCroppedImages }}>
            <VenueModalContent
                isLoading={isModalLoading}
                isMobile={isMobile}
                countries={countriesWithoutEmptyValue}
                states={statesWithoutEmptyValue}
                onClose={onClose}
                title={title}
                data={rest}
                croppedImages={croppedImages}
            />
        </FormDataContext.Provider>
    </form>
};

export default ManageVenue;
