const textStyles = 'text-s leading-standard text-title placeholder-input-placeholder font-normal font-main-font';
const borderStyles = 'border border-input-default-border rounded-input focus:border-title hover:border-title focus-visible:border-title focus-visible:outline-0';
const bgStyles = 'bg-input-default-bg focus:bg-input-active-bg disabled:bg-input-disabled-bg';

export default function CustomTextarea({
    required,
    title,
    value,
    rows,
    name,
    placeholder,
    disabled,
    onChange,
    className,
    ...other
}) {
    return (
        <label className={`flex flex-col ${className}`}>
            {title && <p className='text-label leading-label font-semibold mb-8'>{title}</p>}
            <textarea
                name={name}
                rows={rows || 6}
                disabled={disabled}
                onChange={event => onChange ? onChange(event.target.value) : null}
                className={`${textStyles} ${borderStyles} ${bgStyles} h-full px-input-x-p py-input-y-p`}
                value={value}
                placeholder={placeholder || 'Type here'}
                required={required}
                {...other}
            />
        </label>
    )
}