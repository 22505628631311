import Icon from '../../../assets/images/icons';

export default function CustomUploadIcon({ className, setIcon, chooseIcon, icon, title }) {
    return <div className={className ?? ''}>
        {title && <p className='w-full mb-12 text-label leading-label font-semibold text-black'>
            {title}
        </p>}
        <div className='flex items-start'>
            <div className='w-[72px] h-[72px] mr-24'>
                {icon
                    ? <Icon arr='TILES' name={icon} className='fill-black'/>
                    : <div className='font-semibold font-accent-font flex items-center justify-center text-[#5e5e5e] text-[11px] h-full bg-card-default border-dashed border-[2px] border-[#c9c9c9] text-[11px]'>
                        No image
                    </div>}
            </div>
            <div className='flex flex-col'>
                <p className='pb-24 text-xs leading-xs'>Click Upload to add predefined icon</p>
                <div className='flex'>
                    <button
                        title="Choose icon"
                        type='button'
                        onClick={chooseIcon}
                        className='flex mr-16'
                    >
                        <Icon name='image_add' />
                    </button>
                    <button
                        title="Remove icon"
                        onClick={() => setIcon('')}
                        disabled={!icon}
                        type='button'
                        className='flex disabled:cursor-not-allowed'
                    >
                        <Icon className={!icon && 'fill-icon-disabled' } name='delete' />
                    </button>
                </div>
            </div>
        </div>
    </div>
}
