import { useContext } from 'react';
import { useNode } from '@craftjs/core';

import TextModalSettings from './TextModalSettings';
import CommonHeading from '../../common/CommonHeading';
import { parseTemplateValues } from '../../../utils';
import { SettingsContext } from '../../../context';

const Text = ({title, description}) => {
    const { connectors: { connect } } = useNode();
    const settings = useContext(SettingsContext);
    const parsedTitle = parseTemplateValues(title, settings);
    const parsedDescription = parseTemplateValues(description, settings);

    return (
        <div ref={connect} className='wrapper py-default_section_py lg:py-default_section_md_py'>
            <div className='lg:max-w-[65vw] mx-auto'>
                {parsedTitle &&
                    <CommonHeading className='break-words-custom' craftComponent textSection>
                        {parsedTitle}
                    </CommonHeading>
                }
                {parsedDescription &&
                    <div className='quill-content break-words-custom w-full text-s lg:text-m leading-standard text-content'>
                        <div dangerouslySetInnerHTML={{ __html: parsedDescription}} />
                    </div>}
            </div>
        </div>
    );
};

Text.craft = {
    displayName: 'Text',
    props: {
        title: 'Idea for section name — Welcome to ...',
        description: 'This exemplary text serves as a template to illustrate the appearance of your customized content. This section can be effectively utilized to describe various aspects, such as your organization\'s mission, values, or key offerings. Keep in mind that a concise yet informative approach enhances the visual appeal of this section. Craft your text thoughtfully to succinctly convey the most crucial information, ensuring it resonates effectively with your audience. Replace this placeholder text with your specific content through the customization options provided in this section, creating a compelling narrative that aligns with your goals and resonates with your target audience.',
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: TextModalSettings,
    },
};

export default Text;
