import { useContext } from 'react';

import SocialLink from '../Buttons/SocialLinks';
import LinkWithQuery from '../LinkWithQuery';
import { SettingsContext } from '../../../context';
import { DEFAULT_SOCIALS } from '../../../config';

function FooterComponent() {
    const { configuration: config } = useContext(SettingsContext);
    const { phone, formattedPhone } = config || {};
    const isWithSocialLinks = DEFAULT_SOCIALS.some(key => config && config[key + 'Visible'] && config[key]);
    const isWithPhone = false;

    return (
        <footer className='font-main-font bg-black text-white pt-40 pb-24'>
            <div className='wrapper flex flex-wrap flex-col  items-center mx-auto'>
                    <div className='text-button leading-button grid gap-x-24 md:gap-x-0 lg:gap-x-24 grid-cols-2 grid-rows-[auto] md:flex md:flex-nowrap md:justify-center md:auto-cols-auto uppercase '>
                        <div className='whitespace-nowrap text-right md:text-left'>
                            <LinkWithQuery isNav className='inline-block p-10 ' to='/'>Home</LinkWithQuery>
                        </div>
                        <div className='whitespace-nowrap'>
                            <LinkWithQuery isNav className='inline-block p-10' to='/teams'>Teams</LinkWithQuery>
                        </div>
                        <div className=' whitespace-nowrap text-right md:text-left'>
                            <a href='/login' className='inline-block p-10'>Log in</a>
                        </div>
                        <div className='whitespace-nowrap'>
                            <LinkWithQuery isNav className='inline-block p-10' to='/schedule'>Schedule</LinkWithQuery>
                        </div>
                    </div>
                    {/* TODO: show phone later */}
                    {isWithPhone && phone
                        ? <div className='font-accent-font mt-40 md:grid md:grid-cols-2 md:gap-24 text-center'>
                            <p className='mb-16 md:mb-0 md:text-right text-h5 leading-h5 lg:text-h4 text-header_main font-bold whitespace-nowrap'>
                                Contact us
                            </p>
                            <a href={`tel:${phone}`} className='font-main-font text-accent-color text-s leading-h5 lg:text-m font-semibold whitespace-nowrap'>
                                {formattedPhone || phone}
                            </a>
                        </div>
                        : null}
                    {isWithSocialLinks
                        ? <div className='mt-40 flex justify-center gap-20 xs:gap-24'>
                            {DEFAULT_SOCIALS.map(key =>
                                config[key + 'Visible'] && config[key]
                                    ? <SocialLink classname='border border-white !w-40 !h-40' key={key} type={key} link={config[key]} />
                                    : null)}
                            </div>
                        : null}
                <div className='mt-40 md:basis-full mx-auto text-center text-label leading-label text-header_menu_item'>
                    <p className='mb-16 text-s leading-standard text-header_main'>
                        © 2023 Powered by TourneyPro.
                    </p>
                    <LinkWithQuery to='/terms-of-use' className='mr-32'>
                        Terms of use
                    </LinkWithQuery>
                    <LinkWithQuery to='/privacy-policy'>
                        Privacy Policy
                    </LinkWithQuery>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent
