import { useContext } from 'react';

import Icon from '../../../assets/images/icons';
import { copyUrl, shareData } from '../../../utils';
import LinkWithQuery from '../../common/LinkWithQuery';
import {TeamDataContext} from '../../../context';

const TEAM_MENU_LIST = [
    {text: 'Schedule', href: 'schedule'},
    {text: 'Statistics', href: 'statistics'},
    {text: 'History', href: 'history'},
    {text: 'Roster', href: 'roster'},
];
export default function TeamHeader() {
    const data = useContext(TeamDataContext);

    return (
        <div className='wrapper py-16 flex items-center text-header_main'>
            {data.logo && <div className='w-40 h-40 mr-12'>
                <img src={data.logo} className='w-full h-full object-cover rounded-full' alt='Team small logo' />
            </div>
            }
            <p className='mr-12 flex flex-col'>
                <span className='text-label leading-label text-header_main'>{data.organization_name}</span>
                <span className='text-s leading-standard font-semibold text-header_main'>{data.schedule_name}</span>
            </p>
            <button
                type='button'
                className='cursor-pointer'
                onClick={() => navigator.share ? shareData() : copyUrl()}
            >
                <Icon
                    name={navigator.share ? 'share_mobile' : 'share_desktop'}
                    className='fill-header_main'
                />
            </button>
            <nav className='ml-auto text-button leading-button font-bold uppercase'>
                {TEAM_MENU_LIST.map((menuItem) => (
                    <LinkWithQuery
                        isNav
                        key={menuItem.text}
                        to={menuItem.href}
                        className={({isActive}) => {
                            return `${isActive ? 'border-b-accent-color' : 'border-b-transparent text-header_menu_item'} border-b-team_desktop_menu ml-40 pb-24`
                        }}
                    >
                        {menuItem.text}
                    </LinkWithQuery>
                ))}
            </nav>
        </div>
    )
}
