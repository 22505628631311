import { Fragment } from 'react';

import HomeContainer from './pages';
import TeamsContainer from './pages/teams';
import StaffContainer from './pages/staff';
import VenuesContainer from './pages/venues';
import ScheduleContainer from './pages/schedule';
import ContactUsContainer from './pages/contact_us';
import NoPage from './pages/404';
import StaticContent from "./pages/static_content";
import Team from './components/page-team';
import TeamSchedule from './components/page-team/tabs/Schedule';
import TeamDetails from './components/page-team/tabs/Details';
import TeamStatistics from './components/page-team/tabs/Statistics';
import TeamHistory from './components/page-team/tabs/History';
import TeamRoster from './components/page-team/tabs/Roster';
import AppWrapper from "./components/craftJS/AppWrapper";
import Hero from "./components/page-home/Hero";
import Text from "./components/page-home/Text";
import Tiles from "./components/page-home/Tiles";
import Gallery from "./components/page-home/Gallery";
import Columns from "./components/page-home/Columns";
import Partners from "./components/page-home/Partners";
import Form from "./components/page-home/Form";
import Contacts from "./components/page-contact_us/Contacts";
import SocialLinks from "./components/page-contact_us/SocialLinks";
import Home from "./components/page-home";
import ContactUs from "./components/page-contact_us";
import Venues from "./components/page-venues";
import Schedule from "./components/page-schedule";
import Teams from "./components/page-teams";
import Staff from "./components/page-staff";
import Empty from "./components/craftJS/empty";
import NavigateWithQuery from "./components/common/NavigateWithQuery";

export const titles = {
    teams: 'Teams',
    staff: 'Staff',
    venues: 'Venues',
    schedule: 'Schedule',
    statistics: 'Statistics',
    history: 'History',
    roster: 'Roster',
};

export const PATH_ROOT = '/';
export const PATH_404 = '/404';

export const routes = [
    { isIndex: true, element: <HomeContainer /> },
    { path: 'teams', element: <TeamsContainer /> },
    { path: 'staff', element: <StaffContainer /> },
    { path: 'schedule', element: <ScheduleContainer /> },
    { path: 'venues', element: <VenuesContainer /> },
    { path: 'contact-us', element: <ContactUsContainer /> },
    { path: 'teams/:team', element: <Team />, children: [
        { isIndex: true, element: <NavigateWithQuery to='schedule' replace /> },
        { path: 'schedule', element: <TeamSchedule /> },
        { path: 'details', element: <TeamDetails /> },
        { path: 'statistics', element: <TeamStatistics /> },
        { path: 'history', element: <TeamHistory /> },
        { path: 'roster', element: <TeamRoster /> },
    ]},
    { path: '/privacy-policy', element: <StaticContent type="privacyData" /> },
    { path: '/terms-of-use', element: <StaticContent type="termsData" /> },
    { path: '/static/media/*', element: <Fragment /> },
    { path: '/404', element: <NoPage /> },
    { path: '*', element: <NavigateWithQuery to='/404' replace /> },
];

export const CraftJSResolver = {
    AppWrapper,
    Hero,
    Text,
    Tiles,
    Gallery,
    Columns,
    Partners,
    Form,
    Contacts,
    SocialLinks,
    Empty,
    Home,
    ContactUs,
    Venues,
    Staff,
    Schedule,
    Teams,
};

export const CRAFTJS_PAGES = {
    home: { key: 'home', name: 'home', pathname: '/' },
    teams: { key: 'teams', name: 'teams', pathname: '/teams' },
    staff: { key: 'staff', name: 'staff', pathname: '/staff' },
    schedule: { key: 'schedule', name: 'schedule', pathname: '/schedule' },
    venues: { key: 'venues', name: 'venues', pathname: '/venues' },
    contact_us: { key: 'contact_us', name: 'contact us', pathname: '/contact-us' },
};

export const SIDEBAR_SECTIONS = [
    { name: 'Hero', icon: 'hero', element: Hero, pages: [CRAFTJS_PAGES.home.key] },
    { name: 'Tiles', icon: 'tiles', element: Tiles, pages: [CRAFTJS_PAGES.home.key] },
    { name: 'Text', icon: 'text', element: Text, pages: [CRAFTJS_PAGES.home.key, CRAFTJS_PAGES.contact_us.key] },
    { name: 'Gallery', icon: 'gallery', element: Gallery, pages: [CRAFTJS_PAGES.home.key] },
    { name: 'Join form', icon: 'form', element: Form, pages: [CRAFTJS_PAGES.home.key, CRAFTJS_PAGES.contact_us.key] },
    { name: 'Columns', icon: 'columns', element: Columns, pages: [CRAFTJS_PAGES.home.key] },
    { name: 'Partners', icon: 'partners', element: Partners, pages: [CRAFTJS_PAGES.home.key] },
    { name: 'Contacts', icon: 'contacts', element: Contacts, pages: [CRAFTJS_PAGES.contact_us.key] },
    { name: 'Social links', icon: 'socials', element: SocialLinks, pages: [CRAFTJS_PAGES.contact_us.key] },
];

export const apiMethodByType = {
    teamData: { variable: 'teamData', action: 'onGetTeamDetails' },
    teamsData: { variable: 'teamsData', action: 'onGetTeamsList' },
    coachesData: { variable: 'coachesData', action: 'onGetCoachesList' },
    scheduleData: { variable: 'scheduleData', action: 'onGetSchedule' },
    venuesData: { variable: 'venuesData', action: 'onGetVenuesList' },
    privacyData: { variable: 'staticData', action: 'onGetPrivacyPolicy' },
    termsData: { variable: 'staticData', action: 'onGetTermsOfUse' },
};

export const modalTypes = {
    settings: 'settings',
    staffMessage: 'staffMessage',
    icons: 'icons',
    manageVenue: 'manageVenue',
    deleteVenue: 'deleteVenue',
    confirm: 'confirm',
    venueBackdrop: 'venueBackdrop',
};

export const modalSizes = { sm: 'sm', lg: 'lg' };

export const DEFAULT_MODAL_PROPS_BY_TYPE = {
    [modalTypes.settings]: { isThemed: true, size: modalSizes.lg, },
    [modalTypes.staffMessage]: { isThemed: true },
    [modalTypes.icons]: {},
    [modalTypes.manageVenue]: { size: modalSizes.lg },
    [modalTypes.deleteVenue]: { size: modalSizes.sm },
    [modalTypes.confirm]: { size: modalSizes.sm },
    [modalTypes.venueBackdrop]: {
        isThemed: true,
        animated: true,
        closeClickOutside: true,
        alignBottom: true,
    },
};

export const iconColorType = {
    info: 'info',
    warning: 'warning',
    error: 'error',
    success: 'success',
}

export const settingsVariablesMap = {
    'company name': 'organization',
    'company logo': 'logo',
    'company phone': 'phone',
    'company formatted phone': 'formattedPhone',
    'company email': 'email',
};

export const prefixByName = {
    'Columns': 'columns',
    'Gallery': 'gallery',
    'Tiles': 'tiles',
    'Partners': 'partners',
    'Contacts': 'contacts',
};

export const DEFAULT_SOCIALS = ['twitter', 'instagram', 'facebook', 'youtube', 'tiktok'];

export const DEFAULT_MAX_FILE_SIZE = 4.5 * 1024 * 1024;
export const MAX_SIZE_PER_REQUEST = 4.5 * 1024 * 1024 + 1;
