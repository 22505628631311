import { useRef, useState, useContext } from 'react';
import { useNode } from '@craftjs/core';

import Contact from './Contact';
import ModalHeader from '../../common/Modal/ModalHeader';
import ModalContent from '../../common/Modal/ModalContent';
import ModalFooter from '../../common/Modal/ModalFooter';
import CustomQuillElement from '../../common/Controls/CustomQuillElement';
import CustomInput from '../../common/Controls/CustomInput';
import FormImageUploadWrapper from '../../common/FormImageUploadWrapper';

import { SettingsContext } from '../../../context';
import {
    dispatchChange,
    handleAddOneMoreItem,
    handleChangeItemsOrder,
    handleCloseModal,
    handleDeleteItem,
    handleSetItemValue,
    parseTemplateValues
} from '../../../utils';
import { useOnChange } from '../../../hooks';
import { prefixByName } from '../../../config';
import { DEFAULT_PREFIX_DIVIDER } from '../../../const';

const DEFAULT_ITEM = { icon: '', title: '', description: '' };

const ContactsModalSettings = ({ onSave }) => {
    const {
        props: { uuid, items, description, title },
        actions: { setProp, setCustom },
    } = useNode(node => ({ props: node.data.props }));

    const settings = useContext(SettingsContext);
    const [isLoading, setLoading] = useState(false);
    const [newTitle, setNewTitle] = useState(parseTemplateValues(title, settings));
    const [newDescription, setNewDescription] = useState(parseTemplateValues(description, settings));

    const [newItems, setNewItems] = useState(items.map(item => {
        const newItem = { ...item };
        newItem.name = parseTemplateValues(newItem.name, settings);
        newItem.main_phone = parseTemplateValues(newItem.main_phone, settings);
        newItem.additional_phone = parseTemplateValues(newItem.additional_phone, settings);
        newItem.fax = parseTemplateValues(newItem.fax, settings);
        newItem.email = parseTemplateValues(newItem.email, settings);
        return newItem;
    }));

    const ref = useRef();
    const changeRef = useRef(null);
    const isChanged = useOnChange(changeRef);

    const handleClose = () => handleCloseModal(setCustom);

    const handleAddOneMore = () => {
        dispatchChange(changeRef);
        handleAddOneMoreItem(
            setNewItems,
            DEFAULT_ITEM,
            `${prefixByName.Contacts}${DEFAULT_PREFIX_DIVIDER}${uuid}`,
            { element: ref.current },
        );
    }
    const handleChangeOrder = (index, newIndex) => {
        dispatchChange(changeRef);
        handleChangeItemsOrder(setNewItems, index, newIndex);
    }

    const handleSave = async (e) => {
        e.preventDefault();
        handleClose();
        if (isChanged) {
            setLoading(true);
            setProp(props => {
                props.items = newItems;
                props.title = newTitle;
                props.description = newDescription;
            });
            await onSave();
            setLoading(false);
        }
    }

    return (
        <FormImageUploadWrapper parentIsLoading={isLoading} onSubmit={handleSave}>
            <ModalHeader title='Edit Contacts section' onClose={handleClose}/>
            <ModalContent outerRef={changeRef}>
                <div className='w-full'>
                    <CustomInput
                        label='Add a section title'
                        onChange={setNewTitle}
                        value={newTitle}
                        className='mb-16 mt-20 mx-24'
                    />
                    <CustomQuillElement
                        title='Add a section description'
                        value={newDescription}
                        wrapperClassName='mb-20 mx-24'
                        className='quill-text_block'
                        onChange={value => {
                            dispatchChange(changeRef);
                            setNewDescription(value);
                        }}
                    />
                    <div className='flex justify-between border-t border-color-border overflow-hidden'>
                        <div className='w-full flex flex-wrap'>
                            {newItems.map((item, index )=>
                                <Contact
                                    template={settings.template}
                                    key={item.id}
                                    handleChangeOrder={handleChangeOrder}
                                    index={index}
                                    isLast={index === newItems.length - 1}
                                    handleSetValue={(value, id, type) => {
                                        dispatchChange(changeRef);
                                        handleSetItemValue(setNewItems)(value, id, type);
                                    }}
                                    handleDelete={id => {
                                        dispatchChange(changeRef);
                                        handleDeleteItem(setNewItems)(id)}
                                    }
                                    {...item}
                                />
                            )}
                            <div ref={ref} className='invisible'></div>
                        </div>
                    </div>
                </div>
            </ModalContent>
            <ModalFooter
                onClose={handleClose}
                additionalButton={{ text: 'Add Contacts', onClick: handleAddOneMore }}
                elementsCount={`${newItems.length} contact${newItems.length > 1 ? 's': ''} added`}
            />
        </FormImageUploadWrapper>
    );
};

export default ContactsModalSettings;
