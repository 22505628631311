import CustomUploadIcon from '../../common/Controls/CustomUploadIcon';
import CustomQuillElement from '../../common/Controls/CustomQuillElement';
import CustomInput from '../../common/Controls/CustomInput';
import Icon from '../../../assets/images/icons';

const Tile = ({
    id,
    icon,
    title,
    description,
    handleSetValue,
    handleDelete,
    index,
    isLast,
    handleChangeOrder,
    handleChooseIcon
}) => {
    return (
        <div className='flex w-full py-20 px-24 border-b last:border-none border-border'>
            <div className='basis-[47%] pr-24 flex flex-col justify-between'>
                <CustomUploadIcon
                    title='Tile Image'
                    icon={icon}
                    chooseIcon={() => handleChooseIcon(id, icon)}
                    setIcon={value => handleSetValue(value, id, 'icon')}
                />
                <CustomInput
                    label='Add a tile’s title'
                    className='pt-24'
                    value={title}
                    onChange={value => handleSetValue(value, id, 'title')}
                />
            </div>
            <CustomQuillElement
                title='Add a tile’s text'
                value={description}
                wrapperClassName='pr-16 basis-[47%]'
                className='max-h-tile-quill'
                onChange={value => handleSetValue(value, id, 'description')}
            />
            <div className='border-l border-border pl-16 flex flex-col justify-between items-center'>
                <button title="Remove tile" type='button' onClick={() => handleDelete(id)}>
                    <Icon name='close'/>
                </button>
                <button
                    title="Move this tile up"
                    onClick={() => handleChangeOrder(index, index-1)}
                    className='mt-auto mb-12'
                    disabled={!index}
                    type='button'
                >
                    <Icon className={!index && 'fill-icon-disabled'} name='arrow_up' />
                </button>
                <button
                    title="Move this tile down"
                    onClick={() => handleChangeOrder(index, index+1)}
                    disabled={isLast}
                    type='button'
                >
                    <Icon className={isLast && 'fill-icon-disabled'}  name='arrow_down' />
                </button>
            </div>
        </div>)
};

export default Tile;
