import React from 'react';
import { areNumbersEqual, getNumberSuffixFromNumber } from './utils';

const TEAM_PLACEHOLDER = 'TBA';

const gameViewFilter = (
    competitor,
    listTeams,
    pools,
    games,
    defaultBrackets,
    bracketId,
    currentGameScore,
    divisionBracketsGamesIndexesStarts,
) => {
    if (!competitor) {
        return 'Error: No team.';
    }
    if (competitor.error) {
        return competitor.error;
    }

    let result = '';
    switch (competitor.type) {
        case defaultBrackets.competitor_source.fromList:
            if (competitor.index > listTeams.length) {
                result = `Error: teams count is less than: ${competitor.index}`;
            } else if (competitor.index === 0) {
                result = 'Error: no team was chosen.';
            } else {
                result = listTeams[competitor.index - 1].name.trim() || TEAM_PLACEHOLDER;
            }
            break;
        case defaultBrackets.competitor_source.teamId: {
            const team = listTeams.find(team => team.id === parseInt(competitor.id, 10));
            if (team) {
                result = <>
                    <div>{!team.name.trim() ? TEAM_PLACEHOLDER : team.name}</div>
                    {(currentGameScore || typeof currentGameScore === 'number') && <div className="team-score">{currentGameScore}</div>}
                </>;
            } else {
                result = `Error: team "${competitor.id}" was not found in division.`;
            }
            break;
        }
        case defaultBrackets.competitor_source.gameResult:
            result = (competitor.result || '') + competitor.id;
            break;
        case defaultBrackets.competitor_source.gameResultVariant:
            (competitor.parent ? Array.isArray(competitor.parent) ? competitor.parent : [competitor.parent] : []).forEach(parent => {
                if (result.length > 0) {
                    result += ' | ';
                }
                result += ' ' + competitor.result + parent;
            });
            break;
        case defaultBrackets.competitor_source.customGameResult: {
            const matchingCustomGames = games.filter(game => game.customName && game.customName === competitor.id);
            result = matchingCustomGames.length === 1 ? (competitor.result || '') + ' ' + matchingCustomGames[0].customName : 'Error: no matching custom game';
            break;
        }
        case defaultBrackets.competitor_source.fromPool:
            if (!pools) {
                result = 'Error: no pools in division.';
            } else {
                const pool = pools.find(pool => areNumbersEqual(pool.id, competitor.id));
                if (!pool) {
                    result = `Error: no such pools in division: ${(competitor.id + 1)}`;
                } else if (competitor.result > pool.teams.length) {
                    result = `Error: teams count in pool ${pool.name} is less than ${competitor.result}.`;
                } else {
                    result = pool.teams[competitor.result - 1].name;
                }
            }
            break;
        case defaultBrackets.competitor_source.custom:
            result = competitor.text;
            break;
        case defaultBrackets.competitor_source.xthLowestPtDiff:
            result = getNumberSuffixFromNumber(Number(competitor.teamIndex)) + ' Lowest pt diff';
            break;
        case defaultBrackets.competitor_source.multiWinnerLoser:
            result = competitor.variants.map(value => {
                const startIndex = divisionBracketsGamesIndexesStarts[bracketId || 0];
                return value[0] + Number(startIndex + Number(value.substring(1)));
            }).join(' | ');
            break;
        case defaultBrackets.competitor_source.poolPlace:
            if (!pools) {
                result = 'Error: no pools in division.';
            } else {
                const pool = pools.find(pool => areNumbersEqual(pool.id, competitor.poolId));
                if (!pool) {
                    result = `Error: no such pools in division: ${(competitor.poolId)}`;
                } else if (competitor.teamIndex > pool.teams.length) {
                    result = `Error: teams count in pool ${pool.name} is less than ${competitor.result}.`;
                } else {
                    result = `${pool.shortLabel}, ${getNumberSuffixFromNumber(Number(competitor.teamIndex))} place`;
                }
            }
            break;
        case defaultBrackets.competitor_source.winnerFromBracketGame:
        case defaultBrackets.competitor_source.loserFromBracketGame:
            result =
                `${competitor.type === defaultBrackets.competitor_source.winnerFromBracketGame ? 'W' : 'L'}${
                    divisionBracketsGamesIndexesStarts[competitor.bracketId] + Number(competitor.id)
                }`;
            break;
        case defaultBrackets.competitor_source.divisionPlace:
            result = `Division ${getNumberSuffixFromNumber(Number(competitor.teamIndex))} place`;
            break;
        default:
            result = `Error: Unknown team source: ${JSON.stringify(competitor)}.`;
            break;
    }

    return result;
};

const GameTemplate = ({
    listTeams,
    pools,
    games,
    gameId,
    bracketData,
    competitorIndex,
    defaultBrackets,
    divisionBracketsGamesIndexesStarts,
}) => {
    const { id: bracketId, data } = bracketData;
    const gameData = data?.[gameId]?.competitors[competitorIndex];

    const currentGame = games.find(game =>
        areNumbersEqual(game.bracketGameName, gameId) &&
        areNumbersEqual(game.bracket, bracketId),
    );
    const currentGameScore = currentGame
        ? currentGame[`team${competitorIndex ? 'B' : 'A'}Score`]
        : null;

    return <div className="team-name-container">
        {gameViewFilter(
            gameData,
            listTeams,
            pools,
            games,
            defaultBrackets,
            bracketId,
            currentGameScore,
            divisionBracketsGamesIndexesStarts,
        )}
    </div>;
};

export default GameTemplate;
