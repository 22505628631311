import React, { useRef, useEffect, useContext } from 'react';
import moment from "moment";

import Spinner from '../common/Spinner';
import { ScheduleDataContext } from '../../context';
import Icon from "../../assets/images/icons";
import GradientLogo from "../common/GradientLogo";
import LinkWithQuery from "../common/LinkWithQuery";
import { generateTitle, navigateByCoords } from "../../utils";
import { EmptyContent } from "../common/EmptyContent";
import ScheduleNoMatches from "../page-schedule/components/ScheduleNoMatches";

const ScheduleDesktopLayout = ({ setCurrentFilterContainer }) => {
    const spinnerContainer = useRef(null);
    const { data, filteredData, enabled, isLoading } = useContext(ScheduleDataContext);

    useEffect(() => {
        const pageTitle = document.querySelector('#page-title');
        if (pageTitle && pageTitle.parentElement) {
            setCurrentFilterContainer(pageTitle.parentElement);
        }
    }, []);

    if (isLoading) {
        return <div ref={spinnerContainer} className='flex flex-wrap gap-[24px]'>
            {isLoading ? <Spinner targetRef={spinnerContainer} className='rounded-12' /> : null}
        </div>
    }

    if ((!data.schedule || !data.schedule.length) && !filteredData.schedule) {
        return <EmptyContent icon="schedule" text="Currently, there are no available schedules for your club. Please check back." />
    }

    const items = filteredData.schedule || data.schedule || [];
    const timestamp = filteredData.timestamp || data.timestamp;

    const generateTimes = (item, href, hasBracket) => <a
        href={href}
        target="_blank"
        className="flex flex-col cursor-pointer"
    >
        <span className="w-[130px] max-w-[130px] flex justify-between items-center gap-8 mb-8 last:mb-0">
            {`${item.times.join`, `}${hasBracket ? ', Bracket' : ''}`}
            <Icon className="shrink-0 fill-title w-20 h-20" name="arrow_right" />
        </span>
    </a>;

    const generateVenue = item => <span
        onClick={() => {
            if (item.latitude && item.longitude) {
                navigateByCoords({
                    latitude: item.latitude,
                    longitude: item.longitude,
                });
            }
        }}
        key={item.id}
        className="flex items-center gap-8 cursor-pointer"
    >
        {item.name}
        {item.latitude && item.longitude
            ? <Icon name='geolocation' className='shrink-0 !w-20 !h-20 fill-title' />
            : null}
    </span>;

    return <div className='flex flex-wrap gap-[24px]'>
        <div className="flex flex-col flex-1">
            {timestamp
                ? <div className="flex gap-8">
                    <span className="text-label font-semibold">Last modified:</span>
                    <span className="text-label">{moment(timestamp).format('MMM DD h:mm A z')}</span>
                </div>
                : null}
            <div className="flex flex-col flex-1 mt-8">
                {enabled
                    ? <div className="flex bg-card-default rounded-16 items-center justify-center border border-border gap-24 p-20 mb-40">
                        <Icon name="construction" className="fill-title" />
                        <span className="font-bold font-accent text-l text-title">
                            Custom schedule is coming soon
                        </span>
                        <Icon name="construction" className="fill-title" />
                    </div>
                    : null}
                {items.length
                    ? <div className="rounded-6 border border-border flex-1">
                        <table className="table-fixed min-w-full text-left text-xs text-content">
                            <thead className="border-b border-border text-label font-semibold bg-card-default">
                                <tr className="[&>th]:px-8 [&>th]:py-[7px] [&>th:first-child]:pl-16 [&>th:last-child]:pr-16">
                                    <th scope="col" className="text-center w-[40px]">Event</th>
                                    <th scope="col" className="min-w-[55px]">Date</th>
                                    <th scope="col" className="min-w-[170px]">Team name</th>
                                    <th scope="col" className="min-w-[170px]">Division</th>
                                    <th scope="col" className="min-w-[100px] max-w-[130px]">Game times</th>
                                    <th scope="col" className="min-w-[200px]">Venue</th>
                                </tr>
                            </thead>
                            <tbody className="[&_.hovering:hover]:bg-card-default [&_td]:align-top leading-xs">
                                {items.map(item => {
                                    const venueGamesLength = item.venueGames.length;
                                    const timesHref = `${item.eventUrl}?team=${item.id}`;
                                    return <React.Fragment key={item.id}>
                                        <tr className={`${venueGamesLength > 1 ? '' : 'border-b'} border-border last:border-0 [&>td>*]:p-8 [&>td:first-child>*]:pl-16 [&>td:last-child]:pr-16`}>
                                            <td rowSpan={venueGamesLength} className="hovering">
                                                <a href={item.eventUrl} target="_blank"
                                                   className="flex justify-center w-full cursor-pointer">
                                                    <Icon className="fill-title" name="popout"/>
                                                </a>
                                            </td>
                                            <td rowSpan={venueGamesLength} className="hovering">
                                                <p className='whitespace-nowrap'>{item.formattedGameDay}</p>
                                            </td>
                                            <td rowSpan={venueGamesLength} className="hovering">
                                                <LinkWithQuery
                                                    to={`/teams/${item.team_id}`}
                                                    className="flex items-center gap-4 cursor-pointer"
                                                >
                                                    <div className="shrink-0 w-24 h-24 rounded-12 overflow-hidden">
                                                        {item.logo
                                                            ? <img
                                                                title={generateTitle(item.grade, item.gender_name)}
                                                                alt='Team logo'
                                                                className='w-24 h-full object-cover'
                                                                src={item.logo}
                                                            />
                                                            : <GradientLogo
                                                                title={generateTitle(item.grade, item.gender_name)}
                                                                text={item.grade}
                                                                gender={item.gender}
                                                            />
                                                        }
                                                    </div>
                                                    <span className="text-accent-color items-center font-bold">
                                                        {item.full_name}
                                                    </span>
                                                </LinkWithQuery>
                                            </td>
                                            <td rowSpan={venueGamesLength} className="max-w-[146px] hovering">
                                                <a
                                                    href={`${item.eventUrl}?division=${item.divisionId}`}
                                                    target="_blank"
                                                    className="flex items-center gap-8 cursor-pointer break-all"
                                                >
                                                    {item.divisionName}
                                                    <Icon className="shrink-0 fill-title w-20 h-20" name="arrow_right"/>
                                                </a>
                                            </td>
                                            <td className="w-[146px] max-w-[146px] hovering">
                                                {generateTimes(item.venueGames.at(0), timesHref, item.hasBracket)}
                                            </td>
                                            <td className="hovering !align-middle">
                                                {generateVenue(item.venueGames.at(0))}
                                            </td>
                                        </tr>
                                        {venueGamesLength > 1
                                            ? item.venueGames.slice(1).map((venue, index) =>
                                                <tr key={`${item.id}-${venue.id}`} className={`${index === item.venueGames.length - 2 ? 'border-b' : ''} border-border last:border-0 [&>td>*]:p-8 [&>td:last-child]:pr-16`}>
                                                    <td className="w-[146px] max-w-[146px] hovering">
                                                        {generateTimes(venue, timesHref, item.hasBracket)}
                                                    </td>
                                                    <td className="hovering !align-middle">
                                                        {generateVenue(venue)}
                                                    </td>
                                                </tr>
                                            )
                                            : null}
                                    </React.Fragment>
                                })}
                            </tbody>
                        </table>
                    </div>
                    : <ScheduleNoMatches />
                }
            </div>
        </div>
    </div>;
}

export default ScheduleDesktopLayout;
