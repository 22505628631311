import { useRef, useState, useContext } from 'react';
import { useNode } from '@craftjs/core';

import FormImageUploadWrapper from '../../common/FormImageUploadWrapper';
import ModalHeader from '../../common/Modal/ModalHeader';
import ModalContent from '../../common/Modal/ModalContent';
import ModalFooter from '../../common/Modal/ModalFooter';
import CustomInput from '../../common/Controls/CustomInput';
import GalleryItem from './GalleryItem';
import { SettingsContext } from '../../../context';

import {
    getMergedItems,
    handleAddOneMoreItem,
    handleChangeItemsOrder,
    handleCloseModal,
    handleDeleteItem,
    handleSetItemValue,
    parseTemplateValues,
    dispatchChange,
} from '../../../utils';
import { useOnChange } from "../../../hooks";
import { prefixByName } from "../../../config";
import { DEFAULT_PREFIX_DIVIDER } from "../../../const";

const DEFAULT_ITEM = { img: '', altText: '', title: '' };

const GalleryModalSettings = ({ onSave }) => {
    const {
        props: { uuid, items, title },
        actions: { setProp, setCustom },
    } = useNode(node => ({ props: node.data.props }));

    const settings = useContext(SettingsContext);
    const [isLoading, setLoading] = useState(false);
    const [newTitle, setNewTitle] = useState(parseTemplateValues(title, settings));
    const [newItems, setNewItems] = useState(items.map(item => {
        const newItem = { ...item };
        newItem.img = parseTemplateValues(newItem.img, settings)
        newItem.altText = parseTemplateValues(newItem.altText, settings);
        newItem.title = parseTemplateValues(newItem.title, settings);
        return newItem;
    }));

    const ref = useRef(null);
    const changeRef = useRef(null);
    const isChanged = useOnChange(changeRef);

    const handleClose = () => handleCloseModal(setCustom);

    const handleAddOneMore = () => {
        dispatchChange(changeRef);
        handleAddOneMoreItem(
            setNewItems,
            DEFAULT_ITEM,
            `${prefixByName.Gallery}${DEFAULT_PREFIX_DIVIDER}${uuid}`,
            { element: ref.current,
        });
    }

    const handleChangeOrder = (index, newIndex) => {
        dispatchChange(changeRef);
        handleChangeItemsOrder(setNewItems, index, newIndex);
    }

    const handleSave = async newSrc => {
        handleClose();
        if (isChanged) {
            setLoading(true);
            setProp(props => {
                props.items = newItems.map(item => ({
                    ...item,
                    img: newSrc[item.id] || item.img,
                }));
                props.title = newTitle;
            });
            await onSave();
            setLoading(false);
        }
    }

    return (
        <FormImageUploadWrapper
            parentIsLoading={isLoading}
            uploadCallback={handleSave}
            data={getMergedItems(items, newItems)}
            prevData={getMergedItems([], items)}
        >
            <ModalHeader title='Edit Gallery section' onClose={handleClose} />
            <ModalContent outerRef={changeRef}>
                <div className='w-full'>
                    <CustomInput
                        label='Add a section title'
                        onChange={setNewTitle}
                        value={newTitle}
                        className='mb-20 mt-24 mx-24'
                    />
                </div>
                <div className='flex justify-between border-t border-border overflow-hidden px-24 py-20'>
                    <div className='w-full grid grid-cols-3 grid-rows-[auto] gap-8'>
                        {newItems.map((item, index) =>
                            <GalleryItem
                                index={index}
                                isLast={index === newItems.length-1}
                                key={item.id}
                                handleSetValue={handleSetItemValue(setNewItems)}
                                handleDelete={id => {
                                    dispatchChange(changeRef);
                                    handleDeleteItem(setNewItems)(id);
                                }}
                                handleChangeOrder={handleChangeOrder}
                                template={settings.template}
                                {...item}
                            />,
                        )}
                        <div ref={ref} className='invisible'></div>
                    </div>
                </div>
            </ModalContent>
            <ModalFooter
                onClose={handleClose}
                additionalButton={{ text: 'Add Image', onClick: handleAddOneMore }}
                elementsCount={`${newItems.length} image${newItems.length > 1 ? 's': ''} added`}
            />
        </FormImageUploadWrapper>
    );
};

export default GalleryModalSettings;
