import { useContext, useEffect } from 'react';
import { useNode } from '@craftjs/core';
import Stories from 'react-insta-stories';

import GalleryModalSettings from './GalleryModalSettings';
import CommonHeading from '../../common/CommonHeading';
import { useMobile } from '../../../hooks';
import { parseTemplateValues } from '../../../utils';
import { SettingsContext } from '../../../context';

import defaultImage from '../../../assets/images/background_default.png';

const setStories = (items, settings) => {
    return items.map(({ img, altText, title }) => {
        const formattedImg = parseTemplateValues(img, settings);
        const formattedTitle = parseTemplateValues(title, settings);
        const formattedAltText = parseTemplateValues(altText, settings);
        return {
            content: () => (
                <div
                    key={img}
                    style={{ backgroundImage: `url(${formattedImg})` }}
                    className='relative w-full h-full bg-cover bg-center bg-no-repeat aspect-16/9'>
                    <div className='w-full h-full flex items-center justify-center backdrop-blur-lg'>
                        <img className='absolute w-full h-full object-contain aspect-16/9' src={formattedImg} alt={formattedAltText} />
                        <span
                            className='absolute bottom-40 left-20 text-m leading-h5 font-medium lg:font-semibold text-header_main drop-shadow shadow-black'>
                            {formattedTitle}
                        </span>
                    </div>
                </div>
            )
        }
    });
};

const Gallery = ({ title, items }) => {
    const { connectors: { connect }, actions: { setProp } } = useNode();
    const isMobile = useMobile();
    const settings = useContext(SettingsContext);
    const filteredItems = items.filter(item => item.img);

    // TODO: remove it in 6.0.1 release. This code need because test portals already configured with item.imgSrc
    // instead of item.img which is correct.
    useEffect(() => {
        if (items.some(item => item.hasOwnProperty('imgSrc'))) {
            setProp(props => (props.items = items.map(item => {
                if (item.hasOwnProperty('imgSrc')) {
                    const newItem = { ...item };
                    newItem.img = item.imgSrc;
                    delete newItem.imgSrc;
                    return newItem;
                }
                return item;
            })))
        }
    }, []);

    return (
        <div ref={connect} className='border-t border-b border-border'>
            <div className={`stories wrapper py-default_section_py lg:py-default_section_md_py
                ${isMobile ? '' : '[&>div:last-child>div:last-child>div]:!z-[2]'}
            `}>
                <CommonHeading className='break-words-custom' center craftComponent>{
                    parseTemplateValues(title, settings)}
                </CommonHeading>
                {filteredItems.length
                    ? isMobile
                        ? <div className='max-w-[100vw] rounded-16 overflow-hidden'>
                            <div className='snap-x flex width-full overflow-x-scroll no-scrollbar gap-8'>
                                {filteredItems.map(item => {
                                    const formattedAltText = parseTemplateValues(item.altText, settings);
                                    return (
                                        <div key={item.img} className={`snap-center h-[500px] rounded-16 overflow-hidden ${filteredItems.length > 1 ? 'min-w-[calc(90vw-32px)]' : 'min-w-full'}`}>
                                            <div className='relative w-full h-full flex items-center rounded-16 overflow-hidden'>
                                                <img
                                                    className='absolute w-full h-full object-cover'
                                                    src={parseTemplateValues(item.img, settings)}
                                                    alt={formattedAltText}
                                                />
                                                <span className='absolute bottom-40 left-20 text-m leading-h5 font-medium lg:font-semibold text-header_main drop-shadow shadow-black'>
                                                    {formattedAltText}
                                                </span>
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>
                        : <Stories
                            className="test"
                            progressContainerStyles={{ bottom: '17px', zIndex: 30 }}
                            storyContainerStyles={{
                                borderRadius: '16px',
                                overflow: 'hidden',
                                boxShadow: '0px 11px 40px 0px rgba(0, 0, 0, 0.25)'
                            }}
                            stories={setStories(filteredItems, settings)}
                            defaultInterval={10000}
                            width='100%'
                            height='100%'
                            loop
                        />
                    :
                    <p>Please add some images to gallery</p>
                }
            </div>
        </div>
    )
};

Gallery.craft = {
    displayName: 'Gallery',
    props: {
        title: 'Idea for section name — Our photo gallery',
        items: [{
            id: 1,
            img: defaultImage,
            altText: 'Example image',
            title: 'Example image',
        }],
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: GalleryModalSettings,
    },
};

export default Gallery;
