import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Icon from '../../../assets/images/icons';
import { TeamDataContext } from '../../../context';
import { useMobile } from '../../../hooks';
import GradientLogo from "../../common/GradientLogo";

const ICONS_DATA = {
    array: 'DETAILS',
    styles: 'absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 fill-details-icons-color',
}
const FIELDS_DATA = {
    organization_name: { text: 'Organization', icon: 'organization' },
    gender_name: { text: 'Gender', icon: 'gender' },
    grade_level_name: { text: 'Grade', icon: 'grade' },
    head_coach_name: { text: 'Head Coach', icon: 'head' },
    assistant_coach_name: { text: 'Assistant Coach', icon: 'assistant' },
    abbreviation: { text: 'Team Abbreviation', icon: 'abbreviation' },
    type_name: { text: 'Team Type', icon: 'team_type' },
};

export default function TeamDetails() {
    const data = useContext(TeamDataContext);
    const isMobile = useMobile();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMobile) {
            const newPath = pathname.replace('details', 'schedule') + search;
            navigate(newPath, { replace: true });
        }
    }, [isMobile]);

    return (
        <div className='px-8 lg:px-0 flex flex-col items-center lg:items-start'>
            <div className='w-[240px] h-[240px] p-20 mx-auto mb-32'>
                {data.logo
                    ? <img src={data.logo} className='w-full h-full object-cover rounded-full' alt='Team Big Logo' />
                    : <GradientLogo gender={data.gender} text={data.grade} className="!text-[64px] rounded-full" />}
            </div>
            {Object.keys(FIELDS_DATA).map(key => {
                const { text, icon } = FIELDS_DATA[key];
                return <div key={key} className='flex py-14 border-b border-border self-stretch last:border-b-transparent'>
                    <div className='relative bg-block-bg w-40 h-40 rounded-40 mr-16'>
                        <Icon name={icon} arr={ICONS_DATA.array} className={ICONS_DATA.styles} />
                    </div>
                    <div>
                        <p className='text-label leading-label text-content'>{text}</p>
                        <p className='text-s leading-standard text-title'>{data[key] || '-'}</p>
                    </div>
                </div>;
            })}
        </div>
    )
}
