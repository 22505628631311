import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Editor, Frame, Element } from '@craftjs/core';

import { RenderNode } from './components/craftJS/RenderNodes';
import { Viewport } from './components/craftJS/viewport';
import AppWrapper from "./components/craftJS/AppWrapper";

import { AppContext, AuthContext, SettingsContext } from './context';
import ApiClient from './api';
import { CraftJSResolver } from './config';
import Spinner from "./components/common/Spinner";
import { initModals } from "./utils";

const App = () => {
    const [appState, setAppState] = useState({
        modal: null,
        toast: {},
        init: process.env.NODE_ENV === 'development' });
    const [isLoading, setLoading] = useState(false);
    const [authState, setAuthState] = useState({
        publishedContent: window.pageContent?.published?.content || '',
        draftContent: window.pageContent?.draft?.content || '',
    });
    const [settings, setSettings] = useState(window.portalSettings || {});

    useEffect(() => {
        initModals(setAppState);
    }, []);

    useEffect(() => {
        if (appState.init) {
            const init = async () => {
                setLoading(true);
                await Promise.all([
                    ApiClient.post({ action: 'onGetPageContent' }),
                    ApiClient.post({ action: 'onGetPortalSettings' }),
                ])
                    .then(([content, settings]) => {
                        if (content) {
                            setAuthState(prevState => ({
                                publishedContent: content.published?.content || prevState.publishedContent,
                                draftContent: content.draft?.content || '',
                            }))
                        }
                        if (settings) {
                            setSettings(settings);
                        }

                    })
                    .catch(() => {})
                    .finally(() => {
                        setAppState(prevState => ({
                            ...prevState,
                            init: false,
                        }))
                        setLoading(false);
                    });
            };
            init();
        }
    }, [appState.init]);

    useEffect(() => {
        if (settings.configuration) {
            const root = document.getElementById('root');
            root.classList = settings.configuration.darkTheme ? 'dark' : 'light';
        }
    }, [settings.configuration?.darkTheme])

    useEffect(() => {
        const root = document.documentElement;
        if (settings.configuration?.accentColor) {
            root.style.setProperty('--accent-color', settings.configuration?.accentColor)
        }
    }, [settings.configuration?.accentColor])

    if (isLoading) {
        return <div className="h-[100vh] w-[100vw] py-16">
            {/* style here because tailwind not parsed className from this part for any reasons which idk */}
            <Spinner className="!rounded-none" style={{ borderRadius: 0 }} />
        </div>
    }

    return <AppContext.Provider value={{ appState, setAppState }}>
        <AuthContext.Provider value={{ ...authState, setAuthState }}>
            <SettingsContext.Provider value={{ ...settings, setSettings }}>
                <Editor resolver={CraftJSResolver} enabled={false} onRender={RenderNode}>
                    <BrowserRouter>
                        <Viewport>
                            <Frame>
                                <Element is={AppWrapper} />
                            </Frame>
                        </Viewport>
                    </BrowserRouter>
                </Editor>
            </SettingsContext.Provider>
        </AuthContext.Provider>
    </AppContext.Provider>
};

export default App;
