import React, { useRef, useState } from "react";

import Spinner from "../Spinner";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import CustomInput from "../Controls/CustomInput";
import { DEFAULT_PHONE_MASK_PROPS } from "../../../const";
import CustomTextarea from "../Controls/CustomTextarea";
import Button from "../Buttons/Button";
import ApiClient from "../../../api";

const StaffMessage = ({ onClose, name }) => {
    const [modalLoading, setLoading] = useState(false);
    const formRef = useRef(null);

    const onSubmit = async event => {
        event.preventDefault();

        const formData = new window.FormData(event.target);
        formData.append('title', `Message for ${name}`);
        const pageName = window.location.pathname.slice(1);
        formData.append('page', pageName);
        const content = Object.fromEntries(formData);

        setLoading(true);
        const thenCallback = () => {
            formRef.current.reset();
            onClose();
        }
        const finallyCallback = () => setLoading(false);

        ApiClient.onPostRequest({
            action: 'onSendMessage',
            content,
            thenCallback,
            finallyCallback,
        });
    }

    return <form ref={formRef} onSubmit={onSubmit} className="flex flex-col max-h-screen lg:max-h-[calc(100vh_-_110px)]">
        {modalLoading ? <Spinner/> : null}
        <ModalHeader
            titleClassName="!text-content"
            title={`Message for ${name}`}
            onClose={onClose}
        />
        <ModalContent className="flex flex-col gap-20 p-[20px_24px_32px]">
            <p className="text-content">
                You can write and send a message using the form below. Remember that the recipient cannot
                answer you directly but only to the contacts you leave below.
            </p>
            <div className='flex flex-col lg:flex-row gap-16 lg:gap-24'>
                <div className='w-full lg:basis-1/3 flex flex-col justify-between gap-16 lg:gap-24'>
                    <CustomInput
                        name='name'
                        placeholder='Full Name'
                        pattern="^(?:[.\-' a-zA-Z0-9]+)$"
                        title='Letters, digits, spaces and symbols .-" only'
                        minLength='2'
                        maxLength='50'
                        required
                    />
                    <CustomInput
                        name='email'
                        placeholder='Email Address'
                        pattern="^(?:(.{1,256}@.{1,256}\..{1,100})+)$"
                        title='Example: email@example.com'
                        required
                    />
                    <CustomInput
                        name='phone'
                        placeholder='Phone Number (optional)'
                        title='Phone number must contain only digits: (123) 456-7890'
                        {...DEFAULT_PHONE_MASK_PROPS}
                    />
                </div>
                <div className="lg:basis-2/3 w-full">
                    <CustomTextarea
                        name='message'
                        placeholder='Your Message'
                        className='h-full'
                        maxLength='1000'
                        required
                    />
                </div>
            </div>
        </ModalContent>
        <div className='flex-none flex gap-12 items-center px-24 py-20 z-1200'>
            <Button type="submit">send</Button>
            <Button onClick={onClose} buttonStyle='transparent' className="mr-12">
                Cancel
            </Button>
        </div>
    </form>
};

export default StaffMessage;
