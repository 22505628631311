import React, { useRef } from "react";

import Button from '../common/Buttons/Button';
import Accordion from '../common/Accordion';
import LinkWithQuery from "../common/LinkWithQuery";
import Spinner from "../common/Spinner";
import { EmptyContent } from "../common/EmptyContent";

import { useFetch } from "../../hooks";
import defaultImg from '../../assets/images/person_default.png'
import { handleModalOpen } from "../../utils";
import { modalTypes } from "../../config";

export default function Staff() {
    const [data, isLoading] = useFetch('coachesData');
    const containerRef = useRef(null);

    return (
        <div className='mt-[8px] bg-page-bg pb-default_section_py lg:pb-default_section_md_py text-content'>
            <div className='wrapper relative'>
                <div ref={containerRef}>
                    {isLoading
                        ? <Spinner targetRef={containerRef} />
                        : data.length
                            ? data.map(({id, first_name, last_name, bio, avatar, teams, title}) => {
                                const name = `${first_name} ${last_name}`;
                                return (
                                    <div key={id}
                                         className='flex flex-col md:flex-row text-s md:text-m leading-standard mb-32 gap-16 lg:gap-32 last:mb-0'>
                                        <div
                                            className='w-full md:w-[30%] md:min-w-[300px] md:max-w-[350px] h-full md:h-[334px] rounded-16 overflow-hidden shrink-0 grow-0'>
                                            <img
                                                className='w-full h-full object-cover aspect-square md:aspect-auto'
                                                src={avatar || defaultImg}
                                                alt={name}
                                            />
                                        </div>
                                        <div className='w-full'>
                                            <div
                                                className='flex flex-col md:flex-row justify-between items-center md:items-baseline pt-32 pb-24 md:pt-0 md:pb-32 gap-16 md:gap-24'>
                                                <div className="flex flex-col gap-8">
                                                    <h2 className="font-accent-font font-bold text-h4 leading-h4 md:leading-[44px]">
                                                        {name}
                                                    </h2>
                                                    <p className="text-content text-s text-center md:text-left">{title}</p>
                                                </div>
                                                <Button
                                                    buttonStyle='transparent'
                                                    size='big'
                                                    className='w-full md:w-[161px] md:min-w-[161px] !px-[21px]'
                                                    onClick={() => {
                                                        handleModalOpen({
                                                            modalType: modalTypes.staffMessage,
                                                            data: { name },
                                                        });
                                                    }}
                                                >
                                                    Send a Message
                                                </Button>
                                            </div>
                                            {bio && <Accordion
                                                title='Bio'
                                                sectionStyle='border-t border-b border-border'
                                                headerStyle='b-none py-18 text-h5 leading-h5 text-title font-accent-font font-semibold'
                                                contentStyle='b-none pb-18'
                                            >
                                                <pre
                                                    className='font-main-font text-s lg:text-m leading-standard text-content whitespace-pre-wrap'>{bio}</pre>
                                            </Accordion>}
                                            {teams.length
                                                ? <Accordion
                                                    title='Teams'
                                                    sectionStyle={`${!bio && 'border-t'} border-b border-border`}
                                                    headerStyle='b-none py-18 text-h5 leading-h5 text-title font-accent-font font-semibold'
                                                    contentStyle='b-none pb-18'
                                                >
                                                    <div
                                                        className='grid grid-rows-[1fr] grid-cols-[1fr] lg:grid-cols-[1fr_1fr_1fr] gap-y-[4px] lg:gap-y-[16px] gap-x-[24px] text-accent-color text-s leading-standard font-semibold'>
                                                        {teams.map(team =>
                                                            <LinkWithQuery to={`/teams/${team.id}`} key={team.id}>
                                                                {team.name}
                                                            </LinkWithQuery>
                                                        )}
                                                    </div>
                                                </Accordion>
                                                : null}
                                        </div>
                                    </div>
                                )
                            })
                            : <EmptyContent icon='staff' text='At the moment, there is no data about Staff yet.'/>
                    }
                </div>
            </div>
        </div>
    )
}
