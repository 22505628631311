import React, { useEffect, useRef, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useNode, useEditor } from '@craftjs/core';
import { v4 as uuid } from "uuid";

import SwitchButton from '../common/Buttons/SwitchButton';
import Icon from '../../assets/images/icons';
import { handleModalClose, handleModalOpen, handleSaveDraft } from "../../utils";
import { AuthContext } from "../../context";
import { DEFAULT_PREFIX_DIVIDER } from "../../const";
import { iconColorType, modalTypes, prefixByName } from "../../config";
import ApiClient from "../../api";

export const RenderNode = ({ render }) => {
    const { id, props, actions: { setProp } } = useNode(node => ({ props: node.data.props }));
    const { actions, query, isActive, enabled } = useEditor((state, query) => ({
        isActive: query.getEvent('selected').contains(id),
        enabled: state.options.enabled,
    }));
    const { setAuthState } = useContext(AuthContext);
    const {
        actions: { setCustom },
        isEmpty,
        isHover,
        dom,
        name,
        movable,
        deletable,
        parent,
        isHidden,
        isHideTopbar,
        isMayBeHidden,
        isMayBeDeleted,
        isMayBeMoved,
        isCanBeEditable,
    } = useNode((node) => {
        return {
            isEmpty: node.data.name === 'Empty',
            isHover: node.events.hovered,
            dom: node.dom,
            name: node.data.custom.displayName || node.data.displayName,
            movable: query.node(node.id).isDraggable(),
            deletable: query.node(node.id).isDeletable(),
            node: query.node(node.id).get(),
            parent: node.data.parent,
            nodeProps: node.data.props,
            isSettingShow: node.data.custom.isSettingShow,
            isHideTopbar: node.data.custom.isHideTopbar,
            isMayBeHidden: node.data.custom.isMayBeHidden,
            isMayBeDeleted: node.data.custom.isMayBeDeleted,
            isMayBeMoved: node.data.custom.isMayBeMoved,
            isHidden: node.data.custom.isHidden,
            isCanBeEditable: !!node.related?.modal,
        }
    });
    const [hidden, setHidden] = useState(isHidden);
    const currentRef = useRef();

    useEffect(() => {
        if (dom) {
            if (isActive || isHover) dom.classList.add('component-selected');
            else dom.classList.remove('component-selected');
        }
    }, [dom, isActive, isHover]);

    useEffect(() => {
        if (!props.uuid) {
            setProp(props => (props.uuid = uuid()));
        }
        // this code need for changing default id of section items, because we need uniq id to create image on backed
        // with this name to handle of changing and deletion images
        if (props.items && Array.isArray(props.items)) {
            setProp( props => {
                props.items.forEach((item, index) => {
                    if (!String(item.id).includes(DEFAULT_PREFIX_DIVIDER)) {
                        const newItem = { ...item };
                        newItem.id = `${prefixByName[name]}${DEFAULT_PREFIX_DIVIDER}${props.uuid}${DEFAULT_PREFIX_DIVIDER}${uuid().slice(0, 13)}`;
                        props.items[index] = newItem;
                    }
                });
            });
        }
    }, []);

    const actionWrapper = async (e, callback) => {
        e.stopPropagation();
        callback();
        await handleSaveDraft(query, content => setAuthState(prevState => ({
            ...prevState,
            draftContent: content,
        })));
    };

    const handleShowDeleteModal = e => {
        e.stopPropagation();
        handleModalOpen({
            modalType: modalTypes.confirm,
            data: {
                title: 'Remove section',
                titleIconType: iconColorType.warning,
                contentText: 'Confirm to remove this section',
                onConfirm: async () => await ApiClient.onPostRequest({
                    action: 'onRemoveSectionImages',
                    content: { section: uuid },
                    thenCallback: async () => {
                        actions.delete(id);
                        await handleSaveDraft(
                            query,
                            content => setAuthState(prevState => ({
                                ...prevState,
                                draftContent: content,
                            })),
                            () => handleModalClose(),
                        );
                    },
                    catchCallback: handleModalClose,
                }),
            }})
    }

    const isShowTopbar = !isHideTopbar && (isHover || isActive) && (isMayBeHidden || isCanBeEditable ||
        (isMayBeDeleted && deletable) || (isMayBeMoved && movable));

    return <div className={`relative w-full flex flex-col flex-1${isEmpty ? ' h-full' : ''}`}>
        {isShowTopbar
            ? ReactDOM.createPortal(
                <div className='absolute top-0 left-0 w-full border-t border-edit-color'>
                    <div
                        className='renderNode font-main-font text-header-footer-text
                       absolute top-0 left-1/2 -translate-x-1/2 flex items-center h-[48px] text-14 bg-header-bg z-40 border border-aside-border rounded-b-[12px]'
                        ref={currentRef}>
                        {/* checkbox VISIBLE*/}
                        {isMayBeHidden && (
                            <SwitchButton
                                title={`${hidden ? 'Show' : 'Hide'} section in publish mode`}
                                label='Visible'
                                containerClass='px-12 h-full'
                                checked={!hidden}
                                onChange={e => actionWrapper(e, () => {
                                    setHidden(!hidden);
                                    setCustom(custom => {
                                        custom.isHidden = !hidden;
                                    })
                                })}
                            />
                        )}
                        {/* Edit button */}
                        <div className="h-full border-x border-aside-border px-16 flex gap-16">
                            {isCanBeEditable && <button
                                title="Show edit modal"
                                onClick={() => setCustom(custom => {
                                    custom.isSettingShow = true;
                                })}
                            >
                                <Icon name='edit' className='fill-header-footer-text' />
                            </button>}
                            {/* Delete button */}
                            {isMayBeDeleted && deletable && (<>
                                <button
                                    title="Remove this section"
                                    type='button'
                                    onMouseDown={handleShowDeleteModal}
                                >
                                    <Icon name='delete' className='fill-header-footer-text'/>
                                </button>
                            </>)}
                        </div>
                        {/* Move buttons*/}
                        {isMayBeMoved && movable &&
                            <div className='h-full px-16 flex gap-16'>
                                <button
                                    title="Move this section up"
                                    onMouseDown={e => actionWrapper(e, () => {
                                        const elements = query.node(parent).childNodes();
                                        const currentIndex = elements.indexOf(id) + 1;
                                        if (currentIndex !== 1) {
                                            actions.move(id, parent, currentIndex - 2);
                                        }
                                    })}
                                >
                                    <Icon name='arrow_up' className='fill-header-footer-text' />
                                </button>
                                <button
                                    title="Move this section down"
                                    onMouseDown={e => actionWrapper(e, () => {
                                        const elements = query.node(parent).childNodes();
                                        const currentIndex = elements.indexOf(id) + 1;
                                        if (currentIndex !== elements.length) {
                                            actions.move(id, parent, currentIndex + 1);
                                        }
                                    })}
                                >
                                    <Icon name='arrow_down' className='fill-header-footer-text'/>
                                </button>
                            </div>
                        }
                    </div>
                </div>,
                dom
            )
            : null}
        {hidden
            ? <div className={`z-30 w-full h-full opacity-50 bg-edit-hidden-wrapper ${enabled ? 'block' : 'hidden'}`}>
                {enabled ? render : null}
            </div>
            : render
        }
    </div>;
};
