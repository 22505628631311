import { useContext } from 'react';
import { useNode } from '@craftjs/core';

import { HeroModalSettings } from './HeroModalSettings';
import { useMobile } from '../../../hooks';
import { parseTemplateValues } from '../../../utils';
import { SettingsContext } from '../../../context';

import defaultBgImg from '../../../assets/images/background_default.png';

const HERO_GRADIENT = 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)';

const Hero = ({ title, bg, mobileBg, logo, mainText }) => {
    const { connectors: { connect } } = useNode();
    const isMobile = useMobile();
    const settings = useContext(SettingsContext);

    const parsedTitle = parseTemplateValues(title, settings);
    const parsedLogo = parseTemplateValues(logo, settings);
    const parsedMainText = parseTemplateValues(mainText, settings);
    const parsedBg = parseTemplateValues(isMobile ? mobileBg || bg : bg, settings);

    return (
        <div
            ref={connect}
            className="bg-cover bg-center bg-no-repeat w-full text-hero lg:min-h-[calc(100vw/(21/9))]"
            style={{ backgroundImage: `${HERO_GRADIENT}, url(${parsedBg || defaultBgImg})` }}
        >
            <div className='wrapper py-default_section_md_py h-full flex flex-col justify-center items-center lg:min-h-[inherit]'>
                {parsedLogo &&
                    <div className='overflow-hidden rounded-90 mb-[22px] lg:mb-32 w-[120px] lg:w-[160px] h-[120px] lg:h-[160px]'>
                        <img
                            className='w-full h-full object-cover'
                            src={parsedLogo}
                            alt="Section logo"
                        />
                    </div>
                }
                {parsedTitle &&
                    <h1 className='break-words-custom text-center font-accent-font font-bold lg:font-medium text-h3 lg:text-h1 leading-h3 lg:leading-h1 mb-24 lg:mb-32'>
                        {parsedTitle}
                    </h1>
                }
                {parsedMainText &&
                    <div className='quill-content break-words-custom px-24 text-center text-s leading-xs lg:text-l lg:leading-l lg:max-w-[60vw]'>
                        <div dangerouslySetInnerHTML={{ __html: parsedMainText }} />
                    </div>
                }
            </div>
        </div>
    );
};

Hero.craft = {
    displayName: 'Hero',
    props: {
        bg: defaultBgImg,
        mobileBg: defaultBgImg,
        logo: '',
        title: 'Section Title',
        mainText: 'This sample text is used here to demonstrate how your text will appear. Replace this text with your desired content in the settings of this section. It serves as a placeholder for customization.',
    },
    custom: {
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true,
        isHidden: false,
    },
    related: {
        modal: HeroModalSettings,
    },
};

export default Hero;
