import CustomQuillElement from '../../common/Controls/CustomQuillElement';
import CustomInput from '../../common/Controls/CustomInput';
import CustomUploadImage from '../../common/Controls/CustomUploadImage';
import Icon from '../../../assets/images/icons';

const Column = ({
    id,
    img,
    title,
    description,
    handleSetValue,
    handleDelete,
    handleChangeOrder,
    template,
    index,
    isLast,
}) => {
    return (
        <div className='flex w-full py-20 px-24 border-b last:border-none border-border'>
            <div className='flex basis-[47%] flex-col justify-between pr-24'>
                <CustomUploadImage
                    mayBeDeleted
                    template={template}
                    name={id}
                    title='Add a column image'
                    description='Click to Upload to add an image'
                    imgType="column"
                    imgSrc={img}
                    setImg={value => handleSetValue(value, id, 'img')}
                    cropperProps={{
                        aspectRatio: 1,
                        preferredSize: { width: 1296, height: 1296 }
                    }}
                />
                <CustomInput
                    label='Add a column title'
                    className='pt-24'
                    value={title}
                    onChange={value => handleSetValue(value, id, 'title')}
                />
            </div>
            <CustomQuillElement
                title='Add a column subtitle'
                value={description}
                wrapperClassName='pr-16 basis-[47%]'
                className='max-h-column_quill'
                onChange={value => handleSetValue(value, id, 'description')}
            />
            <div className='border-l border-border pl-16 flex flex-col justify-between items-center '>
                <button
                    title="Remove column"
                    type='button'
                    onClick={() => handleDelete(id)}
                >
                    <Icon name='close' />
                </button>
                <button
                    title="Move this column up"
                    onClick={() => handleChangeOrder(index, index-1)}
                    className='mt-auto mb-12'
                    disabled={!index}
                    type='button'
                >
                    <Icon className={!index && 'fill-icon-disabled'} name='arrow_up' />
                </button>
                <button
                    title="Move this column down"
                    onClick={() => handleChangeOrder(index, index+1)}
                    disabled={isLast}
                    type='button'
                >
                    <Icon className={isLast && 'fill-icon-disabled'}  name='arrow_down' />
                </button>
            </div>
        </div>)
};

export default Column;
