import { useState, useContext, useRef } from 'react';
import { useNode } from '@craftjs/core';

import FormModalSettings from './FormModalSettings';
import Button from '../../common/Buttons/Button';
import CustomInput from '../../common/Controls/CustomInput';
import CustomTextarea from '../../common/Controls/CustomTextarea';
import Spinner from '../../common/Spinner';
import CommonHeading from '../../common/CommonHeading';
import ApiClient from '../../../api';
import { parseTemplateValues } from '../../../utils';
import { SettingsContext } from '../../../context';
import { DEFAULT_PHONE_MASK_PROPS } from '../../../const';

const Form = ({ title, description }) => {
    const { connectors: { connect }} = useNode();
    const [isLoading, setLoading] = useState(false);
    const settings = useContext(SettingsContext);
    const formRef = useRef(null);

    const handleSubmit = async event => {
        event.preventDefault();

        const formData = new window.FormData(event.target);
        formData.append('title', title);
        const pageName = window.location.pathname.slice(1);
        formData.append('page', pageName);
        const content = Object.fromEntries(formData);

        setLoading(true);
        const thenCallback = () => {
            formRef.current.reset();
        }
        const finallyCallback = () => setLoading(false);

        ApiClient.onPostRequest({
            action: 'onSendMessage',
            content,
            thenCallback,
            finallyCallback,
        });
    }

    return (
        <div ref={connect}
             className='py-default_section_py lg:py-default_section_md_py border-t border-b border-border'>
            <div className='wrapper'>
                {isLoading ? <Spinner /> : null}
                <div className='lg:max-w-[65vw] mx-auto'>
                    {title && <CommonHeading className='break-words-custom' craftComponent textSection>{parseTemplateValues(title, settings)}</CommonHeading>}
                    {description && <div className='quill-content break-words-custom w-full text-s lg:text-m leading-standard text-content mb-40'>
                        <div dangerouslySetInnerHTML={{ __html: parseTemplateValues(description, settings)}} />
                    </div>}
                    {title && !description && <div className='h-[8px]'></div>}
                    <form ref={formRef} onSubmit={handleSubmit} className='flex flex-wrap'>
                        <div className='w-full lg:basis-1/2 lg:pr-12  flex flex-col justify-between'>
                            <CustomInput
                                name='name'
                                placeholder='Full Name'
                                className='mb-24'
                                pattern="^(?:[.\-' a-zA-Z0-9]+)$"
                                title='Letters, digits, spaces and symbols .-" only'
                                minLength='2'
                                maxLength='50'
                                required
                            />
                            <CustomInput
                                name='email'
                                placeholder='Email Address'
                                className='mb-24'
                                pattern="^(?:(.{1,256}@.{1,256}\..{1,100})+)$"
                                title='Example: email@example.com'
                                required
                            />
                            <CustomInput
                                name='phone'
                                className='mb-[24px] lg:mb-0'
                                placeholder='Phone Number (optional)'
                                title='Phone number must contain only digits: (123) 456-7890'
                                {...DEFAULT_PHONE_MASK_PROPS}
                            />
                        </div>
                        <div className="lg:basis-1/2 lg:pl-[12px] w-full">
                            <CustomTextarea
                                name='message'
                                placeholder='Your Message'
                                className='h-full'
                                maxLength='1000'
                                required
                            />
                        </div>
                        <Button type='submit' className='w-full lg:w-auto px-48 mt-40'>Send</Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

Form.craft = {
    displayName: 'Form',
    props: {
        title: 'Idea for section name — Join our team',
        description: 'This sample text is used here to demonstrate how your text will appear. Replace this text with your desired content in the settings of this section. It serves as a placeholder for customization.',
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: FormModalSettings,
    },
};

export default Form;
