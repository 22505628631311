import { useEffect, useRef, useState, useContext } from 'react';

import DropdownMenu from '../DropdownMenu';
import Navbar from './Navbar';
import Icon from '../../../assets/images/icons';

import { SettingsContext } from '../../../context';
import { useMobile, useScrollDirection } from '../../../hooks';
import { CRAFTJS_PAGES } from "../../../config";

const filterPages = (pages, hideArray = []) => Object.values(pages).filter(item => !hideArray.includes(item.key));

const Header = () => {
    const [burgerOpen, setBurgerOpen] = useState(false);
    const [burgerMenuPosition, setBurgerMenuPosition] = useState(0);

    const { auth, configuration, hiddenPages } = useContext(SettingsContext);
    const { phone, formattedPhone } = configuration || {};
    const ref = useRef();

    const isMobile = useMobile();
    const scrollDirection = useScrollDirection();

    useEffect(() => {
        setBurgerMenuPosition(ref.current.offsetHeight)
    }, []);

    return (
        <header className={`z-[1000] sticky ${ isMobile && scrollDirection === 'down' ? '-top-[72px]' : auth ? 'top-[48px]' :'top-0'} bg-header font-main-font`} ref={ref}>
            <div className='min-h-[72px] wrapper flex justify-between items-center'>
                <button onClick={() => setBurgerOpen(!burgerOpen)} className='outline-none lg:hidden'>
                    {burgerOpen ? <Icon name='close' className='fill-header_main' /> : <Icon name='burger' />}
                    <DropdownMenu
                        open={burgerOpen}
                        position={burgerMenuPosition}
                        onClose={() => setBurgerOpen(false)}
                        className='w-[320px] bg-header-bg'
                    >
                        <Navbar items={filterPages(CRAFTJS_PAGES, hiddenPages)} listStyles='flex-col' />
                    </DropdownMenu>
                </button>
                <Navbar items={filterPages(CRAFTJS_PAGES, hiddenPages)} listStyles='-ml-[20px] hidden lg:flex' />
                {/* TODO: show phone later */}
                {false && phone
                    ? <a href={`tel:${phone}`}
                        className='flex items-center my-14 px-20 py-[8.5px] text-header_main text-s leading-phone font-bold border-header_phone border-header_main rounded-80 whitespace-nowrap'
                    >
                        <Icon name='phone' className='fill-header-footer-text mr-[5px]' />
                        {formattedPhone || phone}
                    </a>
                    : null}
            </div>
        </header>
    );
};

export default Header;
