import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

function LinkWithQuery({ isNav, to, ...rest }) {
    const { search } = useLocation();
    const Component = isNav ? NavLink : Link;

    return <Component to={{ pathname: to, search }} {...rest} />
}

export default LinkWithQuery;