import React, { useState, useContext, useRef, useEffect } from 'react';

import ModalHeader from "../ModalHeader";
import ModalContent from "../ModalContent";
import ModalFooter from "../ModalFooter";
import CustomInput from "../../Controls/CustomInput";
import Divider from '../../Divider';
import { dispatchChange, getPhoneInputProps, handleSetItemValue, scrollIntoView } from "../../../../utils";
import FormImageUploadWrapper from "../../FormImageUploadWrapper";
import CustomColorPicker from "../../CustomColorPicker";
import CustomUploadImage from "../../Controls/CustomUploadImage";
import SwitchButton from "../../Buttons/SwitchButton";
import { SettingsContext } from "../../../../context";
import ApiClient from "../../../../api";
import { useOnChange } from "../../../../hooks";
import Icon from "../../../../assets/images/icons";
import Button from "../../Buttons/Button";
import Map from "./Map";

const DEFAULT_SOCIALS = [
    { url: '', name: 'Twitter', id: 'twitter' },
    { url: '', name: 'Instagram', id: 'instagram' },
    { url: '', name: 'Facebook', id: 'facebook' },
    { url: '', name: 'YouTube', id: 'youtube' },
    { url: '', name: 'TikTok', id: 'tiktok' },
];

const KEY_LOGO = 'settings_logo';
const KEY_FAVICON = 'settings_favicon';

const SettingsModal = ({ onClose, scrollToSocials }) => {
    const settings = useContext(SettingsContext);
    const config = settings.configuration;

    const [isLoading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(config.organization);
    const [phone, setPhone] = useState(config.formattedPhone);
    const [email, setEmail] = useState(config.email);
    const [accentColor, setAccentColor] = useState(config.accentColor);
    const [logo, setLogo] = useState(config.logo);
    const [favicon, setFavicon] = useState(config.favicon);
    const [darkTheme, setDarkTheme] = useState(config.darkTheme);
    const [hideStaff, setHideStaff] = useState(config.hideStaff);
    const [socials, setSocials] = useState(DEFAULT_SOCIALS.map(social => ({
        ...social,
        url: config[social.id] || social.url,
        visible: config[social.id + 'Visible'],
    })));

    const changeRef = useRef(null);
    const socialsRef = useRef(null);
    const isChanged = useOnChange(changeRef);

    useEffect(() => {
        if (scrollToSocials) {
            scrollIntoView({ element: socialsRef.current, block: 'start' });
        }
    }, [scrollToSocials]);

    const handleSave = async (event, croppedImages) => {
        event.preventDefault();
        if (isChanged) {
            setLoading(true);
            const parsedSocials = socials.reduce((acc, item) => {
                acc[item.id] = item.url;
                acc[item.id + 'Visible'] = item.visible;
                return acc;
            }, {});
            const formData = new window.FormData(event.target);

            const array = [...formData.entries()];
            const parsedData = array.reduce((acc, [name, file]) => {
                if (typeof file === 'object') {
                    if (file.size) {
                        acc[name] = new File([file], name, {
                            type: file.type,
                            lastModified: file.lastModified,
                        });
                    }
                } else {
                    acc[name] = file;
                }
                return acc;
            }, {});
            parsedData.logo = parsedData['custom' + KEY_LOGO] || croppedImages[KEY_LOGO] || parsedData[KEY_LOGO] || '';
            parsedData.favicon = parsedData['custom' + KEY_FAVICON] || croppedImages[KEY_FAVICON] || parsedData[KEY_FAVICON] || '';
            delete parsedData['custom' + KEY_LOGO];
            delete parsedData['custom' + KEY_FAVICON];
            delete parsedData[KEY_LOGO];
            delete parsedData[KEY_FAVICON];

            const config = {
                ...parsedData,
                hideStaff,
                darkTheme,
                accentColor,
                ...parsedSocials,
            }

            const thenCallback = newSettings => {
                settings.setSettings(newSettings);
            }
            const finallyCallback = () => setLoading(false);

            await ApiClient.onPostRequest({
                action: 'onManageConfiguration',
                content: { config },
                thenCallback,
                finallyCallback,
            });
        }
        onClose();
    }

    return <FormImageUploadWrapper
        customClassName={darkTheme ? 'dark bg-page-bg text-title' : 'light bg-white text-black'}
        onSubmit={handleSave}
        parentIsLoading={isLoading}
    >
        <ModalHeader title="Club Settings" onClose={onClose} />
        <ModalContent outerRef={changeRef}>
            <div className="p-24 flex gap-24">
                <div className="basis-2/5 bg-card-default px-24 pt-40">
                    <h4 className="text-h4 leading-h4 font-accent-font font-bold mb-40">Appearance</h4>
                    <div className="flex gap-16">
                        <h5 className="text-s flex-1">Dark theme</h5>
                        <SwitchButton
                            containerClass='basis-[36px]'
                            elementClass="w-[36px] h-[12px] rounded-[6px] bg-[#C4C4C4] peer-checked:bg-edit-color-light"
                            roundClass='after:left-0 peer-checked:after:left-[16px] after:bg-white peer-checked:after:bg-white'
                            label=''
                            checked={darkTheme}
                            onChange={() => setDarkTheme(prevState => !prevState)}
                        />
                    </div>
                    <Divider className="!border-[#5E5E5E] my-24" />
                    <div className="relative w-full" onDragStart={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <span className="text-label font-semibold mb-8">Accent color</span>
                        <CustomColorPicker
                            color={accentColor}
                            onChange={value => {
                                dispatchChange(changeRef);
                                setAccentColor(value.hex)
                            }}
                        />
                    </div>
                    <div className="flex flex-col mt-24">
                        <span className="text-label font-semibold mb-8">Chosen color example</span>
                        <div className="bg-page-bg flex flex-col gap-12 rounded-2 py-20 px-24">
                            <div className="relative bg-card-default w-44 h-44 rounded-44 flex items-center justify-center">
                                <Icon name="warning" style={{ fill: accentColor }} />
                            </div>
                            <span>
                                This text shows you how your <span style={{ color: accentColor }}>accent color</span> will look as a link according to the selected theme.
                            </span>
                            <Button tabIndex={-1} className="w-fit" size="small" style={{ backgroundColor: accentColor }}>
                                button
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="basis-3/5 pt-40 flex flex-col gap-24">
                    <h4 className="text-h4 leading-h4 font-accent-font font-bold">Organization info</h4>
                    <CustomUploadImage
                        template={settings.template}
                        name={KEY_LOGO}
                        title="Logo"
                        description="The image will be used as the logo on header page."
                        setImg={setLogo}
                        imgSrc={logo}
                        imgType="logo"
                        hideDeleteIcon
                        descriptionClassName="!text-content"
                        actionsInRow
                        cropperProps={{
                            aspectRatio: 1,
                            preferredSize: { width: 160, height: 160 }
                        }}
                    />
                    <CustomUploadImage
                        template={settings.template}
                        name={KEY_FAVICON}
                        title="FAV-icon"
                        accept=".png, .jpg, .jpeg, .ico"
                        description="The image will be used as FAV-icon for your Club Portal"
                        setImg={setFavicon}
                        imgSrc={favicon}
                        imgType="favicon"
                        hideDeleteIcon
                        descriptionClassName="!text-content"
                        actionsInRow
                        cropperProps={{
                            aspectRatio: 1,
                            preferredSize: { width: 64, height: 64 }
                        }}
                    />
                    <Divider className="!border-[#5E5E5E]" />
                    <CustomInput
                        label='Add an organization name'
                        placeholder="Type here"
                        onChange={setOrganization}
                        value={organization}
                        name="organization"
                    />
                    <div className="flex gap-24">
                        <CustomInput
                            className="flex-1"
                            label='Phone number'
                            value={phone}
                            name="phone"
                            onChange={setPhone}
                            {...getPhoneInputProps(settings.template)}
                        />
                        <CustomInput
                            className="flex-1"
                            label='Email'
                            value={email}
                            name="email"
                            onChange={setEmail}
                            placeholder="Type here"
                            pattern="^(?:(.{1,256}@.{1,256}\..{1,100})+)$"
                        />
                    </div>
                    <Map data={config} states={settings.states} countries={settings.countries} />
                    <Divider className="!border-[#5E5E5E]" />
                    <h4 ref={socialsRef} className="text-h4 leading-h4 font-accent-font font-bold">Socials</h4>
                    {socials.map(social =>
                        <CustomInput
                            key={social.id}
                            label={`Add ${social.name} link`}
                            placeholder="Type here"
                            onChange={value => handleSetItemValue(setSocials)(value, social.id, 'url')}
                            setVisible={value => handleSetItemValue(setSocials)(value, social.id, 'visible')}
                            visible={social.visible}
                            value={social.url}
                        />
                    )}
                    <Divider className="!border-[#5E5E5E]" />
                    <h4 ref={socialsRef} className="text-h4 leading-h4 font-accent-font font-bold">Pages configuration</h4>
                    <SwitchButton
                        elementClass="w-[36px] h-[16px] border-[2px] border-solid border-input-default-border peer-checked:border-toggle-active-bg bg-transparent rounded-[8px]"
                        roundClass='after:bg-input-default-border peer-checked:after:bg-toggle-active-bg'
                        elementAfterClass="after:h-[8px] after:w-[8px] after:top-2/4 after:-translate-y-2/4 after:left-[2px] peer-checked:after:left-[22px]"
                        label='Hide staff page'
                        checked={hideStaff}
                        onChange={() => setHideStaff(prevState => !prevState)}
                    />
                </div>
            </div>
        </ModalContent>
        <ModalFooter darkTheme={darkTheme} onClose={onClose} />
    </FormImageUploadWrapper>;
};

export default SettingsModal;
