import Icon from '../../../assets/images/icons';

const ModalHeader = ({ onClose, title, titleClassName, description }) => {
    return (
        <div className='px-24 py-20 bg-card-default z-1200'>
            {title &&
                <div className='flex justify-between items-center'>
                    <h3 className={`text-m leading-standard text-modal-text font-semibold ${titleClassName ?? ''}`}>
                        {title}
                    </h3>
                    <button type='button' onClick={onClose} className='ml-auto'>
                        <Icon name='close' className="fill-content" />
                    </button>
                </div>
            }
            {description &&
                <p className='text-16 mt-[10px] max-w-[75%]'>
                    {description}
                </p>
            }
        </div>
    )
};

export default ModalHeader;
