import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useEditor } from "@craftjs/core";

import Modal from "./index";
import { AuthContext } from "../../../context";
import { defer, handleSaveDraft } from "../../../utils";

const CraftModalContainer = ({ move, currentCraftNodeIdRef }) => {
    const { setAuthState } = useContext(AuthContext);
    const [children, setChildren] = useState(null);
    const childTimeoutIdRef = useRef(null);

    const { query, enabled, related, isSettingShow = false, currentlySelectedNodeId } = useEditor((state, query) => {
        const currentlySelectedNodeId = query.getEvent('selected').first();
        const actualNode = state.nodes[currentlySelectedNodeId] || state.nodes[currentCraftNodeIdRef.current];
        return {
            currentlySelectedNodeId,
            isSettingShow: !!actualNode && actualNode.data.custom.isSettingShow,
            related: !!actualNode && actualNode.related,
            enabled: state.options.enabled,
        };
    });

    useEffect(() => {
        if (currentlySelectedNodeId) {
            currentCraftNodeIdRef.current = currentlySelectedNodeId;
        }
        if (!enabled) {
            defer(() => {
                currentCraftNodeIdRef.current = null;
            }, 350);
        }
    }, [enabled, currentlySelectedNodeId]);

    useEffect(() => {
        if (related?.modal && currentCraftNodeIdRef.current && isSettingShow) {
            clearTimeout(childTimeoutIdRef.current);
            setChildren(React.createElement(related.modal, { onSave }));
        } else {
            childTimeoutIdRef.current = defer(() => {
                setChildren(null);
            }, 350);
        }
    }, [related?.modal, currentlySelectedNodeId, isSettingShow]);

    const onSave = useCallback(() => {
        handleSaveDraft(query, content => setAuthState(prevState => ({
            ...prevState,
            draftContent: content,
        })));
    }, []);

    return <Modal open={isSettingShow} move={move}>
        {currentlySelectedNodeId ? children : null}
    </Modal>
};

export default CraftModalContainer;
