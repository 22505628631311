export const DEFAULT_DIRECTION_LINK = 'https://maps.apple.com/maps?daddr=';

export const ERR_DEFAULT = 'Something went wrong!';

export const DEFAULT_PHONE_MASK_PROPS = {
    pattern: "\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}",
    mask: "(___) ___-____",
    accept: "\\d",
    slots: "_",
};

export const DEFAULT_PREFIX_DIVIDER = '|';
