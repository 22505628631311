import React, { useState, useRef, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import CustomInput from "../Controls/CustomInput";
import Icon from "../../../assets/images/icons";
import Button from "../Buttons/Button";
import ApiClient from "../../../api";
import { SettingsContext } from "../../../context";
import { defer } from "../../../utils";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const Datepicker = ({ minDate, enabled, filters, setFilteredData, setLoading }) => {
    const [value, setValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [datepickerOpen, setDatepickerOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { configuration } = useContext(SettingsContext);
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const minDateRef = useRef(moment(minDate));
    const maxDateRef = useRef(null);

    useEffect(() => {
        minDateRef.current = moment(minDate);
    }, [minDate]);

    useEffect(() => {
        defer(() => {
            if (inputRef.current) {
                    /* used this solution because it is easier to handle changing the open/close state and
                    clicking on the arrow image than using <Icon/> with absolute positioning
                      defer needed because after change theme useEffect trigger at the same time ass useEffect in App.jsx,
                    which change class based on new theme */
                const textColor = getComputedStyle(inputRef.current).getPropertyValue('--text-content');

                const svg = `<svg fill="${textColor}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                ${dropdownOpen
                    ? '<path d="M12 8.6249L18 14.6249L16.6 16.0249L12 11.4249L7.4 16.0249L6 14.6249L12 8.6249Z" fill="inherit"></path>'
                    : '<path d="M12 15.3751L18 9.3751L16.6 7.9751L12 12.5751L7.4 7.9751L6 9.3751L12 15.3751Z" fill="inherit"></path>'
                }
                </svg>`;

                inputRef.current.style.backgroundImage = `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`;
            }
        })
    }, [inputRef.current, dropdownOpen, configuration.darkTheme]);

    useEffect(() => {
        const handleClick = e => {
            if (!enabled) {
                // this class check needed because if user clicks to the last available month navigation button removes
                // and closeAll triggers
                if (!containerRef.current.contains(e.target) &&
                    !(e.target.classList.contains('react-datepicker__navigation') ||
                        e.target.classList.contains('react-datepicker__navigation-icon')))
                {
                    closeAll();
                }
            }
        };
        document.body.addEventListener('click', handleClick);
        return () => document.body.removeEventListener('click', handleClick);
    }, [enabled]);

    const setDefaultMinMaxDay = () => {
        minDateRef.current = moment(minDate);
        maxDateRef.current = null;
    }

    const closeAll = () => {
        setDropdownOpen(false);
        setShowDropdown(false);
        setDatepickerOpen(false);
    };

    const toggleView = () => {
        const newValue = !dropdownOpen;
        setDropdownOpen(newValue);
        setShowDropdown(newValue);
        newValue || setDatepickerOpen(newValue);
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start) {
            const threeMonthsBefore = moment(start).subtract(3, 'months');
            minDateRef.current = threeMonthsBefore.isBefore(minDateRef.current) ? minDateRef.current : threeMonthsBefore;
            maxDateRef.current = moment(start).add(3, 'months');
        } else {
            setDefaultMinMaxDay();
        }
    };

    const handleVariantsClick = ({ name, start, end }) => {
        setValue(name);
        closeAll();
        handleApiRequest({ start, end });
    }

    const handleClearDatepicker = () => {
        setStartDate(null);
        setEndDate(null);
        setValue('');
        setDefaultMinMaxDay();
        handleVariantsClick(filters.at(0));
    };

    const handleApplyDatepicker = () => {
        const formattedStart = moment(startDate).format('MM/DD/yyyy');
        const formattedEnd = moment(endDate).format('MM/DD/yyyy');
        setValue(`${formattedStart} - ${formattedEnd}`);
        closeAll();
        handleApiRequest({ start: formattedStart, end: formattedEnd });
    };

    const handleApiRequest = async content => {
        setLoading(true);
        await ApiClient.onPostRequest({
            action: 'onGetSchedule',
            content,
            thenCallback: setFilteredData,
            finallyCallback: () => setLoading(false),
        });
    }

    if (enabled) {
        return <div className="bg-card-default rounded-8 w-[300px] h-[40px]"></div>
    }

    return <div ref={containerRef} className="relative flex flex-1 h-[40px] justify-end lg:max-w-[300px]">
        <CustomInput
            outerRef={inputRef}
            value={value}
            onClick={toggleView}
            className="flex-1 !flex-row justify-end"
            inputClassName="!p-8 h-[40px] leading-[22px] flex-1 outline-0 bg-no-repeat bg-[calc(100%-8px)] cursor-pointer"
            placeholder="All future dates"
            readOnly
        />
        <div className={`light text-content w-full absolute top-48 bg-page-bg py-6 rounded-6 border border-border
            transition-all duration-200 z-[999] ${showDropdown ? 'opacity-1 max-h-[400px]' : '[&_*]:invisible p-0 opacity-0 max-h-0'}
            [&>div:hover]:bg-card-default`}
        >
            {(filters || []).map((item) =>
                <div
                    onClick={() => handleVariantsClick(item)}
                    className="cursor-pointer px-8 py-10"
                    key={item.name}
                >
                    {item.name}
                </div>,
            )}
            <div
                onClick={() => {
                    setShowDropdown(false);
                    setDatepickerOpen(true);
                }}
                className="relative cursor-pointer px-8 py-10"
            >
                Custom date range
                <Icon className="right-8 absolute fill-content top-1/2 -translate-y-1/2" name="arrow_right" />
            </div>
        </div>
        <div id="datepicker-container" className={`light w-full absolute top-48
            transition-all duration-200 z-[999] h-0 ${datepickerOpen ? 'opacity-1 max-h-[400px]' : 'opacity-0 max-h-0'}
        `}
        >
            {datepickerOpen
                ? <DatePicker
                    closeOnScroll
                    selected={startDate}
                    onChange={onChange}
                    minDate={minDateRef.current.toDate()}
                    maxDate={maxDateRef.current?.toDate()}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MM/dd/yyyy"
                    selectsRange
                    inline
                >
                    <div className="border-t border-border w-full flex gap-12 px-10 py-16">
                        <Button
                            onClick={handleClearDatepicker}
                            buttonStyle="transparent"
                            className="flex-1"
                            size="small"
                        >
                            clear
                        </Button>
                        <Button
                            disabled={!startDate || !endDate}
                            onClick={handleApplyDatepicker}
                            buttonStyle="black"
                            className="flex-1"
                            size="small"
                        >
                            apply
                        </Button>
                    </div>
                </DatePicker>
                : null}
        </div>
    </div>;
}

export default Datepicker;
