import { Route } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import lz from 'lzutf8';

import { DEFAULT_DIRECTION_LINK, DEFAULT_PHONE_MASK_PROPS, DEFAULT_PREFIX_DIVIDER } from './const';
import { settingsVariablesMap } from "./config";
import ApiClient from "./api";

export function shareData() {
    if (navigator.share) {
        navigator
            .share({ title: 'Share link', url: window.location.href })
            .catch(err => {
                if (err.name !== 'AbortError') {
                    toast.error(err.message);
                }
            });
    } else {
        toast.error("Browser doesn't support this API!");
    }
}

export function copyUrl() {
    navigator.clipboard.writeText(window.location.href)
        .then(function () {
            toast.success('Link copied');
        });
}
export function copyText(text, message) {
    navigator.clipboard.writeText(text)
        .then(function () {
            toast.success(message || 'Copied Successfully');
        });
}
export const areNumbersEqual = (number1, number2) => Number(number1) === Number(number2);

export const navigateByCoords = ({ latitude, longitude }) => {
    window.open(
        `${DEFAULT_DIRECTION_LINK}${latitude},${longitude}`,
        '_blank',
        'noopener,noreferrer',
    )
}

export const generateRoute = (data, hideArray = []) => data.map((item, index) =>
    hideArray.includes(item.path)
        ? null
        : <Route key={`${item.path}-${index}`} element={item.element} path={item.path} index={item.isIndex}>
            {item.children ? generateRoute(item.children, hideArray) : null}
        </Route>
);

export const handleDeleteItem = setNewItems => {
    return id => setNewItems(prevState => {
        const newState = [...prevState];
        const elementIndex = newState.findIndex(element => element.id === id);
        newState.splice(elementIndex, 1);
        return newState.slice(0);
    });
}
export const handleSetItemValue = setNewItems => {
    return (value, id, type) => setNewItems(prevState => {
        const state = [...prevState];
        const elementIndex = prevState.findIndex(element => element.id === id);

        const item = {...state[elementIndex]};
        item[type] = value;
        state[elementIndex] = item;
        return state;
    });
}
export const handleAddOneMoreItem = (setNewItems, defaultItem, idPrefix, scrollSettings) => {
    setNewItems(prevState => [...prevState, {
        ...defaultItem,
        id: `${idPrefix || ':error:'}${DEFAULT_PREFIX_DIVIDER}${uuid().slice(0, 13)}`,
    }]);
    scrollSettings && scrollIntoView(scrollSettings);
}

export const handleChangeItemsOrder = (setNewItems, index, newIndex) => {
    setNewItems(prevState => {
        const newState = [...prevState];
        newState[index] = newState.splice(newIndex, 1, newState[index])[0];
        return newState;
    });
}

export const handleCloseModal = setCustom => setCustom(custom => (custom.isSettingShow = false));

// returns a merge of prevItems and newItems, if some of prevItems was removed, it values will be equal to ''
export const getMergedItems = (prevItems, newItems) => {
    const allItems = {};
    prevItems.forEach(item => {
        allItems[item.id] = '';
    });
    newItems.forEach(item => {
        allItems[item.id] = item.img;
    });
    return allItems;
}

export const defer = (callback, ms = 0) => setTimeout(callback, ms)

export const scrollIntoView = ({ element, behavior = 'smooth', block = 'end' }) =>
    defer(() => element?.scrollIntoView({ behavior, block }));

export const stopScrolling = open => {
    defer(() => {
        document.body.classList[open ? 'add' : 'remove']('overflow-hidden');
    }, 50);
}
export const deserializeCraftContent = (content, deserialize) => {
    if (content) {
        try {
            const json = lz.decompress(lz.decodeBase64(content));
            deserialize(json);
        } catch (e) {}
    }
}

export const capitalizeString = string => string.split`/`
    .map(part => part.at(0).toUpperCase() + part.slice(1).toLowerCase())
    .join`/`;

const TEMPLATE_REGEXP = /{%([^%]+)%}/g;

export const parseTemplateValues = (string, settings) => {
    return settings.template
        ? string
        : string?.replace(TEMPLATE_REGEXP, (match, capturedValue) => {
            const lowerCaseValue = capturedValue.trim().toLowerCase();
            const value = settingsVariablesMap[lowerCaseValue];
            return settings.configuration[value] || '';
        }) || '';
}

export const getPhoneInputProps = template => template ? {} : DEFAULT_PHONE_MASK_PROPS;

export const dispatchChange = ref => {
    const changeEvent = new Event('change', { bubbles: true });
    ref.current?.dispatchEvent(changeEvent);
}

export const handleSaveDraft = async (query, thenCallback, finallyCallback) => {
    const json = query.serialize();
    const content = lz.encodeBase64(lz.compress(json));
    await ApiClient.onPostRequest({
        action: 'onSavePageContent',
        content: { content },
        thenCallback: () => thenCallback(content),
        finallyCallback,
    });
}

let globalSetAppState;

const wrapperModal = setAppState => {
    if (!globalSetAppState && setAppState) {
        globalSetAppState = setAppState;
    }
    return callback => globalSetAppState(callback);
}
export const handleModalClose = () => {
    wrapperModal()(prevState => ({
        ...prevState,
        modal: null,
    }));
};

export const handleModalOpen = (props = {}) => {
    const { onAccept, onClose, data, modalType } = props;
    wrapperModal()(prevState => ({
        ...prevState,
        modal: {
            modalType,
            onAccept,
            onClose: () => {
                if (onClose) {
                    onClose();
                }
                handleModalClose();
            },
            ...data,
        }
    }));
};

export const initModals = setAppState => wrapperModal(setAppState);

export const generateTitle = (gradeLevel, genderName) => `Grade: ${gradeLevel || '-'} Gender: ${genderName || '-'}`;

export const filterStatesByCountry = ({ states, countries, countryId, countryName }) => {
    const parsedCountryName = Object.entries(countries).find(([name, id]) => areNumbersEqual(id, countryId)) || [];
    return Object.entries(states).reduce((acc, [key, item]) => {
        if (item.countries.includes(countryName || parsedCountryName.at(0))) {
            acc[key] = item.id;
        }
        return acc;
    }, {});
};

/* google maps loaders */
let mapLibraryLoaded = false;
let markerLibraryLoaded = false;
let MapEl, MarkerEl;

export async function loadGoogleMapsLibrary() {
    if (!mapLibraryLoaded && window.google?.maps) {
        const library = await window.google.maps.importLibrary("maps");
        MapEl = library.Map;
        mapLibraryLoaded = true;
    }
    return MapEl;
}

export async function loadGoogleMarkersLibrary() {
    if (!markerLibraryLoaded && window.google?.maps) {
        const library = await window.google.maps.importLibrary("marker");
        MarkerEl = library.AdvancedMarkerElement;
        markerLibraryLoaded = true;
    }
    return MarkerEl;
}
