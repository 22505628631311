import React, { useState, useEffect } from 'react';
import { useEditor } from '@craftjs/core';
import ReactDOM from "react-dom";

import { useFetch, useMobile } from '../../hooks';
import VenueMobileLayout from '../layouts/VenueMobileLayout';
import VenueDesktopLayout from '../layouts/VenueDesktopLayout';
import { VenuesDataContext } from '../../context';
import Filter from '../common/Filter';

const Venues = () => {
    const isMobile = useMobile();
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
    const [data = [], isLoading] = useFetch('venuesData', { editMode: enabled });
    const [venues, setVenues] = useState([]);
    const [filteredVenues, setFilteredVenues] = useState(data.venues || []);
    const [currentFilterContainer, setCurrentFilterContainer] = useState(null);

    useEffect(() => {
        const dataArray = Array.isArray(data) ? data : [];
        setVenues(dataArray);
        setFilteredVenues(dataArray);
    }, [data]);

    return <VenuesDataContext.Provider value={{
        venues,
        setVenues,
        filteredVenues,
        isLoading,
        enabled,
    }}>
        {currentFilterContainer && ReactDOM.createPortal(
            <Filter
                enabled={enabled}
                filterParams={['name', 'city']}
                setFilteredValue={setFilteredVenues}
                data={venues}
                placeholder="Filter venues by keyword"
            />,
            currentFilterContainer,
        )}
        <div className='bg-page-bg min-h-[400px] py-[8px] pb-32 lg:py-[16px] text-title font-main-font'>
            <div className='wrapper'>
                {isMobile
                    ? <VenueMobileLayout setCurrentFilterContainer={setCurrentFilterContainer} />
                    : <VenueDesktopLayout setCurrentFilterContainer={setCurrentFilterContainer} />}
            </div>
        </div>
    </VenuesDataContext.Provider>;
};

export default Venues;
