import { useEffect, useState } from 'react';

const Spinner = ({ targetRef, className, ...rest }) => {
    const [isShow, setShow] = useState(false);
    useEffect(() => {
        const currentRef = targetRef?.current;
        if (currentRef) {
            currentRef.style.setProperty('position', 'relative');
            currentRef.style.setProperty('min-height', '400px');
        }
        setShow(true);
        return () => {
            if (currentRef) {
                currentRef.style.removeProperty('position');
                currentRef.style.removeProperty('min-height');
            }
            setShow(false);
        }
    }, [targetRef]);

    return isShow
        ? <div
            className={`absolute flex top-0 left-0 z-[100] rounded-[12px] backdrop-blur-[3px] flex-wrap items-center bg-spinner-container justify-center w-full h-full ${className ?? ''}`}
            {...rest}
        >
            <span className="h-24 inline-block border-[24px] bg-black border-solid rounded-half animate-spinner border-spinner " />
        </div>
        : null;
}

export default Spinner;
