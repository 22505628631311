import React from "react";

import SwipeContainer from "../SwipeContainer";
import Icon from "../../../assets/images/icons";
import VenueListViewItem from "../../page-venues/components/VenueListViewItem";

const VenueMobileBackdrop = ({ onClose, filteredVenues, slideCurrentIndex }) =>
    <SwipeContainer customClassName="bg-page-bg" down={onClose}>
        <div onClick={e => e.stopPropagation()} className='bg-page-bg p-24 h-[80vh] relative flex flex-col'>
            <div className="flex justify-end mb-24">
                <Icon onClick={onClose} name='close' className='fill-title' />
            </div>
            {filteredVenues.at(slideCurrentIndex) &&
                <div className="h-[calc(100%-48px)] flex flex-wrap overflow-y-auto">
                    <VenueListViewItem
                        item={filteredVenues.at(slideCurrentIndex)}
                        navigateButtonClass="!min-h-[44px] !overflow-visible sticky bottom-0"
                    />
                </div>}
        </div>
    </SwipeContainer>;

export default VenueMobileBackdrop;
