import defaultPhoto from '../../../assets/images/person_default.png';

export default function TeamRosterItem({avatarUrl, fullName, number, position}) {
    return (
        <div className='flex py-12 pr-16 pl-8 text-title items-center first:border-t lg:first:border-t-0 border-b border-border'>
            <div className='mr-16 w-40 h-40 shrink-0 rounded-40 overflow-hidden'>
                <img src={avatarUrl || defaultPhoto} alt={fullName} className='object-cover w-full h-full'/>
            </div>
            <span className='inline-block w-[32px] mr-16 font-accent-font text-h5 leading-h5 font-semibold'>{number}</span>
            <p className='flex flex-col lg:flex-row justify-between w-full'>
                <span className={`text-s leading-standard lg:mb-0 ${position ? ' mb-4' : '' }`}>{fullName}</span>
                <span className='lg:pl-16 text-label lg:text-xs leading-label lg:leading-xs font-semibold'>{position}</span>
            </p>
        </div>
    )
};
