import React, { useRef, useState, useEffect, useContext } from "react";
import Slider from "react-slick";

import infoBannerImage from '../../assets/images/info-banner-image.png';
import Button from "./Buttons/Button";
import Icon from "../../assets/images/icons";
import { SettingsContext } from "../../context";

const DEFAULT_SLIDES_DATA = [
    {
        title: 'Customer Portal activation',
        text: 'To begin using your Club Portal, please select a unique URL/subdomain name for your club website and select a subscription plan. You can start for free using the Freemium subscription.',
    },
    // {
    //     title: 'Customer Portal activation',
    //     text: 'In order to specify your unique Club Portal URL and manage the content the Club Portal requires activation. It can be activated in your Account Settings.',
    // }
];

const DEFAULT_LINK_VALUES = ['activation', 'landing'];
const DEFAULT_LINK_NAMES = ['activate portal', 'read more'];

const WIDTH_TABLET = 768 - 32;
const WIDTH_DESKTOP = 1024 - 32;

const SamplePrevArrow = (props) => {
    const { className, style, onClick, isPrev } = props;
    return (
        <div
            className={`${className} slick-${isPrev ? 'prev' : 'next'}`}
            style={style}
            onClick={onClick}
        >
            <Icon name={isPrev ? 'arrow_left' : 'arrow_right'} />
        </div>
    );
}

const InfoBanner = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const containerRef = useRef(null);
    const { links } = useContext(SettingsContext);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef?.current?.clientWidth < WIDTH_TABLET) {
                containerRef.current.classList.add('mobile');
                containerRef.current.classList.remove('tablet');
                containerRef.current.classList.remove('desktop');
            } else if (containerRef?.current?.clientWidth < WIDTH_DESKTOP) {
                containerRef.current.classList.remove('mobile');
                containerRef.current.classList.add('tablet');
                containerRef.current.classList.remove('desktop');
            } else {
                containerRef?.current?.classList?.remove('mobile');
                containerRef?.current?.classList?.remove('tablet');
                containerRef?.current?.classList?.add('desktop');
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return <div ref={containerRef} id="info-banner-wrapper" className="relative mb-32 flex flex-col rounded-16">
        <div id="info-banner-container" className="rounded-t-[inherit] text-color-black bg-white pt-12 flex flex-1">
            <div id="info-banner-content" className="w-full max-w-full grid">
                <img width="100%" className="h-[210px]" alt="info-banner" src={infoBannerImage} />
                <div
                    className="flex-1 max-w-full overflow-hidden my-auto bg-gradient-to-l from-[21%] from-[#E4B459] to-[#CE9E42]"
                    id="slick-banner"
                >
                    <Slider
                        ref={sliderRef}
                        dots={false}
                        arrows={DEFAULT_SLIDES_DATA.length > 1}
                        slidesToShow={1}
                        infinite={false}
                        focusOnSelect={true}
                        speed={500}
                        cssEase='ease-out'
                        prevArrow={<SamplePrevArrow isPrev />}
                        nextArrow={<SamplePrevArrow isPrev={false} />}
                        afterChange={setCurrentIndex}
                    >
                        {DEFAULT_SLIDES_DATA.map(({ title, text}, index) =>
                            <div key={index} className="!flex flex-col flex-1 gap-16">
                                <h3 className='text-[24px] font-main-font text-black leading-130 font-bold'>{title}</h3>
                                <p className='text-[#1E1E1E] text-20 leading-[30px]'>{text}</p>
                            </div>)}
                    </Slider>
                </div>
            </div>
        </div>
        <div id="info-banner-button-container" className="rounded-b-[inherit] pb-[32px] pt-0 p-20 bg-gradient-to-l from-[21%] from-[#E4B459] to-[#CE9E42]">
            <Button
                size="small"
                buttonStyle="black"
                className="w-fit"
                onClick={() => {
                    const key = DEFAULT_LINK_VALUES[currentIndex];
                    const value = links[key];
                    value && window.open(value, '_blank');
                }}
            >
                {DEFAULT_LINK_NAMES[currentIndex]}
            </Button>
        </div>
    </div>
}

export default InfoBanner;
