import { useState, useContext} from 'react';
import { useNode } from '@craftjs/core';

import ColumnsModalSettings from './ColumnsModalSettings';
import Icon from '../../../assets/images/icons';
import Button from '../../common/Buttons/Button';

import { parseTemplateValues } from '../../../utils';
import defaultImage from '../../../assets/images/background_default.png';
import CommonHeading from '../../common/CommonHeading';
import { SettingsContext } from '../../../context';
import { useMobile } from '../../../hooks';

const Columns = ({ title, description, items }) => {
    const isMobile = useMobile();
    const { connectors: { connect } } = useNode(node => ({ props: node.data.props }));
    const [viewAll, setViewAll] = useState(false);
    const settings = useContext(SettingsContext);

    const filteredItems = isMobile
        ? items.filter(({ title, img, description }) => img || title || description)
        : items;

    return (
        <div className='wrapper w-full py-default_section_py lg:py-default_section_md_py' ref={connect}>
            <div className='lg:max-w-[65vw] mx-auto'>
                {title &&
                    <CommonHeading className='break-words-custom' craftComponent textSection>{parseTemplateValues(title, settings)}</CommonHeading>
                }
                {description &&
                    <div className='quill-content break-words-custom w-full text-s lg:text-m leading-standard text-content mb-40'>
                        <div dangerouslySetInnerHTML={{ __html: parseTemplateValues(description, settings)}} />
                    </div>
                }
                {title && !description && <div className='h-[8px]'></div>}
            </div>
            <div className='flex flex-wrap gap-24'>
                {filteredItems.length ?
                    filteredItems.map(({ id, title, img, description }, index) =>
                        <div
                            key={id}
                            className={`${index > 2 && !viewAll ? 'hidden lg:block ' : ''}grow basis-full md:basis-[calc(25%_-_18px)]`}
                        >
                            <div className='h-full'>
                                {img &&
                                    <div className='rounded-16 overflow-hidden'>
                                        <img src={parseTemplateValues(img, settings)}
                                             alt={parseTemplateValues(title, settings)}
                                             className='w-full h-[330px] lg:h-[400px] object-cover'/>
                                    </div>
                                }
                                {(title || description) &&
                                    <div className='mt-24 lg:mt-32'>
                                        {title &&
                                            <h3 className={`break-words-custom text-m leading-standard font-bold ${description && 'mb-24'}`}>
                                                {parseTemplateValues(title, settings)}
                                            </h3>
                                        }
                                        {description &&
                                            <div className='quill-content break-words-custom text-content text-s leading-standard'>
                                                <div dangerouslySetInnerHTML={{ __html: parseTemplateValues(description, settings)}} />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    )
                    : <p>Please add some columns</p>
                }
                {filteredItems.length > 3
                    ? <Button
                        onClick={() => setViewAll(prevValue => !prevValue)}
                        buttonStyle='transparent'
                        size='expanded'
                        className='mt-40 lg:hidden w-full'
                    >
                        {viewAll ? 'View less' : 'View more'}
                        <Icon className='ml-4 fill-title inline-block' name={viewAll ? 'arrow_up' : 'arrow_down'}/>
                    </Button>
                    : null
                }
            </div>
        </div>
    );
};

Columns.craft = {
    displayName: 'Columns',
    props: {
        title: 'Idea for section name — We are proud of ...',
        description: 'This exemplary text serves as a template to illustrate the appearance of your customized content. This section can be effectively utilized to describe various aspects, such as your organization\'s mission, values, or key offerings. Keep in mind that a concise yet informative approach enhances the visual appeal of this section. Craft your text thoughtfully to succinctly convey the most crucial information, ensuring it resonates effectively with your audience. Replace this placeholder text with your specific content through the customization options provided in this section, creating a compelling narrative that aligns with your goals and resonates with your target audience.',
        items: [
            {
                id: 1,
                img: defaultImage,
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear. Replace this text with your desired content in the settings of this section. It serves as a placeholder for customization.',
            },
            {
                id: 2,
                img: defaultImage,
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear. Replace this text with your desired content in the settings of this section. It serves as a placeholder for customization.',
            },
            {
                id: 3,
                img: defaultImage,
                title: 'This is title example',
                description: 'This sample text is used here to demonstrate how your text will appear. Replace this text with your desired content in the settings of this section. It serves as a placeholder for customization.',
            },
        ],
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: ColumnsModalSettings,
    },
};

export default Columns;
