import CustomUploadImage from '../../common/Controls/CustomUploadImage';
import CustomInput from '../../common/Controls/CustomInput';

const Partner = ({
    id,
    index,
    isLast,
    img,
    url,
    altText,
    handleSetValue,
    handleDelete,
    template,
    handleChangeOrder,
}) => {
    const changeOrderButtonsSettings = {
        index: index,
        isLast: isLast,
        onMoveElementForward: () => handleChangeOrder(index, index + 1),
        onMoveElementBack: () => handleChangeOrder(index, index - 1),
    }

    return (
        <div className='w-full flex flex-col p-16 border rounded-4'>
            <CustomUploadImage
                template={template}
                changeOrderButtonsSettings={changeOrderButtonsSettings}
                onDeleteParentElement={() => handleDelete(id)}
                name={id}
                imgType='partner'
                title='Tile Partner’s logo'
                imgSrc={img}
                setImg={value => handleSetValue(value, id, 'img')}
                cropperProps={{
                    aspectRatio: 222 / 118,
                    preferredSize: { width: 444, height: 336 }
                }}
            />
            <CustomInput
                label='Image alt text'
                className='pb-20 mt-16'
                placeholder='Type alt text'
                value={altText}
                onChange={value => handleSetValue(value, id, 'altText')}
            />
            <CustomInput
                label='URL (optional)'
                placeholder='Paste URL'
                value={url}
                onChange={value => handleSetValue(value, id, 'url')}
            />
        </div>)
};

export default Partner;
