import { useState, useContext } from 'react';
import { useNode } from '@craftjs/core';

import ContactsModalSettings from './ContactsModalSettings';
import CommonHeading from '../../common/CommonHeading';
import Icon from '../../../assets/images/icons';
import Button from '../../common/Buttons/Button';

import { copyText, parseTemplateValues } from '../../../utils';
import { SettingsContext } from '../../../context';

const Contacts = ({ items, title, description }) => {
    const { connectors: { connect } } = useNode(node => ({ props: node.data.props }));
    const [viewAll, setViewAll] = useState(false);
    const settings = useContext(SettingsContext);

    return (
        <div className='wrapper w-full' ref={connect}>
            <div className='py-default_section_py lg:py-default_section_py text-m text-content leading-standard'>
                <div className='lg:max-w-[65vw] mx-auto'>
                    {title && <CommonHeading className='break-words-custom' craftComponent textSection>{parseTemplateValues(title, settings)}</CommonHeading>}
                    {description && <div className='quill-content mb-40 break-words-custom'>
                        <div dangerouslySetInnerHTML={{ __html: parseTemplateValues(description, settings)}} />
                    </div>}
                    {title && !description && <div className='h-[8px]'></div>}
                </div>
                <div className='flex flex-wrap gap-24'>
                    {items.length
                        ? items.map((item, index) => {
                            const parsedPhone = parseTemplateValues(item.main_phone, settings);
                            const parsedEmail = parseTemplateValues(item.email, settings);
                            return <div
                                key={item.id}
                                className={`${index > 3 && !viewAll ? 'hidden lg:block ' : ''}grow basis-full lg:basis-[calc(25%_-_18px)] max-w-[100%] overflow-hidden`}
                            >
                                <div
                                    className='flex flex-col justify-center h-full text-center border border-border rounded-8 p-16 bg-page-second-bg'>
                                    {item.name &&
                                        <h3 className='break-words-custom text-title'>
                                            {parseTemplateValues(item.name, settings)}
                                        </h3>
                                    }
                                    {parsedPhone &&
                                        <a href={`tel:${parsedPhone}`}
                                           className='mt-8 whitespace-nowrap text-content text-s leading-standard '>
                                            {parsedPhone}
                                        </a>
                                    }
                                    {parsedEmail &&
                                        <p className='mt-8 flex justify-center items-center max-w-full overflow-hidden'>
                                        <a href={`mailto:${parsedEmail}`}
                                           title={parsedEmail}
                                           className='mr-[6px] max-w-[100%_-_26px] overflow-hidden text-content text-s leading-standard overflow-ellipsis'
                                        >
                                            {parsedEmail}
                                        </a>
                                            <button
                                                type='button'
                                                className='shrink-0 w-[20px] h-[20px]'
                                                onClick={()=>{copyText(parsedEmail, 'Email copied.')}}>
                                                <Icon name='copy' className='fill-title' />
                                            </button>
                                        </p>
                                    }
                                </div>
                            </div>
                        })
                        : <p>Please add some contacts to section</p>
                    }
                    {items.length > 4 &&
                        <Button
                            onClick={() => setViewAll(prevValue => !prevValue)}
                            buttonStyle='transparent'
                            size='expanded'
                            className='mt-40 lg:hidden w-full'>
                            {viewAll ? 'View less' : 'View more'}
                            <Icon className='ml-4 fill-title inline-block' name={viewAll ? 'arrow_up' : 'arrow_down'} />
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
};

Contacts.craft = {
    displayName: 'Contacts',
    props: {
        title: 'Idea for section name — Our contacts',
        description: 'This sample text is used here to demonstrate how your text will appear',
        items: [
            {
                id: 1,
                name: 'Name of Contact',
                main_phone: '{% company formatted phone %}',
                email: '{% company email %}',
            },
        ],
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: ContactsModalSettings,
    },
};

export default Contacts;
