import { useEffect } from 'react';

import { stopScrolling } from '../../../utils';

const DIRECTION_STYLES = (open, direction) => {
    switch (direction) {
        case 'top':
            return open ? 'translate-y-0' : '-translate-y-[120%]';
        case 'left':
        default:
            return open ? 'translate-x-0' : '-translate-x-[100%]';
    }
}
const DropdownMenu = ({ open, onClose, children, direction, position, className }) => {
    useEffect(() => {
        stopScrolling(open);
    }, [open])

    return (
        <div
            style={{ top: position }}
            className={`${DIRECTION_STYLES(open, direction)} transition-all absolute w-screen h-screen  left-0 z-[999] overflow-y-scroll backdrop-blur-[3px]`}
            onClick={onClose}
        >
            <div className={`absolute h-screen ${className ?? ''}`}>
                {children}
            </div>
        </div>
    );
}

export default DropdownMenu;
