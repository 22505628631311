import { useContext } from 'react';
import { useNode } from '@craftjs/core';
import Slider from 'react-slick';

import PartnersModalSettings from './PartnersModalSettings';
import CommonHeading from '../../common/CommonHeading';
import { SettingsContext } from '../../../context';

import { parseTemplateValues } from '../../../utils';

import defaultBgImg from '../../../assets/images/background_default.png';
import TP_logo from '../../../assets/images/demonstration/partners/TP_logo.png';
import ODS_logo from '../../../assets/images/demonstration/partners/ODS_logo.png';

const ImageWithLink = ({ url, settings, img, altText }) => {
    const imageUrl = img ? parseTemplateValues(img, settings) : defaultBgImg;
    const imageAlt = parseTemplateValues(altText, settings);
    const Wrapper = url ? 'a' : 'span';
    const wrapperProps = url ? { href: parseTemplateValues(url, settings), target: '_blank', rel: 'noreferrer' } : {};

    return (
        <Wrapper {...wrapperProps} className='flex items-center justify-center w-full h-full'>
            <img src={imageUrl} alt={imageAlt} className={`w-full rounded-6 aspect-[222/118]${img ? '' : ' object-cover'}`} />
        </Wrapper>
    );
};

const Partners = ({ title, items }) => {
    const { connectors: { connect } } = useNode(node => ({ props: node.data.props }));
    const settings = useContext(SettingsContext);

    const sliderSettings = {
        dots: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 5,
        swipeToSlide: true,
        arrows: false,
        centerMode: true,
        autoplay: true,
        infinite: items.length > 5,
        centerPadding: '25px',
        responsive: [
            {
                breakpoint: 1411,
                settings: {
                    slidesToShow: 4,
                    infinite: items.length > 4,
                },
            },
            {
                breakpoint: 1173,
                settings: {
                    slidesToShow: 3,
                    infinite: items.length > 3,
                },
            },
            {
                breakpoint: 791,
                settings: {
                    slidesToShow: 2,
                    infinite: items.length > 2,
                },
            },
            {
                breakpoint: 553,
                settings: {
                    slidesToShow: 1,
                    infinite: items.length > 1,
                },
            }
        ],
    };

    return (
        <div className='partners-slider py-default_section_py lg:py-default_section_md_py w-full' ref={connect}>
            {title
                ? <div className='wrapper'>
                    <CommonHeading className='break-words-custom' center craftComponent>
                        {parseTemplateValues(title, settings)}
                    </CommonHeading>
                </div>
                : null}
            <div className='wrapper overflow-hidden'>
                <div>
                    {items.length
                        ? <Slider
                            className={items.length <= 5 ? '[&_.slick-track]:justify-center' : ''}
                            {...sliderSettings}
                        >
                            {items.map(({ id, img, url, altText }) =>
                                <div
                                    key={id}
                                    className='flex justify-center mx-8 w-full min-w-[222px] min-h-[118px] bg-white rounded-6'
                                >
                                    <ImageWithLink img={img} url={url} altText={altText} settings={settings} />
                                </div>
                            )}
                            </Slider>
                        : null}
                </div>
            </div>
        </div>
    );
};

Partners.craft = {
    displayName: 'Partners',
    props: {
        title: 'Idea for section name — Our Partners',
        items: [
            {
                id: 'partners_default_1',
                img: TP_logo,
                altText: 'TourneyPro',
                url: 'https://tourneypro.com/',
            },
            {
                id: 'partners_default1_2',
                img: ODS_logo,
                altText: 'One day Shootouts',
                url: 'https://onedayshootouts.com/categories',
            },
        ],
    },
    custom: {
        isHidden: false,
        isSettingShow: false,
        isMayBeHidden: true,
        isMayBeDeleted: true,
        isMayBeMoved: true
    },
    related: {
        modal: PartnersModalSettings,
    },
};

export default Partners;
