import { useEffect, useRef, useId } from 'react';
import Icon from "../../../assets/images/icons";
import SwitchButton from "../Buttons/SwitchButton";

const textStyles = 'text-s leading-standard text-title placeholder-input-placeholder font-normal font-main-font';
const borderStyles = 'border border-input-default-border rounded-input focus:border-title hover:border-title focus-visible:border-title focus-visible:outline-0';
const bgStyles = 'bg-input-default-bg focus:bg-input-active-bg disabled:bg-input-disabled-bg';

const CustomInput = ({
    outerRef,
    label,
    value,
    disabled,
    name,
    placeholder,
    onChange = () => {},
    className,
    inputClassName,
    type,
    visible,
    setVisible,
    withClearIcon,
    ...other
}) => {
    const id = useId();
    const innerRef = useRef(null);
    const ref = outerRef || innerRef;

    useEffect(() => {
        if (other.mask) {
            const currentRef = ref.current;
            const pattern = other.mask;
            const slots = new Set(other.slots || '_');
            const prev = (index =>
                Array.from(pattern, (symbol, i) => slots.has(symbol) ? index = i + 1 : index)
            )(0);
            const first = [...pattern].findIndex(c => slots.has(c));
            const accept = new RegExp(other.accept || '\\d', 'g');
            let back = false;

            const clean = input => {
                input = input.match(accept) || [];
                return Array.from(pattern, c =>
                    input[0] === c || slots.has(c) ? input.shift() || c : c
                );
            };
            const onFormat = () => {
                const [i, j] = [currentRef.selectionStart, currentRef.selectionEnd].map(i => {
                    i = clean(currentRef.value.slice(0, i)).findIndex(c => slots.has(c));
                    return i < 0 ? prev[prev.length - 1] : back ? prev[i - 1] || first : i;
                });
                const newValue = clean(currentRef.value).join``;
                currentRef.value = newValue;
                onChange(newValue);
                currentRef.setSelectionRange(i, j);
                back = false;
            };
            const onKeyDown = (e) => back = e.key === 'Backspace'
            const onKeyBlur = () => {
                if (currentRef.value === pattern) {
                    currentRef.value = '';
                    onChange('');
                }
            }
            currentRef.addEventListener('keydown', onKeyDown);
            currentRef.addEventListener('input', onFormat);
            currentRef.addEventListener('focus', onFormat);
            currentRef.addEventListener('blur', onKeyBlur);
            return () => {
                if (currentRef) {
                    currentRef.removeEventListener('keydown', onKeyDown);
                    currentRef.removeEventListener('input', onFormat);
                    currentRef.removeEventListener('focus', onFormat);
                    currentRef.removeEventListener('blur', onKeyBlur);
                }
            }
        }
    }, []);

    return (
        <label htmlFor={id} className={`flex flex-col relative ${className ?? ''}`}>
            {label || setVisible
                ? <div className="flex mb-8 gap-16">
                    {label ? <p className='text-label leading-label font-semibold'>{label}</p> : null}
                    {setVisible
                        ? <SwitchButton
                            containerClass='ml-auto'
                            labelClass='!text-label !mr-4'
                            elementClass="w-[24px] h-[12px] border-[2px] border-solid border-input-default-border peer-checked:border-toggle-active-bg bg-transparent rounded-[6px]"
                            roundClass='after:bg-input-default-border peer-checked:after:bg-toggle-active-bg'
                            elementAfterClass="after:h-[6px] after:w-[6px] after:top-2/4 after:-translate-y-2/4 after:left-[2px] peer-checked:after:left-[12px]"
                            label='Visible'
                            checked={visible}
                            onChange={() => setVisible(!visible)}
                        />
                        : null}
                </div>
                : null}
            <input
                ref={ref}
                id={id}
                name={name}
                disabled={disabled}
                type={type || 'text'}
                value={value}
                placeholder={placeholder || 'Type here'}
                onChange={event => onChange ? onChange(event.target.value) : null}
                className={`${textStyles} ${borderStyles} ${bgStyles} px-input-x-p py-input-y-p ${inputClassName ?? ''}`}
                {...other}
            />
            {withClearIcon && value.length
                ? <Icon
                    name='close'
                    className='absolute pointer top-1/2 fill-[#9E9E9E] -translate-y-1/2 right-8'
                    onClick={e => {
                        e.preventDefault();
                        onChange('');
                    }}
                />
                : null}
        </label>
    )
}

export default CustomInput;

