import React,  { Fragment, useState, useContext, useRef, useEffect } from 'react';
import { useEditor } from '@craftjs/core';
import { toast } from 'react-toastify';

import ApiClient from '../../api';
import { AppContext, AuthContext } from '../../context';
import { deserializeCraftContent, handleModalOpen, handleModalClose, defer } from '../../utils';
import { useMobile, useScrollDirection } from '../../hooks';

import Button from '../common/Buttons/Button';
import SwitchButton from '../common/Buttons/SwitchButton';
import DropdownMenu from '../common/DropdownMenu';
import Icon from '../../assets/images/icons';
import { ERR_DEFAULT } from '../../const';
import { modalTypes } from '../../config';

export const EditHeader = ({ onEdit, editor, menu, permissions, upgradeLink }) => {
    const { enabled, actions } = useEditor(state => ({ enabled: state.options.enabled }));
    const [isEdit, setEditMode] = useState(false);
    const [isCustomerMenuOpen, setCustomerMenuOpen] = useState(false);
    const [customerMenuPosition, setCustomerMenuPosition] = useState(0);
    const { setAppState } = useContext(AppContext);
    const { setAuthState, publishedContent } = useContext(AuthContext);
    const isMobile = useMobile();
    const scrollDirection = useScrollDirection();
    const isMobileAfterDesktopRef = useRef(null);

    const ref = useRef();

    useEffect(() => {
        setCustomerMenuPosition(ref.current.offsetHeight)
    }, []);

    useEffect(() => {
        if (isMobile && isEdit) {
            setEditMode(false);
            actions.setOptions(options => {
                options.enabled = false;
            });
            isMobileAfterDesktopRef.current = true;
        } else if (isMobileAfterDesktopRef.current) {
            setEditMode(true);
            actions.setOptions(options => {
                options.enabled = true;
            });
            isMobileAfterDesktopRef.current = false;
        }
    }, [isMobile]);

    const toggleCustomerMenu = () => {
        setCustomerMenuOpen(!isCustomerMenuOpen);
    }

    const handlePublish = () => {
        handleModalOpen({
            modalType: modalTypes.confirm,
            data: {
                submitButtonText: 'publish',
                title: 'Publish changes',
                contentText: 'All the changes in the draft will be published. Are you sure you want to proceed?',
                onConfirm: () => {
                    ApiClient.onPostRequest({
                        action: 'onPublish',
                        thenCallback: response => {
                            setAppState(prevState => ({
                                ...prevState,
                                init: true,
                                toast: { type: 'success', message: response, options: {} },
                            }));
                            handleModalClose();
                        },
                    });
                },
            },
        });
    };
    const handleLogout = async (e) => {
        e.preventDefault();
        await ApiClient.onPostRequest({
            action: 'onLogout',
            thenCallback: () => window.location.reload(),
        });
    };

    const handleResetChanges = () => {
        handleModalOpen({
            modalType: modalTypes.confirm,
            data: {
                submitButtonText: 'reset',
                title: 'Reset changes',
                titleIconType: 'error',
                contentText: 'All the changes in the draft will be reset by the published version. Are you sure you want to proceed?',
                onConfirm: () => {
                    ApiClient.onPostRequest({
                        action: 'onResetChanges',
                        thenCallback: message => {
                            setAuthState(prevState => ({
                                ...prevState,
                                draftContent: prevState.publishedContent,
                            }));
                            deserializeCraftContent(publishedContent, actions.deserialize);
                            handleModalClose();
                            defer(() => {
                                toast.success(message);
                            }, 50);
                        },
                    });
                },
            },
        });
    };
    const handleShowPermissionModal = message => {
        handleModalOpen({
            modalType: modalTypes.confirm,
            data: {
                submitButtonText: 'upgrade',
                title: 'Subscription upgrade required',
                contentText: message || ERR_DEFAULT,
                onConfirm: () => {
                    handleModalClose();
                    window.open(upgradeLink, '_blank');
                },
            },
        });
    };

    return (
        <div className='h-[48px]' ref={ref}>
            <div className={`w-full fixed z-[1001] ${ isMobile && scrollDirection === 'down' ? '-top-[48px]' : 'top-0'} ${isEdit ? 'bg-edit-color' : 'bg-admin-panel'}`}>
                <div
                    className='h-[48px] wrapper w-full py-[6px] text-14 text-header-footer-text z-[1000] flex flex-wrap lg:flex-nowrap items-center justify-between'>
                    {/* TODO: show Edit button on mobile devices */}
                    {editor && !isMobile &&
                        <div className="flex gap-16">
                            <Button
                                buttonStyle={isEdit ? 'transparent-white' : 'edit'}
                                title={permissions?.edit.message || ''}
                                size='small'
                                onClick={() => {
                                    if (permissions?.edit.allow) {
                                        onEdit();
                                        setEditMode(prev => !prev);
                                        actions.setOptions((options) => {
                                            options.enabled = !isEdit;
                                        });
                                    } else {
                                        handleShowPermissionModal(permissions?.edit.message);
                                    }
                                }}
                            >
                                {isEdit ? 'Exit' : 'Edit mode'}
                            </Button>
                            {isEdit
                                ? <>
                                    <Button
                                        title="Publish content"
                                        buttonStyle='black'
                                        size='small'
                                        onClick={() => {
                                            if (permissions?.publish.allow) {
                                                handlePublish();
                                            } else {
                                                handleShowPermissionModal(permissions?.publish.message);
                                            }
                                        }}
                                    >
                                        Publish
                                    </Button>
                                    <Button buttonStyle='transparent-white' size='small' onClick={handleResetChanges}>
                                        Reset changes
                                    </Button>
                                    <SwitchButton
                                        containerClass='uppercase'
                                        roundClass='after:bg-[#dfb668] peer-checked:after:bg-white'
                                        label='Preview'
                                        checked={!enabled}
                                        onChange={() => actions.setOptions((options) => (options.enabled = !enabled))}
                                    />
                                </>
                                : <Button
                                    buttonStyle={isEdit ? 'transparent-white' : 'black'}
                                    title={permissions?.editSettings.allow ? '' : permissions?.editSettings.message}
                                    size='small'
                                    onClick={() => {
                                        if (permissions?.editSettings.allow) {
                                            handleModalOpen({ modalType: modalTypes.settings });
                                        } else {
                                            handleShowPermissionModal(permissions?.editSettings.message);
                                        }
                                    }}
                                >
                                    Club Settings
                                </Button>}
                        </div>}
                    {isEdit
                        ? null
                        : <>
                            <div className='ml-auto lg:hidden'>
                                <button className='text-xs leading-xs flex' onClick={toggleCustomerMenu}>
                                    Customer menu
                                    <Icon name={isCustomerMenuOpen ? 'arrow_up' : 'arrow_down'} className='fill-white'/>
                                </button>
                                <DropdownMenu
                                    position={customerMenuPosition}
                                    className={`right-0 ${isEdit ? 'bg-edit-color' : 'bg-admin-panel'} w-[320px]`}
                                    onClose={toggleCustomerMenu}
                                    open={isCustomerMenuOpen}
                                    direction='top'
                                >
                                    <div className='flex flex-col text-xs leading-xs'>
                                        {menu.map(item =>
                                            <Fragment key={item.name}>
                                                {item.submenu.length
                                                    ? <p className='py-15 px-24 mb-8'>{`${item.name}:`}</p>
                                                    :
                                                    <a key={item.name} href={item.link} target='_blank' rel='noreferrer'
                                                       className='py-15 px-24 mb-8'>
                                                        {item.name}
                                                    </a>
                                                }
                                                {item.submenu.map(subitem =>
                                                    <a
                                                        key={subitem.name}
                                                        className='py-15 pl-48 pr-24 bg-card-default'
                                                        href={subitem.link}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        {subitem.name}
                                                    </a>)}
                                            </Fragment>)}
                                        <a
                                            className='py-15 pl-48 pr-24 bg-card-default'
                                            href='/#'
                                            target='_blank'
                                            rel='noreferrer'
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </a>
                                    </div>
                                </DropdownMenu>
                            </div>
                            <div className='ml-auto lg:flex hidden'>
                                {menu.map(item => item.submenu.length
                                    ? <Fragment key={item.name}>
                                        <button className='text-xs leading-xs flex' onClick={toggleCustomerMenu}>
                                            {item.name}
                                            <Icon name={isCustomerMenuOpen ? 'arrow_up' : 'arrow_down'}
                                                  className='fill-white' />
                                        </button>
                                        <DropdownMenu
                                            position={customerMenuPosition}
                                            className={`right-0 ${isEdit ? 'bg-edit-color' : 'bg-admin-panel'} w-[320px]`}
                                            onClose={toggleCustomerMenu}
                                            open={isCustomerMenuOpen}
                                            direction='top'
                                        >
                                            <div className='flex flex-col text-xs leading-xs bg-card-default'>
                                                {item.submenu.map(subitem =>
                                                    <a key={subitem.name}
                                                       className='py-15 px-24 mb-8'
                                                       href={subitem.link}
                                                       target='_blank'
                                                       rel='noreferrer'
                                                    >
                                                        {subitem.name}
                                                    </a>
                                                )}
                                                <a
                                                    className='py-15 px-24 mb-8'
                                                    href='/#'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    onClick={handleLogout}
                                                >
                                                    Log Out
                                                </a>
                                            </div>
                                        </DropdownMenu>
                                    </Fragment>
                                    : <a key={item.name} href={item.link} target='_blank' rel='noreferrer'
                                         className='text-xs leading-xs mr-[35px]'>{item.name}</a>
                                )}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};


