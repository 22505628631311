import React, { useRef, useState, useEffect, useContext } from 'react';

import VenueMap from '../page-venues/components/VenueMap';
import VenueEmptyMap from '../page-venues/components/VenueEmptyMap';
import VenueEmptyLocations from '../page-venues/components/VenueEmptyLocations';
import Spinner from '../common/Spinner';
import { VenuesDataContext } from '../../context';
import VenueLocations from '../page-venues/components/VenueLocations';

const VenueDesktopLayout = ({ setCurrentFilterContainer }) => {
    const [activeMarker, setActiveMarker] = useState({ hovered: null, expanded: null, scrollTo: false });
    const locationsScrollRef = useRef(null);
    const spinnerContainer = useRef(null);
    const { venues = [], filteredVenues = [], enabled, isLoading } = useContext(VenuesDataContext);

    useEffect(() => {
        const pageTitle = document.querySelector('#page-title');
        if (pageTitle && pageTitle.parentElement) {
            setCurrentFilterContainer(pageTitle.parentElement);
        }
    }, []);

    return (
        <div ref={spinnerContainer} className='flex flex-col lg:flex-row flex-wrap gap-[24px] min-h-[400px]'>
            {isLoading
                ? <Spinner targetRef={spinnerContainer} className='rounded-12' />
                : <>
                    <div className='flex flex-1 flex-col'>
                        {venues.length || filteredVenues.length
                            ? <VenueMap
                                setActiveMarker={setActiveMarker}
                                activeMarker={activeMarker}
                                venues={venues}
                                filteredVenues={filteredVenues}
                                key='desktop'
                            />
                            : <VenueEmptyMap />
                        }
                    </div>
                    <div ref={locationsScrollRef} className='relative flex flex-1 flex-col min-h-[400px] h-[calc(100vh-250px)] max-h-[calc(100vh-250px)] overflow-y-auto'>
                        <div className='h-full'>
                            {venues.length || enabled
                                ? <VenueLocations
                                    locationsScrollRef={locationsScrollRef}
                                    setActiveMarker={setActiveMarker}
                                    expandedId={activeMarker.expanded}
                                    isScrollTo={activeMarker.scrollTo}
                                />
                                : <VenueEmptyLocations />
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default VenueDesktopLayout;
