/*TODO: hover effect on theme name??*/
const prepareHoverAndDisabledStyles = type => {
    return `
    after:absolute 
    after:top-0 after:left-0 
    after:w-full after:h-full
    after:content-none 
    hover:after:content-[""] disabled:after:content-[""] 
    ${type === 'light' ? 'hover:after:bg-light-hover': 'hover:after:bg-dark-hover'}
    ${type === 'light' ? 'disabled:after:bg-light-disabled': 'disabled:after:bg-dark-disabled'}
    disabled:cursor-not-allowed `
};

const setButtonStyles = (type, size) => {
    let buttonStyle = '';
    switch (type) {
        case 'transparent':
            buttonStyle += 'bg-transparent hover:bg-button-hover disabled:bg-button-disabled border-title text-title';
            break;
        case 'transparent-white':
            buttonStyle += `${prepareHoverAndDisabledStyles('light')} bg-transparent border-white text-white `;
            break;
        case 'transparent-black':
            buttonStyle += `${prepareHoverAndDisabledStyles('light')} bg-transparent border-black text-black `;
            break;
        case 'edit':
            buttonStyle += `${prepareHoverAndDisabledStyles('dark')} bg-edit-color border-edit-color text-white `;
            break;
        case 'black':
            buttonStyle += `${prepareHoverAndDisabledStyles('dark')} bg-black border-black text-white`;
            break;
        case 'white':
            buttonStyle += `${prepareHoverAndDisabledStyles('light')} bg-white border-white text-black`;
            break;
        case 'accent':
        default:
            buttonStyle += `${prepareHoverAndDisabledStyles('dark')} bg-accent-color border-accent-color text-accent-button-text`;
    }

    switch (size) {
        case 'extended':
            buttonStyle += ' whitespace-nowrap h-[36px] pl-[34.5px] pr-[24.5px] ';
            break;
        case 'small':
            buttonStyle += ' whitespace-nowrap h-[36px] px-[18.5px]';
            break;
        case 'big':
        default:
            buttonStyle += ' whitespace-nowrap h-[44px] min-w-[136px] px-[34.5px]';
            break;
    }

    return buttonStyle;
}

const Button = ({ children, className, type, buttonStyle, size, ...other }) => {
    return (
        <button
            type={type || 'button'}
            className={`${setButtonStyles(buttonStyle, size)} uppercase text-button leading-button font-bold border-button rounded-44 text-center relative overflow-hidden ${className ?? ''}`}
            {...other}
        >
            {children}
        </button>
    )
};

export default Button;
