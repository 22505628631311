import LinkWithQuery from '../../LinkWithQuery';

const NavItem = ({ name, pathname }) => {
    return (
        <li key={name} className='flex mb-8 lg:mb-0 lg:mx-20'>
            <LinkWithQuery
                isNav
                to={pathname}
                className={({ isActive }) => {
                    return `${isActive
                        ? 'text-header_main bg-card-default lg:bg-inherit border-r-accent-color lg:border-b-accent-color'
                        : 'inline-block text-header_menu_item border-r-header lg:border-b-header'}
                     w-full text-left lg:w-fit pl-16 lg:pl-0 py-15 lg:pt-[27px] lg:pb-[23px] first:ml-0 text-button leading-button font-bold uppercase border-r-header_underline lg:border-r-0 lg:border-b-header_underline`
                }}
            >
                {name}
            </LinkWithQuery>
        </li>
    );
};

export default NavItem;
