import { useContext } from 'react';

import TeamRosterItem from '../components/TeamRosterItem';
import { EmptyContent } from '../../common/EmptyContent';

import { TeamDataContext } from '../../../context';

export default function TeamRoster() {
    const data = useContext(TeamDataContext);
    const rosters = data?.roster || [];

    const sortedRosters = rosters.sort((a, b) =>
        a.number - b.number ||
        (a.fullName  ? (b.fullName ? a.fullName.localeCompare(b.fullName) : -1) : 1)
    );

    return (rosters.length
            ? <div className='grid grid-cols-[auto] grid-rows-[auto] lg:grid-cols-2 gap-x-24'>
                {sortedRosters.map(roster =>
                    <TeamRosterItem
                        key={roster.id}
                        avatarUrl={roster.avatarUrl}
                        fullName={roster.fullName}
                        number={roster.number}
                        position={roster.position}
                    />)
                }
            </div>
            : <EmptyContent icon='roster' text='The team roster does not currently include any players.'/>
    )
}
