import React, { useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';

import GameTemplate from './game';
import { getTemplateNumberFromName, getCompiledBracket } from './utils';

import './styles.css';

const BracketHTML = ({
    defaultBrackets,
    bracketData,
    division,
    bracket,
    listTeams,
}) => {
    const bracketRef = useRef();
    const templateNumber = getTemplateNumberFromName(bracket.template);
    const { pools, games } = division;

    const [bracketHTML, setBracketHTML] = useState('');

    useEffect(() => {
        if (!bracketHTML) {
            const bracketTemplate = require(`../../../../assets/brackets/${bracket.template}.html`);
            let isGameScheduled = false;
            const gamesStatus = games.reduce((acc, game) => {
                const condition = !!(game.court && game.timeSlot);
                if (condition && !isGameScheduled) {
                    isGameScheduled = true;
                }

                if (game.bracket) {
                    if (!acc[game.bracket]) {
                        acc[game.bracket] = {};
                    }
                    acc[game.bracket][game.bracketGameName] = condition;
                }
                return acc;
            }, {});
            const newHTML = getCompiledBracket({
                bracketData,
                listTeams,
                games,
                bracketTemplate,
                gamesStatus,
                divisionBracketsGamesIndexesStarts: division.divisionBracketsGamesIndexesStarts,
                getGameView,
            });
            setBracketHTML(newHTML);
        }
    }, [bracket.template, bracketHTML]);

    const getGameView = (gameId, competitorIndex) => renderToString(<GameTemplate
        defaultBrackets={defaultBrackets}
        bracketData={bracketData}
        gameId={gameId}
        competitorIndex={competitorIndex}
        listTeams={listTeams}
        pools={pools}
        games={games}
        divisionBracketsGamesIndexesStarts={division.divisionBracketsGamesIndexesStarts}
    />);

    return <div>
        <div className="bracket-name">
            {defaultBrackets.templates[templateNumber][bracket.template]?.name || ''}.&nbsp;
            {bracket?.fullLabel || ''}.
        </div>
        <div ref={bracketRef} dangerouslySetInnerHTML={{ __html: bracketHTML }} />
    </div>;
};

export default BracketHTML;
