import React, { useState, useEffect, useRef } from 'react';

import BracketHtml from "./bracketHTML";
import { generateBracketData } from "./utils";
import ApiClient from "../../../../api";
import { useMobile } from "../../../../hooks";
import Button from "../../../common/Buttons/Button";
import Spinner from "../../../common/Spinner";

const BRACKET_HTML_REQUEST_URL = '/edt/division/info?';

export default function BracketContainer({ event, division }) {
    const [data, setData] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const spinnerContainer = useRef(null);
    const isMobile = useMobile();

    useEffect(() => {
        if (event && division) {
            const getBracketData = async () => {
                await ApiClient.get(`${BRACKET_HTML_REQUEST_URL}event=${event}&division=${division}`, { addConfig: false })
                    .then(response => setData(response))
                    .catch(() => {})
            }
            getBracketData().then();
        }
    }, [event, division]);

    if (!data) {
        return <div className="w-full px-8 lg:px-default-teams-md-px py-16">
            <div className="!min-h-[200px]" ref={spinnerContainer}>
                <Spinner targetRef={spinnerContainer} className='rounded-12' />
            </div>
        </div>
    }

    return data.division.brackets.length
        ? <div className='border-t border-t-border grid py-16 lg:py-24 px-8 lg:px-default-teams-md-px'>
            {isMobile && !isOpen
                ? <Button buttonStyle="transparent" onClick={() => setOpen(true)}>Show brackets</Button>
                : <div className={`container ${data.theme || ''}`}>
                    {data.division.brackets.map((bracket, index) =>
                        <BracketHtml
                            key={bracket.id}
                            index={index}
                            defaultBrackets={data?.brackets}
                            bracketData={generateBracketData({
                                competitorSource: data?.brackets.competitor_source,
                                bracket,
                            })}
                            bracket={bracket}
                            division={{
                                ...data?.division,
                                divisionBracketsGamesIndexesStarts: data?.division?.brackets?.reduce((acc, bracket, index) => {
                                    acc[bracket.id] = index !== 0
                                        ? acc[data?.division?.brackets[index - 1].id] + data?.division?.brackets[index - 1]?.data?.reduce((acc, round) =>
                                        (acc + round.length), 0)
                                        : 0;
                                    return acc;
                                }, {}) || [],
                            }}
                            listTeams={
                                data?.division?.pools?.reduce((result, pool) =>
                                    result.concat(pool.teams), []).concat(data?.division?.teams)
                            }
                        />,
                    )}
                </div>
            }
        </div>
        : null;
};
