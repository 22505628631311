import axios from 'axios';
import { toast } from "react-toastify";

const DEFAULT_HEADERS = {
    'X-October-Request-Partials': '',
    'X-Requested-With': 'XMLHttpRequest',
}

const getConfig = (action, headers) => {
    return {
        headers: {
            'X-OCTOBER-REQUEST-HANDLER': action || '',
            ...headers,
            ...DEFAULT_HEADERS,
        }
    };
}

const thenHandler = response => {
    if (response.success && response.message) {
        toast.success(response.message);
        return Object.keys(response.data).length ? response.data : response.message;
    } else if (!response.success) {
        toast.error(response.message);
        return Promise.reject(response.message);
    }
    return response.data;
}

const catchHandler = ({ response, message }) => {
    if (response.data.redirect) {
        window.location = response.data.redirect;
    }
    const error = response?.data?.message || message;
    // const error = response?.data?.message || response.request.statusText || message;
    toast.error(error);
    return Promise.reject({ response: response.data, error });
}

class ApiClient {
    constructor() {
        this.client = axios.create({
            transformRequest: function (data) {
                const form = new FormData();
                for (const key in data) {
                    const value = data[key];
                    if (Array.isArray(value)) {
                        const arrayKey = `${key}[]`;
                        value.forEach(v => {
                            form.append(arrayKey, v);
                        });
                    } else if (typeof value === 'object') {
                        for (const valueKey in value) {
                            form.append(`${key}[${valueKey}]`, value[valueKey]);
                        }
                    } else {
                        form.append(key, value);
                    }
                }
                return form;
            },
        });
    }

    get = (url = 'api', params = {}) => this.client.get(
        url,
        params.addConfig ? getConfig(params.action) : {},
    )
        .then(response => response.data)
        .then(thenHandler)
        .catch(catchHandler);

    post = (params = {}, headers = {}) => {
        const searchParams = new window.URLSearchParams(window.location.search);
        const view = searchParams.get('view');
        return this.client.post(
            '/api',
            {...params, view },
            getConfig(params.action, headers),
        )
            .then(response => response.data)
            .then(thenHandler)
            .catch(catchHandler);
    }

    onPostRequest = ({
        action,
        content = {},
        thenCallback = () => {},
        catchCallback = () => {},
        finallyCallback = () => {}
    }) => {
        return this.post(
        { action, ...content },
        action === 'onManageImages' ? { 'Content-Type': 'multipart/form-data' } : null,
        )
            .then(thenCallback)
            .catch(catchCallback)
            .finally(finallyCallback);
    }
}

const client = new ApiClient();
export default client;
