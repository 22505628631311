import { useContext } from 'react';

import Accordion from '../../common/Accordion';
import { EmptyContent } from '../../common/EmptyContent';
import TeamScheduleItem from '../components/TeamScheduleItem';
import Icon from '../../../assets/images/icons';
import BracketContainer from '../components/Brackets';

import { TeamDataContext } from '../../../context';
import logo from '../../../assets/images/placeholder_default.png';
import moment from "moment";

export default function TeamSchedule() {
    const data = useContext(TeamDataContext);
    const schedule = data?.schedule || [];
    return schedule.length
        ? schedule.map(event =>
            <Accordion
                key={event.id}
                logo={event.logo || logo}
                additionalControl={<Icon
                    name='popout'
                    title='Open event details in new tab'
                    className='cursor-pointer fill-title md:hidden'
                    onClick={(e) => {e.stopPropagation(); window.open(event.link, '_blank')}}
                />}
                title={<div className='flex items-center gap-8'>
                    <span
                        title={event.name}
                        className='text-h5 leading-h5 w-fit max-w-full font-accent-font'
                    >
                        {event.name}
                    </span>
                        <Icon
                            name='popout'
                            title='Open event details in new tab'
                            className='hidden md:block cursor-pointer fill-title'
                            onClick={(e) => {e.stopPropagation(); window.open(event.link, '_blank')}}
                        />
                    </div>}
                subtitle={`${moment.utc(event.start_date).format('MMM DD')}${ event.start_date !==  event.end_date ? ` - ${moment.utc(event.end_date).format('MMM DD')}` : ''}`}
                subtitleClass='text-xs md:text-s'
                contentStyle='border-t border-border font-main-font'
                isActive={schedule.length === 1}
            >
                {/* TODO: check it*/}
                {event.dates.length
                    ? <>
                        <div className="px-16 lg:px-24 py-4 flex gap-8 bg-block-bg">
                            <span>Last modified:</span>
                            <span>{moment(event.schedule_timestamp).format('MMM DD h:mm A z')}</span>
                        </div>
                        <div className='pb-16 lg:pb-24'>
                            {event.dates.map(date =>
                                <div key={date.date} className='px-8 lg:px-default-teams-md-px'>
                                    <p className='px-8 lg:px-0 basis-full font-accent-font text-h5 leading-h5 font-semibold my-16'>
                                        {date.formatted}
                                    </p>
                                    <div
                                        className='grid grid-cols-[auto] grid-rows-[auto] lg:grid-rows-[1fr] lg:grid-cols-[1fr_1fr] gap-16'>
                                        {date.games.map(game => <TeamScheduleItem
                                            key={game.id}
                                            teams={[game.teamA, game.teamB]}
                                            {...game}
                                        />)}
                                    </div>
                                </div>,
                            )}
                        </div>
                        <BracketContainer event={event.id} division={event.division_id}/>
                    </>
                    : <EmptyContent icon='event' text='The schedule for this event has not been published.'/>
                }
            </Accordion>)
        : <EmptyContent icon='event' text='Currently, there are no available schedules for this team. Please check back.'/>
}
