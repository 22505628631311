import { useEffect, useState, useRef } from "react";
import { useInView } from 'react-cool-inview';

import { apiMethodByType } from "./config";
import { stopScrolling, defer } from "./utils";
import ApiClient from "./api";

const DEFAULT_DESKTOP_STARTS = 1024;

export const useMobile = () => {
    const [isMobile, setIsMobile] = useState(
        window.screen.width < DEFAULT_DESKTOP_STARTS || document.body.clientWidth < DEFAULT_DESKTOP_STARTS);
    useEffect(() => {
        const handleWindowResize = () => {
            setIsMobile(window.screen.width < DEFAULT_DESKTOP_STARTS ||
                document.body.clientWidth < DEFAULT_DESKTOP_STARTS);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() => {
        stopScrolling(false);
    }, [isMobile]);

    return isMobile;
};

export const useScrollTo = ({ scrollRef, targetRef, isScrollTo, offset = 0 }) => {
    useEffect(() => {
        if (isScrollTo) {
            defer(() => {
                (scrollRef?.current || window).scrollTo({
                    top: targetRef.current.offsetTop - offset,
                    behavior: 'smooth',
                });
            }, 200);
        }
    }, [isScrollTo]);
}

export const useFetch = (type, apiData) => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const isValid = useRef(null);

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            isValid.current = true;
            const { variable, action } = apiMethodByType[type];
            const pageData = window[variable];
            let data;
            if (pageData) {
                data = window[variable].success ? window[variable].data : window[variable];
                window[variable] = null;
            } else {
                data = await ApiClient.onPostRequest({
                    action,
                    content: apiData,
                    thenCallback: response => response,
                    catchCallback: ({ response }) => response,
                });
            }
            if (isValid.current) {
                setData(data);
            }
            setLoading(false);
        };
        getData().then();
        return () => {
            isValid.current = false;
        }
    }, [type]);

    return [data, isLoading];
};

export const useInfiniteLoading = ({ getItems, limit = 20 }) => {
    const [items, setItems] = useState([]);
    const [hasNext, setHasNext] = useState(true);
    const nextItems = useRef([]);
    const isAllLoaded = useRef(false);
    const initialPageOffset = useRef(0);
    const currentPageOffset = useRef(initialPageOffset.current);
    const itemsCount = useRef(0);
    const initialPageLoaded = useRef(false);
    const isProcessing = useRef(false);
    const triggerNext = useRef(false);

    const loadItems = async offset => {
        let newItems = [];
        if (!nextItems.current.length) {
            isProcessing.current = true;
            const data = await getItems({ offset, limit });
            if (!itemsCount.current) {
                itemsCount.current = data.pagination.count;
            }
            isProcessing.current = false;
            isAllLoaded.current = items.length + data.games.length >= itemsCount.current;
            currentPageOffset.current = offset;
            newItems = data.games;
        } else {
            newItems = nextItems.current;
        }

        setItems(prevItems => ([...prevItems, ...newItems]));

        nextItems.current = [];

        if (!isAllLoaded.current) {
            isProcessing.current = true;
            const newOffset = currentPageOffset.current + limit;
            const data = await getItems({ offset: newOffset, limit });
            isProcessing.current = false;
            isAllLoaded.current = items.length + newItems.length + data.games.length >= itemsCount.current;
            currentPageOffset.current = newOffset;
            nextItems.current = data.games;

            if (triggerNext.current) {
                triggerNext.current = false;
                loadItems(currentPageOffset.current + limit);
            }
        } else {
            setHasNext(false);
        }
    };

    useEffect(() => {
        if (initialPageLoaded.current) {
            return;
        }

        loadItems(initialPageOffset.current);
        initialPageLoaded.current = true;
    }, [])

    const { observe } = useInView({
        onEnter: () => {
            if (isProcessing.current) {
                triggerNext.current = true;
            } else {
                loadItems(currentPageOffset.current + limit);
            }
        },
    });

    return { items, hasNext, loadMoreRef: observe };
}

export const useOnChange = ref => {
    const [changed, setChanged] = useState(false);
    useEffect(() => {
        const handleChange = e => {
            if (!changed && e.target && !e.target.dataset.skipOnChange) {
                setChanged(true);
            }
        }
        ref.current?.addEventListener('change', handleChange);
        return () => {
            ref.current?.removeEventListener('change', handleChange);
        }
    }, [ref.current]);

    return changed;
}

export const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener('scroll', updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener('scroll', updateScrollDirection); // clean up
        }
    }, [scrollDirection]);

    return scrollDirection;
}
