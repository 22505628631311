import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import {
    areNumbersEqual,
    capitalizeString,
    handleModalOpen,
    loadGoogleMarkersLibrary,
    loadGoogleMapsLibrary,
} from '../../../utils';
import { useMobile } from '../../../hooks';
import SwipeContainer from '../../common/SwipeContainer';

import NavigateButton from '../../common/Buttons/NavigateButton';
import { modalTypes } from '../../../config';

const MAP_ID = 'VENUE_MAP_ID';
const setBoundsToAllAddresses = (map, actualMarkers) => {
    if (map) {
        const GoogleMaps = window.google.maps;
        const bounds = new GoogleMaps.LatLngBounds();
        let count = 0;
        for (const id in actualMarkers) {
            if (actualMarkers.hasOwnProperty(id)) {
                count++;
                bounds.extend(actualMarkers[id].position);
            }
        }
        if (count > 1) {
            map.fitBounds(bounds);
        } else {
            map.setCenter(bounds.getCenter());
            map.setZoom(14);
        }
    }
};

const SliderItem = ({ item = {}, setOpenModal }) => {
    return <div
        onClick={() => setOpenModal()}
        className={`rounded-16 bg-page-bg ${setOpenModal ? 'pt-[36px]' : 'pt-[26px]'} p-16 !flex flex-col justify-between w-full h-full relative`}
    >
        {setOpenModal ? <div className='bg-border absolute h-4 w-16 top-16 rounded-8 left-1/2 -translate-x-1/2' /> : null}
        <div className='flex flex-col'>
            {item?.name
                ? <p className='font-accent-font basis-full text-[18px] font-semibold text-color-text'>
                    {item.name}
                </p>
                : <>
                    <div className="w-[72.5%] h-[10px] bg-button-disabled rounded-[30px] mb-16" />
                    <div className="w-[37%] h-[10px] bg-button-disabled rounded-[30px]" />
                </>
            }
            {item?.type
                ? <p className='basis-full text-label leaing-label font-semibold text-title my-[6px]'>
                    {`Type: ${capitalizeString(item.type)}`}
                </p>
                : <div className="mt-16 w-[34%] h-[6px] bg-button-disabled rounded-[30px]" />
            }
            {item?.fullAddress
                ? <p className='basis-full text-label text-content mb-16'>
                    {item.fullAddress}
                </p>
                : <div className="my-16 w-[84%] h-[6px] bg-button-disabled rounded-[30px]" />
            }
        </div>
        {item.latitude
            ? <NavigateButton
                onClick={e => e.stopPropagation()}
                latitude={item.latitude}
                longitude={item.longitude}
            />
            : <div className='w-full h-[36px] bg-button-disabled rounded-[30px]' />
        }
    </div>
}

const VenueMap = ({ venues, filteredVenues, activeMarker, setActiveMarker }) => {
    const [stateMarkers, setStateMarkers] = useState({});
    const [activeMarkerData, setActiveMarkerData] = useState({
        hovered: null,
        expanded: { marker: null, location: null },
    });
    const [map, setMap] = useState(null);
    const [settings, setSettings] = useState([]);
    const [librariesLoaded, setLibrariesLoaded] = useState(false);
    const isMobile = useMobile();
    const mapRef = useRef(null);
    const sliderRef = useRef(null);
    const infoWindowRef = useRef(null);
    const slideCurrentIndexRef = useRef(0);

    useEffect(() => {
        async function initializeMap() {
            const MapEl = await loadGoogleMapsLibrary();
            const MarkerEl = await loadGoogleMarkersLibrary();

            const initializedMap = new MapEl(
                mapRef.current,
                {
                    mapTypeControl: false,
                    streetViewControl: false,
                    disableDefaultUI: isMobile,
                    mapId: MAP_ID,
                }
            );

            setMap(initializedMap);
            setLibrariesLoaded(true);
        }

        if (!map) {
            initializeMap();
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            const marker = stateMarkers[activeMarker.hovered];
            if (activeMarkerData.hovered !== activeMarkerData.expanded.marker) {
                activeMarkerData.hovered?.content.classList.remove('animate-bounce');
            }
            if (activeMarker.hovered !== activeMarkerData.expanded.location?.id) {
                marker?.content.classList.add('animate-bounce');
            }
            setActiveMarkerData(prevState => ({ ...prevState, hovered: marker }));
        }
    }, [activeMarker.hovered]);

    useEffect(() => {
        if (map) {
            const marker = stateMarkers[activeMarker.expanded];
            const index = filteredVenues.findIndex(({ id }) => String(id) === String(activeMarker.expanded));
            const location = filteredVenues[index];
            activeMarkerData.hovered?.content.classList.remove('animate-bounce');
            if (activeMarker.expanded) {
                const coords = marker.position;
                handleShowOnMap(coords);
                if (isMobile) {
                    if (slideCurrentIndexRef.current !== index) {
                        setTimeout(() => {
                            sliderRef.current.slickGoTo(index)
                        }, 300);
                    }
                }
            }
            setActiveMarkerData(prevState => ({ ...prevState, expanded: { marker, location } }));
            let infoWindow = infoWindowRef.current;
            infoWindow?.hide();
            if (location) {
                infoWindow.setContent(`<div id='info-window'><h1>${location.name}</h1></div>`);
                infoWindow.setPosition(marker.position);
            }
        }
    }, [activeMarker.expanded]);

    useEffect(() => {
        if (librariesLoaded && map) {
            loadGoogleMarkersLibrary().then(MarkerEl => {
                // removing old markers from the map;
                Object.values(stateMarkers).forEach(marker => {
                    marker.map = null
                });
                const actualMarkers = {};
                filteredVenues.forEach((location) => {
                    const coordinates = {
                        lat: location.latitude ? Number(location.latitude) : 0,
                        lng: location.longitude ? Number(location.longitude) : 0,
                    };
                    const marker = new MarkerEl({
                        position: coordinates,
                        map,
                    });

                    marker.addListener('click', function () {
                        setActiveMarker(prevState => ({
                            ...prevState,
                            expanded: areNumbersEqual(prevState.expanded, location.id) ? null : location.id,
                            scrollTo: !isMobile,
                        }));
                    });

                    actualMarkers[location.id] = marker;
                });

                if (filteredVenues.length) {
                    setBoundsToAllAddresses(map, actualMarkers)
                    isMobile && customPanTo(actualMarkers[filteredVenues.at(0).id].position, map)
                }

                const InfoWindow = require('./VenueInfoWindow').default;
                infoWindowRef.current = new InfoWindow(map);
                setStateMarkers(actualMarkers);
            })
        }
    }, [venues, filteredVenues, librariesLoaded]);

    const scrollToMap = () => {
        const { top } = mapRef.current.getBoundingClientRect();
        const offset = isMobile ? -(mapRef.current.clientHeight - window.screen.height) : 100;
        window.scrollTo({
            top: top + window.scrollY - offset,
            behavior: 'smooth',
        });
    }

    const customPanTo = (coords, newMap) => {
        const GoogleMaps = window.google?.maps || {};
        const Map = map || newMap;
        if (Map) {
            try {
                const offsetx = 0;
                const offsety = 72.5;
                const scale = Math.pow(2, Map.getZoom());

                const worldCoordinateCenter = Map.getProjection().fromLatLngToPoint(coords);
                const pixelOffset = new GoogleMaps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

                const worldCoordinateNewCenter = new GoogleMaps.Point(
                    worldCoordinateCenter.x - pixelOffset.x,
                    worldCoordinateCenter.y + pixelOffset.y
                );

                const newCenter = Map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

                Map.panTo(newCenter);
            } catch(error) {
                Map.panTo(coords);
            }
        }
    };

    const handleShowOnMap = coords => {
        scrollToMap();
        customPanTo(coords);
    };

    useEffect(() => {
        setSettings({
            dots: false,
            arrows: false,
            className: 'center',
            centerPadding: '24px',
            slidesToShow: 1,
            centerMode: true,
            infinite: false,
            focusOnSelect: true,
            speed: 500,
            afterChange: (index) => {
                slideCurrentIndexRef.current = index;
                const id = filteredVenues[Math.ceil(index)]?.id;
                if (id) {
                    setActiveMarker(prevState => ({
                        ...prevState,
                        expanded: id,
                        scrollTo: false,
                    }));
                }
            },
        });
    }, []);

    const handleShowModal = () => {
        handleModalOpen({
            modalType: modalTypes.venueBackdrop,
            data: {
                filteredVenues,
                slideCurrentIndex: slideCurrentIndexRef.current,
            }
        });
    };

    return <div className={`relative w-full ${isMobile ? 'h-[85vh]' : 'h-full'}`}>
        <div ref={mapRef} className='w-full h-full' />
        {isMobile
            ? filteredVenues.length
                ? <>
                    <SwipeContainer
                        up={handleShowModal}
                        customClassName='absolute w-full overflow-hidden bottom-20 z-4 touch-pan-x'
                    >
                        <Slider ref={sliderRef} {...settings}>
                            {filteredVenues.map(location =>
                                <SliderItem key={location.id} item={location} setOpenModal={handleShowModal} />)}
                        </Slider>
                    </SwipeContainer>
                </>
                : <div className='px-24 absolute w-full overflow-hidden bottom-20 z-4'>
                    <SliderItem />
                </div>
            : null}
    </div>
}

export default VenueMap;
