export default function ModalContent ({ children, className, outerRef }){
    return (
        <div
            ref={outerRef}
            className={`flex-1 border-t border-b border-border overflow-y-scroll z-1150 ${className ?? ''}`}
        >
            {children}
        </div>
    )
}
