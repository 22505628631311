import React from 'react';

import defaultImage from '../../../assets/images/imageUpload_default.png';
import Button from '../../common/Buttons/Button';
import NavigateButton from '../../common/Buttons/NavigateButton';
import {capitalizeString} from "../../../utils";

const VenueListViewItem = ({ item, showOnMapCallback, navigateButtonClass }) => {
    return <div key={item.id} className='flex flex-col flex-1'>
        {item.logoPath
            ? <img src={item.logoPath || defaultImage}
                   alt={item.image_alt || 'Location Preview'}
                   className='basis-full rounded-16 overflow-hidden mb-24 w-full'
            />
            : null}
        <div className='flex flex-col px-8 flex-1'>
            <p className='font-accent-font text-h5 leading-h5 font-semibold text-title mb-16'>
                {item.name || 'empty name'}
            </p>
            <p className='font-semibold text-xs leading-xs text-title mb-[6px]'>
                {`Type: ${capitalizeString(item.type)}`}
            </p>
            <p className='text-s leading-standard text-content mb-16'>
                {item.fullAddress || 'empty full address'}
            </p>
            {item.description
                ? <p className='text-s leading-standard text-content mb-16'>{item.description}</p>
                : null}
        </div>
        <NavigateButton
            size="big"
            latitude={item.latitude}
            longitude={item.longitude}
            className={`${showOnMapCallback ? 'mb-16' : ''} ${navigateButtonClass ?? ''}`}
        />
        {showOnMapCallback
            ? <Button
                buttonStyle='transparent'
                className='w-full lg:w-fit'
                title='Maps will open showing the direction to the location'
                onClick={showOnMapCallback}
            >
                Show on map
            </Button>
            : null}
    </div>;
}

export default VenueListViewItem;
