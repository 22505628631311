import React from 'react';

import Button from '../Buttons/Button';

const ModalFooter = ({
    darkTheme,
    onSubmit,
    onClose,
    submitButtonText,
    additionalButton,
    elementsCount,
    submitButtonType,
    disableSaveButton,
}) => {
    return (
        <div className='flex-none flex items-center px-24 py-20 z-1200'>
            {React.isValidElement(additionalButton)
                ? additionalButton
                : additionalButton && <Button
                    onClick={additionalButton.onClick}
                    buttonStyle='edit'
                    size='small'
                    className='mr-12'
                >
                    {additionalButton.text}
                </Button>
            }
            {elementsCount &&
                <p className='text-modal-text text-xs leading-xs'>{elementsCount}</p>
            }
            <Button
                onClick={onClose}
                buttonStyle={darkTheme ? 'transparent-white' : 'transparent-black'}
                size='small'
                className='ml-auto mr-12'
            >
                Cancel
            </Button>
            <Button
                size='small'
                disabled={disableSaveButton}
                buttonStyle={darkTheme ? 'white' : 'black'}
                onClick={onSubmit}
                type={submitButtonType || 'submit'}
            >
                {submitButtonText || 'Save Changes'}
            </Button>
        </div>
    )
};

export default ModalFooter;
