import { useContext } from 'react';

import TeamScheduleItem from '../components/TeamScheduleItem';
import TeamShortStatistics from '../components/TeamShortStatistics';
import { EmptyContent } from '../../common/EmptyContent';
import Spinner from '../../common/Spinner';

import { TeamDataContext } from '../../../context';
import { useInfiniteLoading } from '../../../hooks';
import ApiClient from '../../../api';

export default function TeamHistory() {
    const data = useContext(TeamDataContext);
    const statistics = data.statistics || {};

    const getItems = options =>
        ApiClient.onPostRequest({
            action: 'onGetGamesHistory',
            content: {team: data.id, ...options},
            thenCallback: response => response,
        });

    const {items, hasNext, loadMoreRef} = useInfiniteLoading({getItems, limit: 5});

    return <>
        {items.length
            ? (<div className='lg:max-w-[750px]'>
                    <TeamShortStatistics data={statistics}/>
                </div>)
            : null
        }
        {!items.length && !hasNext
            ? <EmptyContent icon='history' text="The season's history will be visible once your initial tournament games are played."/>
            : null
        }
        <div className='grid grid-cols-[auto] grid-rows-[auto] lg:grid-rows-[1fr] lg:grid-cols-[1fr_1fr] gap-24'>
            {items.map(game => <TeamScheduleItem isHistory key={game.id} teams={[game.teamA, game.teamB]} {...game} />)}
        </div>
        {hasNext && <div className='mt-16 h-[148px] relative' ref={loadMoreRef}>
            <Spinner/>
        </div>}
    </>
}
