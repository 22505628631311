import React, { useState, useContext, useEffect, useRef } from 'react';
import moment from "moment";

import ToggleButtons from '../common/Buttons/ToggleButtons';
import { ScheduleDataContext } from '../../context';
import Spinner from "../common/Spinner";
import GradientLogo from "../common/GradientLogo";
import Icon from "../../assets/images/icons";
import Chips from "../common/Chips";
import LinkWithQuery from '../common/LinkWithQuery';
import { generateTitle, navigateByCoords } from "../../utils";
import ScheduleNoMatches from "../page-schedule/components/ScheduleNoMatches";
import { EmptyContent } from "../common/EmptyContent";

const ScheduleMobileLayout = ({ setCurrentFilterContainer }) => {
    const [isTeamListView, setTeamListView] = useState(false);
    const [combinedData, setCombinedData] = useState({});
    const filterContainer = useRef(null);
    const spinnerContainer = useRef(null);

    const { data, filteredData, isLoading } = useContext(ScheduleDataContext);

    useEffect(() => {
        const items = filteredData.schedule || data.schedule || [];
        setCombinedData(items.reduce((acc, item) => {
            if (acc[item.formattedGameDay]) {
                acc[item.formattedGameDay].push(item);
            } else {
                acc[item.formattedGameDay] = [item];
            }
            return acc;
        }, {}));
    }, [data.schedule, filteredData.schedule]);

    useEffect(() => {
        if (filterContainer.current) {
            setCurrentFilterContainer(filterContainer.current);
        }
    }, [isLoading, filterContainer]);

    const combinedArray = Object.entries(combinedData);
    const timestamp = filteredData.timestamp || data.timestamp;

    return <>
        <ToggleButtons
            activeIndex={+isTeamListView}
            buttons={[
                { text: 'Detailed View', onClick: () => setTeamListView(false) },
                { text: 'Team List', onClick: () => setTeamListView(true) },
            ]}
        />
        <div className="pb-24 mt-24" ref={filterContainer}></div>
        {isLoading
            ? <div ref={spinnerContainer}>
                <Spinner targetRef={spinnerContainer} className='rounded-12' />
            </div>
            : <div className='flex flex-col lg:flex-row flex-wrap mt-24 text-content'>
                {!data?.schedule.length && !filteredData.schedule
                    ? <EmptyContent icon="schedule" text="Currently, there are no available schedules for your club. Please check back." />
                    : <>
                        {timestamp
                            ? <div className="flex gap-8 mb-8">
                                <span className="text-label font-semibold">Last modified:</span>
                                <span className="text-label">{moment(timestamp).format('MMM DD h:mm A z')}</span>
                            </div>
                            : null}
                        {combinedArray.length
                            ? combinedArray.map(([key, items]) =>
                                <div key={key} className="flex flex-col -mx-16">
                                    <div className="px-16 py-4 bg-card-default">{key}</div>
                                    <div className="border-b border-border">
                                        {items.map(item => {
                                            return <div
                                                className="px-16 py-8 [&>div]:min-h-[36px] text-label border-t border-border"
                                                key={item.id}>
                                                <div className="flex !min-h-[44px] py-[10px] text-xs gap-[6px]">
                                                    <div className="shrink-0 w-24 h-24 rounded-[12px] overflow-hidden">
                                                        {item.logo
                                                            ? <img
                                                                title={generateTitle(item.grade, item.gender_name)}
                                                                alt='Team logo'
                                                                className='w-24 h-full object-cover'
                                                                src={item.logo}
                                                            />
                                                            : <GradientLogo
                                                                title={generateTitle(item.grade, item.gender_name)}
                                                                text={item.grade}
                                                                gender={item.gender}
                                                            />
                                                        }
                                                    </div>
                                                    <LinkWithQuery
                                                        to={`/teams/${item.team_id}`}
                                                        className="flex-1 leading-xs text-accent-color items-center font-semibold"
                                                    >
                                                        {item.full_name}
                                                    </LinkWithQuery>
                                                    <a href={item.eventUrl} target="_blank"
                                                       className="flex items-center">
                                                        <Icon className="fill-title" name="popout"/>
                                                    </a>
                                                </div>
                                                {isTeamListView
                                                    ? null
                                                    : item.venueGames.map(venue =>
                                                        <div key={venue.id} className={`flex flex-wrap gap-8 mb-16 last:mb-8 ${
                                                            item.venueGames.length > 1 ? 'mobile-schedule-vertical-line' : ''
                                                        }`}>
                                                            <a
                                                                href={`${item.eventUrl}?team=${item.id}`}
                                                                target="_blank"
                                                            >
                                                                <Chips
                                                                    icon="schedule"
                                                                    text={`${venue.times.join`, `}${item.hasBracket ? ', Bracket' : ''}`}
                                                                />
                                                            </a>
                                                            <div
                                                                className='cursor-pointer'
                                                                onClick={() => navigateByCoords({
                                                                    latitude: venue.latitude || 0,
                                                                    longitude: venue.longitude || 0,
                                                                })}
                                                            >
                                                                <Chips icon="home" text={venue.name} />
                                                        </div>
                                                    </div>)}
                                            </div>
                                        })}
                                    </div>
                                </div>)
                            : <ScheduleNoMatches />}
                    </>}
            </div>
        }
    </>
}

export default ScheduleMobileLayout;
