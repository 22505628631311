import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Icon from "../assets/images/icons";
import { PATH_ROOT } from "../config";
import { defer } from "../utils";

const DEFAULT_TIMER_SECONDS = 5;

const NoPage = () => {
    const [seconds, setSeconds] = useState(DEFAULT_TIMER_SECONDS);
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        const interval = setInterval(() => {
            let newSeconds;
            setSeconds(seconds => {
                newSeconds = seconds - 1;
                return newSeconds;
            });
            defer(() => {
                if (!newSeconds) {
                    clearInterval(interval);
                    navigate(PATH_ROOT + search, { replace: true });
                }
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return <div className="flex flex-col items-center justify-center py-40 lg:py-[48px]">
        <Icon name="image404" className="mb-[42px] max-w-[537px] max-h-[196px]" />
        <h3 className="mb-16 text-h3 font-semibold text-center">The page you are looking for wasn't found.</h3>
        <h5 className="text-center">You will be redirected to the Club Portal home page in<br />
            <span className="timer">{seconds}</span> seconds.
        </h5>
    </div>
};

export default NoPage;
