import React, { useState } from 'react';
import ApiClient from "../../../api";
import Spinner from "../Spinner";
import { MAX_SIZE_PER_REQUEST } from "../../../config";
import { FormDataContext } from "../../../context";

const FormImageUploadWrapper = ({
    onSubmit,
    uploadCallback,
    children,
    customClassName,
    data,
    prevData,
    parentIsLoading,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [croppedImages, setCroppedImages] = useState({});

    const defaultHandleSubmit = async event => {
        event.preventDefault();
        let isError = false;

        const remove = Object.entries(data).reduce((acc, [key, value]) => {
            // !croppedImages[key] if was remove and after add cropped image, same as replace
            if (!value && prevData[key] && !croppedImages[key]) {
                acc.push(key);
            }
            return acc;
        }, []);
        const images = Object.entries(croppedImages).reduce((acc, [name, file]) => {
            const newFile = new File([file], name, {
                type: file.type,
                lastModified: file.lastModified,
            });
            const arrayIndex = acc.findIndex(item =>
                item.reduce((sum, file) => sum + file.size, 0) + newFile.size < MAX_SIZE_PER_REQUEST
            );
            if (arrayIndex === -1) {
                acc[acc.length] = [newFile];
            } else {
                acc[arrayIndex].push(newFile);
            }
            return acc;
        }, []);

        let newSrc = {};
        if (images.length || remove.length) {
            setLoading(true);
            const newCroppedImages = { ...croppedImages };
            const thenCallback = (data) => {
                const { uploaded } = data;
                if (uploaded.length) {
                    uploaded.forEach(({ name, path }) => {
                        newSrc[name] = path;
                        delete newCroppedImages[name];
                    });
                }
            };
            const finallyCallback = () => setLoading(false);
            const catchCallback = () => isError = true;

            if (images.length) {
                await Promise.all(
                    images.map(pack =>
                        ApiClient.onPostRequest({
                            action: 'onManageImages',
                            content: { images: pack, remove },
                            thenCallback,
                        }),
                    )
                )
                    .then(() => {
                        setCroppedImages(newCroppedImages);
                    })
                    .catch(catchCallback)
                    .finally(finallyCallback);
            } else if (remove.length) {
                await ApiClient.onPostRequest({
                    action: 'onManageImages',
                    content: { remove },
                    thenCallback,
                    finallyCallback,
                    catchCallback
                });
            }
        }
        !isError && uploadCallback(newSrc);
    }

    return <form
        className={`max-h-[inherit] relative flex flex-col${customClassName ? ' ' + customClassName : ''}`}
        onSubmit={e => onSubmit ? onSubmit(e, croppedImages) : defaultHandleSubmit(e)}
    >
        <FormDataContext.Provider value={{ setCroppedImages }}>
            {isLoading || parentIsLoading ? <Spinner /> : null}
            {children}
        </FormDataContext.Provider>
    </form>
};

export default FormImageUploadWrapper;
