import { Element } from "@craftjs/core";

import Empty from "../components/craftJS/empty";
import Teams from "../components/page-teams";

const TeamsContainer = () => {
    return <Element id="teams-canvas" canvas is={Empty}>
        <Teams />
    </Element>
};

export default TeamsContainer;
