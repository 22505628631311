import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import Icon from '../../../assets/images/icons';
import { areNumbersEqual } from "../../../utils";
import ApiClient from "../../../api";

const DeleteVenue = ({ onClose, id, name, address, setVenues }) => {
    const handleSubmit = e => {
        e.preventDefault();
        ApiClient.onPostRequest({
            action: 'onRemoveVenue',
            content: { id },
            thenCallback: () => {
                setVenues(prevState => prevState.filter(item => !areNumbersEqual(item.id, id)));
                onClose();
            },
        });
    };

    return <form onSubmit={handleSubmit}>
        <ModalHeader
            title={<div className='flex items-center gap-16'>
                <Icon name='warning' className="fill-warning" />
                Remove Venue
            </div>}
            onClose={onClose}
        />
        <ModalContent>
            <div className="px-24 pt-16 pb-32 flex flex-col text-title">
                <p className="mb-16">Confirm to remove following venue:</p>
                <p className="font-bold">{name}</p>
                <p className="whitespace-normal">{address}</p>
            </div>
        </ModalContent>
        <ModalFooter submitButtonText="confirm" onClose={onClose} />
    </form>;
};

export default DeleteVenue;
