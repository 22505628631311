import NavItem from '../NavItem';

const Navbar = ({ items, listStyles }) => {
    return (
        <nav>
            <ul className={`flex ${listStyles}`}>
                {items.map(item => (
                    <NavItem {...item} key={item.key} />
                ))}
            </ul>
        </nav>
    );
};

export default Navbar;
