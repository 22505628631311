import CustomUploadImage from '../../common/Controls/CustomUploadImage';
import CustomInput from '../../common/Controls/CustomInput';

const GalleryItem = ({
    id,
    index,
    isLast,
    img,
    altText,
    title,
    handleSetValue,
    handleDelete,
    template,
    handleChangeOrder,
}) => {
    const changeOrderButtonsSettings = {
        index: index,
        isLast: isLast,
        onMoveElementForward: () => handleChangeOrder(index, index + 1),
        onMoveElementBack: () => handleChangeOrder(index, index - 1),
    }

    return (
        <div className='w-full flex flex-col p-16 border rounded-4'>
            <CustomUploadImage
                mayBeDeleted
                changeOrderButtonsSettings={changeOrderButtonsSettings}
                onDeleteParentElement={() => handleDelete(id)}
                template={template}
                name={id}
                title='Gallery Image'
                imgType='gallery'
                imgSrc={img}
                setImg={value => handleSetValue(value, id, 'img')}
                cropperProps={{
                    aspectRatio: 16 / 9,
                    preferredSize: { width: 1296, height: 729 }
                }}
            />
            <CustomInput
                className='mt-16'
                label='Image title'
                value={title}
                onChange={value => handleSetValue(value, id, 'title')}
            />
            <CustomInput
                className='mt-16'
                label='Image alt text'
                placeholder='Type alt text here'
                value={altText}
                onChange={value => handleSetValue(value, id, 'altText')}
            />
        </div>)
};

export default GalleryItem;
