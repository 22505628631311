import Icon from "../../assets/images/icons";

const Chips = ({ className, icon, iconClassName, text }) =>
    <div className={`border border-border rounded-[40px] flex gap-4 py-6 pl-6 pr-12 items-center min-h-[36px] ${className ?? ''}`} >
        {icon ?
            <div className="flex items-center justify-center w-24 h-24 bg-dark-disabled rounded-full shrink-0">
                <Icon name={icon} className={iconClassName ?? 'fill-title'} />
            </div>
            : null}
        {text}
    </div>;

export default Chips;
