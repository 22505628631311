var _ = require('lodash');
module.exports = Function(_.keys(_.templateSettings.imports), 'return ' + function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table id="bracket" class="bracket 12t3g" border="0" cellspacing="0" cellpadding="0">\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(5, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(1, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(5)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(5)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(17, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(1)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(5, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(1, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(5)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(5, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(1, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(17)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(17)))==null?'':__t)+
'</td>            \n            <td colspan="3"  class="game-team teamA '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(23, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(2, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'"></td>            \n            <td colspan="3"  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(2)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(2)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(6, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'"></td>            \n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(2, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(6)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(6)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(17, 1)))==null?'':__t)+
'\n            </td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(2, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(6, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game teamB left-side round-2 '+
((__t=(setGameVisibility(17)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(17, 1)))==null?'':__t)+
'                  \n            </td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(6)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(6, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td colspan="3"  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(23)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(23)))==null?'':__t)+
'</td>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(26, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(3, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right round-6 '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(3)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(3)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(7, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(3, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(7)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(7)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(18, 0)))==null?'':__t)+
'                  \n            </td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(3, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(7, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(7)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(7, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'"></td>            \n            <td colspan="3"  class="bracket-game border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(18)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(18)))==null?'':__t)+
'</td>            \n            <td colspan="3"  class="game-team border-right '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(23, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(4, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'"></td>            \n            <td colspan="3"  class="bracket-game teamB left-side round-3 '+
((__t=(setGameVisibility(23)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(23, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(4)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(4)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(8, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-6 '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(26)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(26)))==null?'':__t)+
'</td>            \n            <td class="game-result '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('26', "<!--start-->W26<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(4, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(8)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(8)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(18, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td class="game-summary '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('26', "Champion", false)))==null?'':__t)+
'                  \n            </td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(4)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(4, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(8, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game teamB left-side round-2 '+
((__t=(setGameVisibility(18)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(18, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(8)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(8, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(25, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-5 '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(9, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(21)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(21, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(9)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(9)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(13)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(13, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(21)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(9, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(13)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(13)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(13)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(19)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(19, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(21)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(21)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(21)))==null?'':__t)+
'</td>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(24, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(9)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(9, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(13)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(13, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(19)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(21)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right round-4 '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td  class="bracket-game border-right round-5 '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(25)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(25)))==null?'':__t)+
'</td>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(26, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(10)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(10, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(13)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(13, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(19)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(19)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(19)))==null?'':__t)+
'</td>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(21)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(21, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game teamB left-side round-6 '+
((__t=(setGameVisibility(26)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(26, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(10)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(10)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(10)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(14)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(14, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(19)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game teamB left-side round-3 '+
((__t=(setGameVisibility(21)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(21, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(10)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(10, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(14)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(14)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(14)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(19)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(19, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(10)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(10, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(14)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(14, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game teamB left-side round-2 '+
((__t=(setGameVisibility(19)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(19, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(11)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(11, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(14)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(14, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-4 '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(24)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(24)))==null?'':__t)+
'</td>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(25, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(11)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(11)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(11)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(15)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(15, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game teamB left-side round-5 '+
((__t=(setGameVisibility(25)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(25, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(11)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(11, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(15)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(15)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(15)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(20)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(20, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(11)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(11, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(15)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(15, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(20)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(12)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(12, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(15)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(15, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right round-2 '+
((__t=(setGameVisibility(20)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(20)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(20)))==null?'':__t)+
'</td>            \n            <td  class="game-team teamA '+
((__t=(setGameVisibility(22)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(22, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game border-right round-0 '+
((__t=(setGameVisibility(12)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(12)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(12)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(16)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(16, 0)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(20)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(22)))==null?'':__t)+
'">\n                                    \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'"></td>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(12)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(12, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(16)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(16)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(16)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(20)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(20, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game border-right round-3 '+
((__t=(setGameVisibility(22)))==null?'':__t)+
'">'+
((__t=( getGameIdHtml(22)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(22)))==null?'':__t)+
'</td>            \n            <td  class="game-team border-right '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(24, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td  class="bracket-game teamB left-side round-0 '+
((__t=(setGameVisibility(12)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(12, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(16)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(16, 1)))==null?'':__t)+
'\n            </td>\n            <td  class="bracket-game teamB left-side round-2 '+
((__t=(setGameVisibility(20)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(20, 1)))==null?'':__t)+
'                  \n            </td>\n            <td  class="bracket-game border-right '+
((__t=(setGameVisibility(22)))==null?'':__t)+
'"></td>            \n            <td  class="bracket-game teamB left-side round-4 '+
((__t=(setGameVisibility(24)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(24, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(16)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(16, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(22)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(22, 1)))==null?'':__t)+
'\n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-3 '+
((__t=(setGameVisibility(22)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(22, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n</table>';
}
return __p;
}.toString()).apply(undefined, _.values(_.templateSettings.imports));
