import React, { useEffect, useId, useRef, useState, useContext } from 'react';

import CustomInput from './CustomInput';
import Icon from '../../../assets/images/icons';

import { dispatchChange, defer } from '../../../utils';

import defaultImage from '../../../assets/images/background_default.png';
import { FormDataContext, ModalContext } from "../../../context";
import bgTile from "../../../assets/images/bg_tile.png";

const getImageData = async file =>
    await new Promise((resolve) => {
        const reader = new FileReader();
        const img = new Image();

        reader.onload = function (e) {
            img.onload = function () {
                resolve({
                    width: img.width,
                    height: img.height,
                    name: file.name,
                    src: e.target.result,
                });
            };

            // setting src for img
            img.src = e.target.result;
        };

        // this conversion to png type needed because in cropper we provide to set transparent background
        // and after canvasToBlob from jpeg to png create file with 2-3x times bigger than original
        // https://github.com/fengyuanchen/cropperjs/issues/853
        reader.readAsDataURL(new File([file], 'new_file.png', { type: 'image/png' }));
    });

export default function CustomUploadImage({
    name,
    title,
    description,
    imgType,
    imgSrc,
    setImg,
    accept = ".png, .jpg, .jpeg",
    className,
    onDelete,
    onDeleteParentElement,
    changeOrderButtonsSettings,
    titleClassName,
    template,
    mayBeDeleted,
    hideDeleteIcon,
    descriptionClassName,
    cropperProps = {},
    actionsInRow = false,
}) {
    const [uploadedImg, setUploadedImg] = useState(imgSrc);
    const [templateUrl, setTemplateUrl] = useState(imgSrc);
    const { setCroppedImages } = useContext(FormDataContext);
    const { setCropperData, setCropperOpen } = useContext(ModalContext);
    const id = useId();
    const fileDataRef = useRef(null);
    const imageRef = useRef(null);

    let wrapperStyles = '';
    let imgStyles = '';

    const isDisabledDeleteButton = !uploadedImg || (!mayBeDeleted && uploadedImg === defaultImage);
    useEffect(() => {
        setUploadedImg(imgSrc);
    }, [imgSrc]);

    const setPreview = type => {
        switch (type) {
            case 'logo':
                wrapperStyles = 'w-[96px] h-[72px] mr-16';
                imgStyles = 'rounded-[50%] overflow-hidden w-[72px] object-cover m-auto';
                return;
            case 'horizontal':
                wrapperStyles = 'w-[96px] h-[41px] mr-16';
                imgStyles = 'aspect-21/9 object-cover';
                return;
            case 'vertical':
                wrapperStyles = 'w-[96px] h-[72px] mr-16';
                imgStyles = 'aspect-9/16 w-[44px] object-cover m-auto';
                return;
            case 'column':
                wrapperStyles = 'rounded-4 overflow-hidden h-[145px] w-[145px] mr-16';
                imgStyles = 'object-contain !text-[21px] w-full';
                return;
            case 'gallery':
                wrapperStyles = 'rounded-4 overflow-hidden w-full';
                imgStyles = 'aspect-16/9 object-contain !text-[21px] w-full';
                return;
            case 'partner':
                wrapperStyles = 'rounded-4 overflow-hidden w-full h-[137px]';
                imgStyles = 'object-contain !text-[21px]';
                return;
            case 'favicon':
                wrapperStyles = `overflow-hidden relative h-32 w-32 mr-16
                  ${uploadedImg ? '' : "after:absolute after:top-0 after:left-0 after:w-full after:h-full after:content-[''] after:bg-input-default-bg after:border-dashed after:border-[2px] after:border-[#c9c9c9]"}
                `;
                imgStyles = '';
                return;
            default:
                wrapperStyles = 'w-[96px] mr-16';
                imgStyles = 'aspect-16/9 object-cover';
                return;
        }
    };
    setPreview(imgType);

    const uploadImage = async event => {
        const file = event.target.files[0];
        if (file) {
            fileDataRef.current = await getImageData(file);
            setCropperOpen(true);
            setCropperData({
                fileData: fileDataRef.current,
                setImageSrc: setUploadedImg,
                name,
                title,
                setCroppedImages,
                onClose: isCloseWithoutSave => {
                    if (isCloseWithoutSave) {
                        setCroppedImages(prevState => {
                            if (!prevState[name]) {
                                imageRef.current.value = '';
                            }
                            return prevState;
                        });
                    }
                    setCropperOpen(false);
                    defer(() => {
                        setCropperData(null);
                    }, 350);
                },
                ...cropperProps,
            });
        }
    };

    const removeCroppedImage = () => setCroppedImages(prevState => {
        const newState = { ...prevState };
        delete newState[name];
        return newState;
    });

    const removeImage = () => {
        imageRef.current.value = '';
        if (onDelete) {
            onDelete();
            return;
        }
        setUploadedImg('');
        setImg('');
        removeCroppedImage();
        dispatchChange(imageRef);
    };

    const changeURL = value => {
        setTemplateUrl(value);
        imageRef.current.value = '';
        setImg(value);
    };
    const handleOnDeleteParentElement = () => {
        removeCroppedImage();
        onDeleteParentElement();
    }
    const actualName = name || `image-${id}`;
    return (
        <div className={className ?? ''}>
            {template
                ? <CustomInput
                    label='Add template or URL'
                    name={`custom${actualName}`}
                    onChange={changeURL}
                    value={templateUrl}
                    className='mb-20'
                />
                : null}
            {title &&
                <p className={`flex items-center w-full mb-12 text-label leading-label font-semibold text-content ${titleClassName ?? ''}`}>
                    {`${template ? 'Or upload image: ' : ''}${title}`}
                    {onDeleteParentElement &&
                        <button title="Remove item" onClick={handleOnDeleteParentElement} type='button' className='flex ml-auto'>
                            <Icon name='close' />
                        </button>}
                </p>}
            <div className={`${imgType === 'gallery' || imgType === 'partner' ? 'flex-col' : ''} flex
             ${actionsInRow ? 'items-center' : 'items-start'}`}>
                <div className={`${wrapperStyles} flex-none flex justify-center`}>
                    {uploadedImg
                        ? <img
                            className={`${imgStyles} h-full`}
                            style={{ backgroundImage: `url(${bgTile})`}}
                            alt='Preview'
                            src={uploadedImg}
                        />
                        : mayBeDeleted
                            ? <div className={`font-semibold font-accent-font flex items-center justify-center text-[#5e5e5e] text-[11px] h-full bg-card-default border-dashed border-[2px] border-[#c9c9c9] ${imgStyles}`}>
                                No image
                            </div>
                            : <img className={`${imgStyles} h-full`} alt='Default image' src={defaultImage} />
                    }
                </div>
                <div className={`flex w-full ${actionsInRow ? 'items-center justify-between flex-row' : 'flex-col'}`}>
                    {description &&
                        <p className={`text-xs leading-xs text-modal-additional ${actionsInRow ? '' : 'pb-8 '}${descriptionClassName ?? ''}`}>
                            {description}
                        </p>}
                    <div className={`flex${actionsInRow ? '' : ' mt-8'}`}>
                        <label
                            title="Upload image"
                            className={`${!hideDeleteIcon && imgType !== 'gallery' ? 'mr-16 ' : ''}cursor-pointer`}
                        >
                            <Icon onClick={() => (imageRef.current.value = '')} name='image_add' className="fill-content" />
                            <input
                                ref={imageRef}
                                className='invisible w-0 h-0 grow-0 hidden'
                                type='file'
                                name={actualName}
                                accept={accept}
                                onChange={uploadImage}
                            />
                        </label>
                        {!hideDeleteIcon && imgType !== 'gallery'
                            ? <button
                                title="Remove image"
                                disabled={isDisabledDeleteButton}
                                onClick={removeImage}
                                type='button'
                                className='flex'>
                                <Icon
                                    className={`fill-content ${isDisabledDeleteButton ? 'fill-icon-disabled' : ''}`}
                                    name='delete'
                                />
                            </button>
                            : null}
                        {changeOrderButtonsSettings &&
                            <div className='flex ml-auto'>
                                <button
                                    title="Move this section left"
                                    disabled={!changeOrderButtonsSettings.index}
                                    onClick={changeOrderButtonsSettings.onMoveElementBack}
                                    type='button'
                                    className='flex ml-auto mr-12'
                                >
                                    <Icon
                                        className={!changeOrderButtonsSettings.index ? 'fill-icon-disabled' : ''}
                                        name='arrow_left'
                                    />
                                </button>
                                <button
                                    title="Move this section right"
                                    disabled={changeOrderButtonsSettings.isLast}
                                    onClick={changeOrderButtonsSettings.onMoveElementForward}
                                    type='button'
                                    className='flex'
                                >
                                    <Icon className={changeOrderButtonsSettings.isLast ? 'fill-icon-disabled' : ''}
                                          name='arrow_right'
                                    />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/*{openCropper*/}
            {/*    ? <CropperModal*/}
            {/*        fileData={fileDataRef.current}*/}
            {/*        name={name}*/}
            {/*        title={title}*/}
            {/*        setImageSrc={setUploadedImg}*/}
            {/*        onClose={() => setOpenCropper(false)}*/}
            {/*        {...cropperProps}*/}
            {/*    />*/}
            {/*    : null}*/}
        </div>
    )
}
