import { Element } from '@craftjs/core';

import Hero from './Hero'
import Text from './Text';
import Tiles from './Tiles';
import Gallery from './Gallery';
import Columns from './Columns';
import Partners from './Partners';
import Form from './Form';
import Empty from "../craftJS/empty";

import demonstrationImageForColumn_1 from '../../assets/images/demonstration/columns_1.png';
import demonstrationImageForColumn_2 from '../../assets/images/demonstration/columns_2.png';
import demonstrationImageForColumn_3 from '../../assets/images/demonstration/columns_3.png';
import TP_logo from '../../assets/images/demonstration/partners/TP_logo.png';
import ODS_logo from '../../assets/images/demonstration/partners/ODS_logo.png';
import partner_1 from '../../assets/images/demonstration/partners/example_logo_1.png';
import partner_2 from '../../assets/images/demonstration/partners/example_logo_2.png';
import partner_3 from '../../assets/images/demonstration/partners/example_logo_3.png';
import bgImgExample from '../../assets/images/demonstration/main_header_example.png';
import bgMobileImgExample from '../../assets/images/demonstration/hero_bg_mobile.png';
import logoExample from '../../assets/images/demonstration/logo.png';

const DEMONSTRATION = {
    HERO: {
        bg: bgImgExample,
        mobileBg: bgMobileImgExample,
        logo: logoExample,
        title: 'Crusaders Academy',
        mainText: 'Unleash your athletic potential and soar to new heights at All in Athletics – where passion meets excellence, and champions are forged through dedication, expert coaching, and state-of-the-art facilities.',
    },
    WELCOME: {
        title: 'Welcome to Crusaders Academy',
        description: 'Welcome to All in Athletics, the premier sports school where champions are made! We are thrilled to have you join our passionate community of athletes, coaches, and supporters. At All in Athletics, we believe in the power of dedication, perseverance, and teamwork. Our goal is to provide you with top-notch training, state-of-the-art facilities, and a nurturing environment that will enable you to unleash your full athletic potential. Whether you are a beginner taking your first steps in sports or a seasoned athlete looking to elevate your skills to the next level, All in Athletics is here to guide and support you every step of the way. Get ready to embark on an exciting journey of growth, achievement, and unforgettable sporting experiences.',
    },
    ADVANTAGES: {
        title: 'Our advantages',
        items: [
            {
                id: 1,
                icon: 'ball_in_fire',
                title: 'Cutting-edge Facilities',
                description: 'State-of-the-art gymnasium, tracks, and training areas.',
            },
            {
                id: 2,
                icon: 'whistle',
                title: 'Expert Coaching',
                description: 'Highly experienced professionals providing personalized training programs.',
            },
            {
                id: 3,
                icon: 'player',
                title: 'Holistic Development',
                description: 'Fostering physical, mental, and emotional growth for well-rounded athletes',
            },
            {
                icon: 'tactic',
                id: 4,
                title: 'Competitive Opportunities',
                description: 'Access to various competitions and tournaments for valuable experience',
            },
        ],
    },
    GALLERY: {
        title: 'Image gallery',
        items: [
            {
                id: 1,
                img: 'https://images4.alphacoders.com/130/1304617.png',
                altText: 'Spring 2023 games 1',
            },
            {
                id: 2,
                img: 'https://pibig.info/uploads/posts/2022-11/thumbs/1668788635_5-pibig-info-p-khonsyu-pinterest-6.jpg',
                altText: 'Spring 2023 games 2',
            },
            {
                id: 3,
                img: 'https://onedayshootouts.com/storage/app/uploads/public/631/a25/f24/631a25f24ca19505903274.png',
                altText: 'Spring 2023 games 3',
            },
            {
                id: 4,
                img: 'https://onedayshootouts.com/storage/app/uploads/public/5cf/734/eca/5cf734ecad6a7096895348.png',
                altText: 'Spring 2023 games 4',
            },
            {
                id: 5,
                img: 'https://onedayshootouts.com/storage/app/uploads/public/5cf/6c3/6c0/5cf6c36c0c505740410146.jpg',
                altText: 'Spring 2023 games 5',
            },
        ],
    },
    PROUD_COLUMNS: {
        title: 'We are proud of',
        description: 'Crusaders Academy takes immense pride in its exceptional student-athletes, particularly in the realm of basketball. Our school boasts a long list of outstanding basketball players who have achieved remarkable success both nationally and internationally. These exceptional athletes serve as a testament to the unwavering commitment and high-quality training that Crusaders Academy provides, solidifying our reputation as a breeding ground for future basketball stars.',
        items: [
            {
                id: 1,
                img: demonstrationImageForColumn_1,
                title: 'Ethan Reynolds',
                description: 'Meet Ethan Reynolds, the extraordinary student-athlete who embodies the spirit of Crusaders Academy with his exceptional talent, unwavering dedication, and remarkable achievements in multiple sports disciplines.',
            },
            {
                id: 2,
                img: demonstrationImageForColumn_2,
                title: 'Olivia Thompson',
                description: 'Introducing Olivia Thompson, the shining star of Crusaders Academy whose remarkable athleticism, unwavering determination, and outstanding sportsmanship have made her a true inspiration to her fellow athletes and a source of immense pride for our school.',
            },
            {
                id: 3,
                img: demonstrationImageForColumn_3,
                title: 'Mia Johnson',
                description: 'We proudly present Mia Johnson, a true sensation and embodiment of the Crusaders Academy spirit. With her unmatched talent, relentless work ethic, and unwavering determination, Mia has risen to the top of her sport, earning accolades, breaking records, and inspiring future generations of athletes.',
            },
        ],
    },
    PARTNERS: {
        items: [
            {id: 1, img: TP_logo, url: 'https://tourneypro.com/',},
            {id: 2, img: ODS_logo, url: 'https://onedayshootouts.com/categories',},
            {id: 3, img: partner_1, url: 'https://onedayshootouts.com/categories',},
            {id: 4, img: partner_2, url: 'https://onedayshootouts.com/categories',},
            {id: 5, img: partner_3, url: 'https://onedayshootouts.com/categories',},
        ],
    },
    FORM: {
        title: 'Send Us a Message',
        description: 'Can\'t reach us by phone? Fill out the form below with your contact information and your question or comment. We\'ll get back to you as soon as we can!',
    }
};

const Home = () => {
    return <Element id="home-canvas" canvas is={Empty}>
        <Element id="hero" is={Hero} {...DEMONSTRATION.HERO} />
        <Element id="text" is={Text} {...DEMONSTRATION.WELCOME} />
        <Element id="tiles" is={Tiles} {...DEMONSTRATION.ADVANTAGES} />
        <Element id="gallery" is={Gallery} {...DEMONSTRATION.GALLERY} />
        <Element id="columns" is={Columns} {...DEMONSTRATION.PROUD_COLUMNS} />
        <Element id="partners" is={Partners} {...DEMONSTRATION.PARTNERS} />
        <Element id="form" is={Form} {...DEMONSTRATION.FORM} />
    </Element>;
}

export default Home;
