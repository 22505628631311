import React, { useState, useEffect } from 'react'

import Icon from '../../assets/images/icons';
import { useScrollTo } from '../../hooks';
import { defer } from "../../utils";

const Accordion = ({
    children,
    title,
    titleClassName,
    logo,
    subtitle,
    subtitleClass,
    sectionStyle,
    headerStyle,
    contentStyle,
    additionalControl,
    onExpand,
    isActive,
    isScrollTo,
    scrollRef,
    subtitleTitle,
    sideContent,
    ...rest
}) => {
    const [expanded, setExpanded] = useState(false);
    const [controlledIsScrollTo, setControlledIsScrollTo] = useState(false);
    const targetRef = React.useRef(null);
    useScrollTo({ scrollRef, targetRef, isScrollTo: isScrollTo || controlledIsScrollTo });
    const defaultHeaderStyle = 'py-16 px-8 lg:py-20 lg:px-24';
    const defaultSectionStyle = `border border-border rounded-16 mb-16 last:mb-0 hover:bg-card-hover ${expanded ? '!bg-card-active' : 'bg-card-default'}`;
    const defaultContentStyle = 'border-t border-border py-24 font-main-font';
    const getFinalSectionStyle = () => {
        if (sectionStyle) {
            return sectionStyle instanceof Function ? sectionStyle(expanded) : sectionStyle;
        }
        return defaultSectionStyle;
    }

    useEffect(() => {
        setExpanded(isActive);
    }, [isActive]);

    useEffect(() => {
        if (expanded && scrollRef?.current) {
            setControlledIsScrollTo(true);
            defer(() => setControlledIsScrollTo(false), 200);
        }
    }, [expanded, scrollRef?.current]);

    return (
        <section
            ref={targetRef}
            className={`w-full border-border ${getFinalSectionStyle()}`}
            {...rest}
        >
            <div className="flex">
                {sideContent
                    ? <aside className="cursor-auto flex flex-col justify-between px-12 py-16 w-48 border-r border-border">
                        {sideContent}
                    </aside>
                    : null}
                <header
                    onClick={() => {
                        setExpanded(!expanded);
                        onExpand?.(!expanded);
                    }}
                    className={`flex flex-1 justify-between items-center gap-16 ${headerStyle ?? defaultHeaderStyle}`}
                >
                    {logo
                        ? <div className='w-48 h-48 flex-[0_0_48px] lg:w-64 lg:h-64 lg:flex-[0_0_64px] border border-border rounded-2 overflow-hidden'>
                            <img src={logo} alt='Event logo' className='object-contain'/>
                        </div>
                        : null}
                    <div className="flex flex-col flex-1">
                        {React.isValidElement(title)
                            ? title
                            : <h4 title={title} className={titleClassName}>{title}</h4>}
                        {subtitle
                            ? <span title={subtitleTitle} className={`text-content text-xs leading-xs ${subtitleClass ?? ''}`}>
                            {subtitle}
                        </span>
                            : null}
                    </div>
                    <div className="flex flex-row items-center gap-16">
                        {additionalControl}
                        {children
                            ? <Icon name={expanded ? 'arrow_up' : 'arrow_down'} className='fill-title' />
                            : <div className="w-24 h-24"></div>}
                    </div>
                </header>
            </div>
            {children && expanded &&
                <main className={` ${contentStyle ?? defaultContentStyle}`}>
                    {children}
                </main>
            }
        </section>
    )
}

export default Accordion
