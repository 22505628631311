var _ = require('lodash');
module.exports = Function(_.keys(_.templateSettings.imports), 'return ' + function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table id="bracket" class="bracket 6t1gChampionship135" border="0" cellspacing="0" cellpadding="0">\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(1, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(1)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(1)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('1', "<!--start-->W1<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(1, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('1', "Champion", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(1)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(1, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(2, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(2)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(2)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('2', "<!--start-->W2<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(2, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('2', "3rd place", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(2)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(2, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team teamA '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameView(3, 0)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game border-right round-1 '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                                    \n                  '+
((__t=( getGameIdHtml(3)))==null?'':__t)+
''+
((__t=( getGameScheduleHtml(3)))==null?'':__t)+
'                  \n            </td>\n            <td class="game-result '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('3', "<!--start-->W3<!--end-->", true)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="game-team border-right '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=( getGameView(3, 1)))==null?'':__t)+
'\n            </td>\n            <td class="game-summary '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameSummary('3', "5th place", false)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n      <tr>            \n            <td>&nbsp;</td>\n            <td  class="bracket-game teamB left-side round-1 '+
((__t=(setGameVisibility(3)))==null?'':__t)+
'">\n                  '+
((__t=(getGameLabel(3, 1)))==null?'':__t)+
'                  \n            </td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n            <td>&nbsp;</td>\n      </tr>\n</table>';
}
return __p;
}.toString()).apply(undefined, _.values(_.templateSettings.imports));
